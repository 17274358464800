/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Injectable } from '@angular/core';

@Injectable()
export class InstanceIdService {
    private readonly ids: Object = {};

    public getId(instance: any): number {
        const componentName: string = instance.constructor.name;

        if (typeof this.ids[componentName] === 'undefined') {
            this.ids[componentName] = 0;
        }
        this.ids[componentName]++;
        return this.ids[componentName];
    }
}
