/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Column } from 'angular-slickgrid';
import { saveAs } from 'file-saver';

@Injectable()
export class ExportService {
    private readonly _csvColDelimiter = ';';
    private readonly _csvLineDelimiter = '\n';
    private readonly _csvMimeType = 'text/csv';
    private readonly _utf8BomChar = '\uFEFF';

    public generateCsvFileContent(columns: Column[], data: Object[]): string {
        let csvFile: string = columns.map(col => col.name).join(this._csvColDelimiter) + this._csvLineDelimiter;
        data.forEach(line => {
            csvFile += columns.map(col =>
                col.formatter(null, null, line[col.field], null, null, null)).join(this._csvColDelimiter) + this._csvLineDelimiter;
        });

        return csvFile;
    }

    public startDownloadFile(filename: string, content: string): void {
        const outputData: Uint8Array = new TextEncoder().encode(content);
        const blob: Blob = new Blob([this._utf8BomChar, outputData], {
            type: `${this._csvMimeType};charset=utf-8;`
        });
        const csvUrl: string = URL.createObjectURL(blob);

        saveAs(csvUrl, filename);
    }
}
