/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Injectable, Component, OnDestroy, ViewChild, ViewChildren, QueryList, AfterViewInit } from '@angular/core';

import { TemplateModel, ComponentInstance } from '@services/templates/template.model';
import { GenericGridService } from '@services/generic-grid/generic-grid.service';
import { GenericChartService } from '@services/generic-chart/generic-chart.service';
import { GenericGridClickService } from '@services/generic-grid/generic-grid-click.service';

import { ReportPlaceholderComponent } from '@components/report-placeholder/report-placeholder.component';
import { GridComponent } from '@components/grid/grid.component';
import { ChartComponent } from '@components/chart/chart.component';
import { FiltersComponent } from '@components/filters/filters.component';
import { GridTemplateComponent, GridTemplateJSArgs } from './grid-template.model';
import { MenuService } from '@app/services/menu/menu.service';

@Injectable()
export class GridModel extends TemplateModel {

    constructor() {
        super();
    }

    protected _getHtmlTemplate(htmlArgs: Object): string {
        return `<app-layout></app-layout>
<div
class="main-container"
[class.center]="centerPlaceholder"
[class.printing-contract]="printingContract"
[ngClass]="{
    'collapse-menu': this._menuService.isCollapse,
    'expand-menu': !this._menuService.isCollapse
}">
    <app-loader *ngIf="displayLoader"></app-loader>
    <app-filters
    #filters
    [idReport]="idReportFilter"
    [typeSelectionAvailable]="typeSelectionAvailable"
    [selectedType]="defaultFilterType"
    (reset)="resetData()"
    (collapse)="filtersCollapsed($event)"
    *ngIf="!clickService.selectedRemittance"></app-filters>
    <app-grid
    #appGrid
    [paginated]="paginatedGrid"
    [class.multi-report]="multiReport && !(hiddenGrid | keyvalue)?.length"
    [class.hidden]="clickService.selectedRemittance || hiddenGrid[idGrid] || exportingExcel || filters && (!filters.selectedType || filters.selectedType !== 'grid')"
    [idGrid]="idGrid"
    [enableTypeSwitch]="typeSelectionAvailable && !filters?.disableTypeSelection"
    [enablePrint]="index === 0"
    (switchType)="switchType('chart')"
    (exportExcelStart)="exportExcelStart()"
    (exportExcelDone)="exportExcelDone()"
    (printDashboard)="printDashboard()"
    (printContract)="printContract()"
    (hideComponent)="hideGrid(idGrid)"
    *ngFor="let idGrid of idsGrid; let index = index;"></app-grid>
    <div *ngIf="clickService.selectedRemittance">
        <app-filters
        #filtersRemittance
        [idReport]="clickService.selectedRemittance.ID_GRID"
        [selectedType]="'grid'"
        [applyFunc]="appGridRemittance.fetchGridRemittanceData.bind(appGridRemittance)"
        (reset)="resetData()"
        (collapse)="resizeRemittanceGrid()"></app-filters>
        <app-grid
        #appGridRemittance
        [idGrid]="clickService.selectedRemittance.ID_GRID"
        [remittance]="true"
        (exportExcelStart)="exportExcelStart()"
        (exportExcelDone)="exportExcelDone()"
        (printDashboard)="printDashboard()"></app-grid>
    </div>
    <div
    class="chart-parent-container"
    [class.hidden]="filters?.selectedType === 'grid'"
    *ngIf="idsChart.length > 0">
        <app-chart
        #appChart
        [class.multi-report]="multiReport"
        [class.hidden]="filters?.selectedType !== 'chart'"
        [idChart]="idChart"
        [enableTypeSwitch]="typeSelectionAvailable && !filters?.disableTypeSelection"
        [enablePrint]="index === 0"
        (switchType)="switchType('grid')"
        (printDashboard)="printDashboard()"
        *ngFor="let idChart of idsChart; let index = index;"></app-chart>
    </div>
    <app-report-placeholder
    #placeholder
    (closeRowsLimit)="expandFilters()"
    (forceResult)="forceResult()"
    (exportExcel)="exportExcel()"></app-report-placeholder>
</div>`;
    }

    protected _getComponent(selectorTemplate: string, htmlTemplate: string, jsArgs: GridTemplateJSArgs): ComponentInstance {
        @Component({
            selector: selectorTemplate,
            template: htmlTemplate
        })
        class NewComponent extends GridTemplateComponent implements AfterViewInit, OnDestroy {
            @ViewChild('filters') filtersChild: FiltersComponent;
            @ViewChild('placeholder') placeholderChild: ReportPlaceholderComponent;
            @ViewChildren('appGrid') appGridChildren: QueryList<GridComponent>;
            @ViewChildren('appChart') appChartChildren: QueryList<ChartComponent>;
            @ViewChild('filtersRemittance') filtersRemittanceChild: FiltersComponent;
            @ViewChild('appGridRemittance') appGridRemittanceChild: GridComponent;

            constructor(private readonly _gridService: GenericGridService,
                        private readonly _chartService: GenericChartService,
                        private readonly _clickService: GenericGridClickService,
                        private readonly _menuService: MenuService) {
                super({
                    menuCode: jsArgs.menuCode,
                    idsGrid: jsArgs.idsGrid,
                    idsChart: jsArgs.idsChart,
                    typeSelectionAvailable: jsArgs.typeSelectionAvailable,
                    paginatedGrid: jsArgs.paginatedGrid,
                    gridService: _gridService,
                    chartService: _chartService,
                    clickService: _clickService
                });
            }

            ngAfterViewInit(): void {
                this.filters = this.filtersChild;
                this.placeholder = this.placeholderChild;
                this.appGrid = this.appGridChildren;
                this.appChart = this.appChartChildren;
                this.appGridList = this.appGrid.toArray();
                this.appChartList = this.appChart.toArray();
                this.setSelectedFilterType();
            }

            ngOnDestroy(): void {
                this.destroy();
                this.subscriptions.unsubscribe();
            }
        }
        return NewComponent;
    }
}
