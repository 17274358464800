/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Injectable } from '@angular/core';

import { FormatterService } from './formatter.class';
import { CssFormatterOptions } from '../slickgrid-formatter/slickgrid-formatter.service';

import numeral from 'numeral';

enum RoundedType {
    FLOOR,
    ROUND,
    CEIL
}

enum FormatMethodName {
    MAX_DECIMAL_DIGITS = 'maxDecimalDigits',
    SEPARATE_THOUSANDS = 'separateThousands',
    DECIMAL_SEPARATOR = 'decimalSeparator',
    EXPLICIT_SIGN = 'explicitSign',
    SUFFIX = 'suffix',
    PREFIX = 'prefix',
    FORMAT = 'format'
}

export interface NumberFormatterOptions extends CssFormatterOptions {
    maxDecimalDigits?: {
        maxDecimalDigits: number;
        roundedType?: RoundedType;
    };
    prefix?: string;
    suffix?: string;
    decimalSeparator?: string;
    thousandsSeparator?: string;
    explicitSign?: boolean;
    format?: string;
}

numeral.register('locale', 'fr', {
    delimiters: {
        thousands: ' ',
        decimal: ','
    },
    abbreviations: {
        thousand: 'K',
        million: 'M',
        billion: 'B',
        trillion: 'T'
    },
    ordinal: (nb: number): string => nb === 1 ? 'er' : 'ème',
    currency: {
        symbol: '€'
    }
});

@Injectable({
    providedIn: 'root'
})
export class NumberFormatterService extends FormatterService {
    public callOrder: FormatMethodName[] = [
        FormatMethodName.MAX_DECIMAL_DIGITS,
        FormatMethodName.SEPARATE_THOUSANDS,
        FormatMethodName.DECIMAL_SEPARATOR,
        FormatMethodName.EXPLICIT_SIGN,
        FormatMethodName.SUFFIX,
        FormatMethodName.PREFIX,
        FormatMethodName.FORMAT
    ];

    private _decSeparator = '.';
    private readonly _defaultMaxDecimalDigits = 2;

    public value(value: number): NumberFormatterService {
        FormatterService.prototype.value.apply(this, [value]);
        return this;
    }

    public separateThousands(thousandsSeparator = ' '): NumberFormatterService {
        this._addCallToStack(FormatMethodName.SEPARATE_THOUSANDS, arguments);
        return this;
    }

    public maxDecimalDigits(maxDecimalDigits = this._defaultMaxDecimalDigits, roundedType = RoundedType.ROUND): NumberFormatterService {
        this._addCallToStack(FormatMethodName.MAX_DECIMAL_DIGITS, arguments);
        return this;
    }

    public suffix(suffix: string): NumberFormatterService {
        this._addCallToStack(FormatMethodName.SUFFIX, arguments);
        return this;
    }

    public prefix(prefix: string): NumberFormatterService {
        this._addCallToStack(FormatMethodName.PREFIX, arguments);
        return this;
    }

    public explicitSign(): NumberFormatterService {
        this._addCallToStack(FormatMethodName.EXPLICIT_SIGN, arguments);
        return this;
    }

    public decimalSeparator(decimalSeparator = ','): NumberFormatterService {
        this._addCallToStack(FormatMethodName.DECIMAL_SEPARATOR, arguments);
        return this;
    }

    public format(format = ''): NumberFormatterService {
        this._addCallToStack(FormatMethodName.FORMAT, arguments);
        return this;
    }

    private _separateThousands(thousandsSeparator = ' '): void {
        this._displayedValue = this._getSpacedNumber(this._displayedValue);
    }

    private _maxDecimalDigits(maxDecimalDigits = this._defaultMaxDecimalDigits, roundedType = RoundedType.ROUND): void {
        const mod: number = parseInt('1'.padEnd(maxDecimalDigits + 1, '0'), 10);
        const roundFuncs: ((n: number) => number)[] = [];
        roundFuncs[RoundedType.CEIL] = Math.ceil;
        roundFuncs[RoundedType.FLOOR] = Math.floor;
        roundFuncs[RoundedType.ROUND] = Math.round;
        const roundFunc: ((n: number) => number) = roundFuncs[roundedType];
        const result: number = roundFunc(((this._value as number) + Number.EPSILON) * mod) / mod;
        this._displayedValue = result.toString();
    }

    private _suffix(suffix: string): void {
        this._displayedValue += suffix;
    }

    private _prefix(prefix: string): void {
        this._displayedValue = prefix + this._displayedValue;
    }

    private _explicitSign(): void {
        if (this._value > 0) {
            this._prefix('+');
        }
    }

    private _decimalSeparator(decimalSeparator = ','): void {
        this._decSeparator = decimalSeparator;
        this._displayedValue = this._displayedValue.replace('.', decimalSeparator);
    }

    private _format(format = ''): void {
        this._displayedValue = numeral(this._value).format(format);
    }

    private _getSpacedNumber(nb: string): string {
        const spaceLimit = 1000;
        const dotPosLimit = 3;
        if (Math.abs(this._value as number) < spaceLimit) {
            return nb;
        }

        let dotPos: number = nb.indexOf('.');
        let unitPos = 1;

        if (dotPos === -1) {
            dotPos = nb.length;
        }
        for (let i: number = dotPos - 1; i >= 0; --i) {
            if (unitPos % dotPosLimit === 0 && nb[i] !== '-') {
                nb = `${nb.slice(0, i)} ${nb.slice(i)}`;
                unitPos = 1;
            } else {
                ++unitPos;
            }
        }
        return nb;
    }
}
