/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';

import { ComponentGeneratorService } from '@services/component-generator/component-generator.service';
import { ConfigService } from '@services/config/config.service';
import { LanguageService } from '@services/language/language.service';
import { MenuService } from '@services/menu/menu.service';
import { QueryService } from '@services/query/query.service';
import { COOKIE_URL_KEY } from '@services/session/session.service';
import { SlickgridFormatterService } from '@services/slickgrid-formatter/slickgrid-formatter.service';
import { CookieService } from 'ngx-cookie-service';
import { ErrorService } from './services/error';

/* tslint:disable: component-selector */
@Component({
    selector: 'app',
    template: `<router-outlet></router-outlet>
               <app-loader *ngIf="queryService.isLoading()"></app-loader>
               <app-error *ngIf="error"></app-error>`
})

export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {
    /**
     * Display an error only when get_menu.tpl or get_i18n failed.
     * Except for Access Denied (403) error. The user will be redirect to login page (Manage in query service)
     */
    public error = false;

    private readonly _subscriptions: Subscription = new Subscription();
    private readonly _accessDeniedCode = 403;

    constructor(public queryService: QueryService,
                private readonly _changeDetector: ChangeDetectorRef,
                private readonly _componentGeneratorService: ComponentGeneratorService,
                private readonly _languageService: LanguageService,
                private readonly _router: Router,
                private readonly _formatter: SlickgridFormatterService,
                private readonly _errorService: ErrorService,
                private readonly _configService: ConfigService,
                private readonly _cookieService: CookieService,
                private readonly _menuService: MenuService) {
                    this.error = this._errorService.hasError;
    }

    ngOnInit(): void {
        this._subscriptions.add(
            forkJoin([
                this._languageService.init(),
                this._componentGeneratorService.initMenu(),
                this._formatter.initFormatter()
            ]).subscribe({
                error: (errorResponse: HttpErrorResponse) => {
                    if (errorResponse.status !== this._accessDeniedCode) {
                        this.error = this._errorService.hasError;
                    } else {
                        this._router.initialNavigation();
                    }
                },
                complete: () => {
                    this._router.initialNavigation();
        
                    const url = this._cookieService.get(COOKIE_URL_KEY);
                    if (url) {
                        this._menuService.selectItemByUrl(url.replace(this._configService.dashboardRootUrl, ''));
                        this._cookieService.delete(COOKIE_URL_KEY);
                    }
                }
            })
        );
    }

    ngAfterViewChecked(): void {
        this._changeDetector.detectChanges();
    }

    ngOnDestroy(): void {
       this._subscriptions.unsubscribe();
    }
}
