/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertService } from '@app/services/alert/alert.service';
import { Subscription, Observable, timer } from 'rxjs';

@Component({
  selector: 'app-alert-browser',
  templateUrl: './alert-browser.component.html',
  styleUrls: ['./alert-browser.component.scss']
})
export class AlertBrowserComponent implements OnInit, OnDestroy {
    public browser: string;
    public lastSupportedBrowserVersion: number;
    public displayAlert: boolean;
    private subscription: Subscription;
    private timer: Observable<number>;

    constructor(private _alertService: AlertService) {
        this.browser = this._alertService.getBrowser(navigator.userAgent);
        this.lastSupportedBrowserVersion = this._alertService.lastSupportedBrowserVersion(navigator.userAgent);
        this.displayAlert = !this._alertService.alertBrowserAlreadyDisplay && this._alertService.isObsoleteBrowser(navigator.userAgent);
    }

    ngOnInit(): void {
        this.setTimer();
    }

    ngOnDestroy(): void {
        if (this.subscription && this.subscription instanceof Subscription) {
            this.subscription.unsubscribe();
        }
    }

    public setTimer(): void {
        const timerVal = 10000;
        if (!this._alertService.alertBrowserAlreadyDisplay && this._alertService.isObsoleteBrowser(navigator.userAgent)) {
            this.timer = timer(timerVal);
            this.subscription = this.timer.subscribe(() => {
                this._alertService.alertBrowserAlreadyDisplay = true;
                this.displayAlert = false;
            });
        }
    }
}
