import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TreeSelectItem } from '../tree-select.component';

interface HierarchyLevelLabel {
    code: string;
    label: string;
}

@Component({
    selector: 'app-tree-select-item',
    templateUrl: './tree-select-item.component.html',
    styleUrls: ['./tree-select-item.component.scss']
})
export class TreeSelectItemComponent {
    @Input() treeItem: TreeSelectItem;

    @Output() onSelect: EventEmitter<TreeSelectItem> = new EventEmitter<TreeSelectItem>();

    private readonly _hierarchyLevelLabels: HierarchyLevelLabel[] = [
        { code: 'lvl-ga-fr', label: '_CONTRACT_._GROUP_' },
        { code: 'lvl-ga-en', label: '_CONTRACT_._GROUP_' },
        { code: 'lvl-enseigne-fr', label: '_CONTRACT_._COMMERCIAL_' },
        { code: 'lvl-enseigne-en', label: '_CONTRACT_._COMMERCIAL_' },
        { code: 'lvl-ej-fr', label: '_CONTRACT_._COMPANY_' },
        { code: 'lvl-ej-en', label: '_CONTRACT_._COMPANY_' },
        { code: 'lvl-cc-fr', label: '_GRID_HDR_._CHAIN_CODE_' },
        { code: 'lvl-cc-en', label: '_GRID_HDR_._CHAIN_CODE_' }
    ];

    public getHierarchyLevelLabel(lvlCode: string): string {
        return this._hierarchyLevelLabels.find(hierarchyLevelLabel => hierarchyLevelLabel.code === lvlCode)?.label || '';
    }

    public onItemClick(item: TreeSelectItem): void {
        this.onSelect.emit(item);
    }
}
