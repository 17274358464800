/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Filter, FilterGroupDirection, FlatFilter } from './';

export class FilterGroup {
    groupId: number;
    groupLabel: string;
    displayDirection: FilterGroupDirection;
    filters: Filter[];

    private _isCollapsed = false;

    constructor(_: FlatFilter) {
        this.groupId = _.group_id;
        this.groupLabel = _.group_label;
        this.displayDirection = _.display_direction;
        this.filters = [];
        return this;
    }

    get isCollapsed(): boolean { return this._isCollapsed; }

    set isCollapsed(val: boolean ) { this._isCollapsed = val; }
}
