/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Injectable, isDevMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { Observable, Observer } from 'rxjs';
import {
    catchError,
    finalize,
    publishReplay,
    refCount
} from 'rxjs/operators';

import { ErrorService } from '@services/error';
import { AuthenticationModes, ConfigService } from '@services/config/config.service';
import { StatusCodes } from 'http-status-codes';
import { SESSION_TOKEN_KEY, SessionService } from '@app/services/session/session.service';

class Query {
    public template = '';
    public queryParams: Object = {};
    public limit: number;

    constructor(template: string, queryParams?: Object, limit?: number) {
        this.template = template;
        this.queryParams = queryParams;
        this.limit = limit;
    }

    public queryToString(): string {
        let vars = '';
        for (const id in this.queryParams) {
            if (this.queryParams.hasOwnProperty(id) && this.queryParams[id]) {
                vars += `${id}${this.queryParams[id].toString()}`;
            }
        }

        return this.template + vars + this.limit;
    }
}

export interface QueryConfig {
    template: string;
    params?: Object;
    limit?: number;
}

export interface QueryCredentials {
    username: string;
    password: string;
}

export interface ResetPasswordRequest {
    username: string;
}

export interface ResetPasswordValidationRequest {
    username: string;
    code: string;
    newPassword: string;
}

export interface LogoutRequest {
    refreshToken: string;
}

export interface RefreshRequest {
    refreshToken: string;
}

export interface QueryAuthToken {
    jwt: string;
    refreshToken: string;
    tokenType: string;
    expirationTime: number;
}

export interface DefaultQuery {
    select: string;
    from: string;
    where: string;
}

export interface Response {
    rows: Array<Array<number | string>>;
    metadata: { [key: string]: string };
}

interface RequestHeader {
    headers?: { [header: string]: string | string[]; };
    observe?: 'body';
    params?: { [param: string]: string | string[]; };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
}

const XSRF_COOKIE_NAME = 'XSRF-TOKEN';
const XSRF_HEADER_NAME = 'X-XSRF-TOKEN';

@Injectable()
export class QueryService {
    private readonly _queryConfig: QueryConfig;
    private _queryUrl = '';
    private _printUrl = '';
    private _authUrl = '';
    private _samlAuthUrl = '';
    private _refreshTokenUrl = '';
    private _logoutUrl = '';
    private _resetPasswordRequestUrl = '';
    private _resetPasswordValidationRequestUrl = '';
    private _authToken: QueryAuthToken;

    private _nbRequest = 0;
    private readonly _pendingQueries: Map<string, Observable<Object[]>>;
    private readonly _requestHeaders: RequestHeader = { headers: 
        { 
            'content-type': 'application/json',
            'cache-control': 'no-cache, no-store, max-age=0, must-revalidate'
        } 
    };
    private _debounceHolder: NodeJS.Timeout;
    private readonly _debounceDelay = 5000;

    constructor(private readonly http: HttpClient,
        private readonly router: Router,
        private readonly errorService: ErrorService,
        private readonly configService: ConfigService,
        private readonly _sessionService: SessionService) {
        this._pendingQueries = new Map();
        this._authUrl = `${this.configService.backendUrl}/auth/authenticate`;
        this._samlAuthUrl = `${this.configService.authenticationUrl}`;
        this._refreshTokenUrl = `${this.configService.backendUrl}/auth/token`;
        this._logoutUrl = `${this.configService.backendUrl}/auth/logout`;
        this._resetPasswordRequestUrl = `${this.configService.backendUrl}/auth/passwordReset`;
        this._resetPasswordValidationRequestUrl = `${this.configService.backendUrl}/auth/passwordResetByCodeAndUsername`;
        this._queryUrl = `${this.configService.backendUrl}/query`;
        this._printUrl = `${this.configService.backendUrl}/print`;
        this._queryConfig = {
            'template': ''
        };

        const storedStringifiedToken: string = this._sessionService.getItem(SESSION_TOKEN_KEY);

        if (storedStringifiedToken) {
            this._authToken = JSON.parse(storedStringifiedToken);
            this._setAuthorizationHeader(this._authToken.jwt);
        }
    }

    /**
     * Send auth request to the backend with the credentials given
     */
    public authenticate(username: string, password: string): Observable<QueryAuthToken> {
        const credentials: QueryCredentials = {
            username,
            password
        };

        return new Observable(observer => {
            this._sendPostRequest(this._authUrl, credentials, this._requestHeaders)
                .subscribe((d: QueryAuthToken) => {
                    this.updateStoredAuthToken(d);
                    observer.next(d);
                    observer.complete();
                }, error => observer.error(error));
        });
    }

    /**
     * Refresh the JWT token to avoid untimely forbidden errors from the server
     */
    public refreshToken(): void {
        const refreshRequestArgs: RefreshRequest = {
            refreshToken: this._authToken.refreshToken
        };

        this.http.post(this._refreshTokenUrl, refreshRequestArgs, this._requestHeaders).subscribe((d: QueryAuthToken) => {
            this.updateStoredAuthToken(d);
        }, error => isDevMode() && console.log(error));
    }

    /**
     * Send reset password request to the backend with the given username
     */
    public resetPasswordRequest(username: string): Observable<void> {
        const credentials: ResetPasswordRequest = {
            username
        };

        return new Observable(observer => {
            this._sendPostRequest(this._resetPasswordRequestUrl, credentials, this._requestHeaders)
                .subscribe(() => {
                    observer.next();
                    observer.complete();
                }, error => observer.error(error));
        });
    }

    /**
     * Send reset password request to the backend with the given username
     */
    public resetPasswordValidation(username: string, resetCode: string, newPassword: string): Observable<void> {
        const requestArgs: ResetPasswordValidationRequest = {
            username,
            newPassword,
            code: resetCode
        };
        const requestHeaders: Object = JSON.parse(JSON.stringify(this._requestHeaders));
        requestHeaders['responseType'] = 'text';

        return new Observable(observer => {
            this._sendPostRequest(this._resetPasswordValidationRequestUrl, requestArgs, requestHeaders)
                .subscribe(() => {
                    observer.next();
                    observer.complete();
                }, error => observer.error(error));
        });
    }

    /**
     * Return true if at least one Query is waiting for its response
     */
    public isLoading(): boolean {
        return this._nbRequest > 0;
    }

    /**
     * Remove a specific template/queryParams pair from the cache to force a reexecution
     * of the pair on the next call of getSqlResult()
     */
    public flushQuery(template: string, queryParams?: Object): void {
        const newQuery: Query = new Query(template, queryParams ? queryParams : {});
        const key: string = newQuery.queryToString();

        if (this._pendingQueries.has(key)) {
            this._pendingQueries.delete(key);
        }
    }

    /**
     * Add a template/queryParams pair to the _pendingQueries if not already in it
     * Return the Observable link to the new or already existing Query
     */
    public getSqlResult(template: string, queryParams?: Object, limit?: number, print = false): Observable<Object[]> {
        const newQuery: Query = new Query(template, queryParams ? queryParams : {}, limit);

        if (!this._pendingQueries.has(newQuery.queryToString())) {
            this._pendingQueries.set(newQuery.queryToString(), this._getRequestObservable(template, print, queryParams, limit));
        }
        this._nbRequest++;
        return this._pendingQueries.get(newQuery.queryToString());
    }

    public resetToken(): void {
        delete this._requestHeaders.headers['Authorization'];
        this._sessionService.setItem<string>(SESSION_TOKEN_KEY, '');

    }

    public logout(): Observable<void> {
        const requestHeaders: Object = {
            headers: { 'content-type': 'application/json' },
            responseType: 'text'
        };
        const requestArgs: LogoutRequest = {
            refreshToken: this._authToken.refreshToken
        };

        return new Observable(observer => {
            this._sendPostRequest(this._logoutUrl, requestArgs, requestHeaders)
                .subscribe(() => {
                    observer.next();
                    observer.complete();
                }, error => observer.error(error));
        });
    }

    private updateStoredAuthToken(authToken: QueryAuthToken): void {
        this._authToken = authToken;
        this._sessionService.setItem<string>(SESSION_TOKEN_KEY, JSON.stringify(this._authToken))

        if (this._authToken) {
            this._setAuthorizationHeader(this._authToken.jwt);
        }
    }

    /**
     * Return a template/queryParams pair Observable
     */
    private _getRequestObservable(template: string, print: boolean, queryParams?: Object, limit?: number): Observable<Object[]> {
        return this._executeQuery(template, print, queryParams, limit);
    }

    /**
     * Execute a template/queryParams pair and return its Observable
     */
    private _executeQuery(template: string, print: boolean, queryParams?: Object, limit?: number): Observable<Object[]> {
        const queryConfig: QueryConfig = Object.assign({}, this._queryConfig);
        queryConfig.template = template;
        if (typeof queryParams !== 'undefined') {
            queryConfig.params = queryParams;
        }
        if (typeof limit !== 'undefined') {
            queryConfig.limit = limit;
        }
        if (print && isDevMode()) {
            this.http.post(this._printUrl, queryConfig, this._requestHeaders)
                .subscribe(data => console.log(data), data => console.log(data.error.text));
        }
        return new Observable(observer => {
            this.http.post(this._queryUrl, queryConfig, this._requestHeaders)
                .pipe(
                    publishReplay(1),
                    refCount(),
                    catchError(this._handleError.bind(this, observer))
                )
                .subscribe(response => {
                    this._handleResponse(response, observer);
                });
        });
    }

    private _handleResponse(response: Response, observer: Observer<Object[]>): void {
        this._nbRequest--;
        if (this._nbRequest === 0 && !this.configService.isWebSSOAuthentication) {
            // Refresh the token when the last request is executed wrapped in a debounce of 5sec
            clearTimeout(this._debounceHolder);
            this._debounceHolder = setTimeout(() => this.refreshToken(), this._debounceDelay);
        }
        observer.next(this._formatData(response));
        observer.complete();
    }

    private _handleError(observer: Observer<Object[]>, error: { status: number }): void {
        this._nbRequest--;

        switch (error.status) {
            case StatusCodes.NOT_FOUND:
                this.errorService.updateMessage('_ERROR_', '_ERROR_._USER_NOT_FOUND_');
                break;
            case StatusCodes.UNAUTHORIZED:
                if (this.configService.authenticationMode === AuthenticationModes.WEBSSO) {
                    window.location.assign(this.configService.authenticationUrl);
                } else {
                    this.resetToken();
                    window.location.reload();
                }
                break;
            case StatusCodes.FORBIDDEN:
                this.router.navigate(['/view/login']);
                break;
            default:
                this.errorService.updateMessage('_ERROR_', '_ERROR_._ERROR_DATABASE_');
                break;
        }
        observer.error(error);
        throw (error);
    }

    private _formatData(response: Response): Object[] {
        // For number of rows limit
        if (!response.rows) {
            return [response];
        }
        return response.rows.map(values => {
            const row: Object = {};
            let i = 0;
            for (const key of Object.keys(response.metadata)) {
                row[key] = values[i];
                i++;
            }
            return row;
        });
    }

    private _setAuthorizationHeader(token: string): void {
        this._requestHeaders.headers['Authorization'] = `Bearer ${token}`;
    }

    /* tslint:disable-next-line:no-any */
    private _sendPostRequest(url: string, args: any, headers: any): Observable<any> {
        this._nbRequest++;
        return this.http.post(url, args, headers)
            .pipe(finalize(() => this._nbRequest--));
    }
}
