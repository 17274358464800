/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, Input, AfterContentInit } from '@angular/core';
import { Filter, FilterDimType, FilterDim, FilterGroup } from '@components/filters/filter-typings';
import { FilterDimValueService } from '@services/filter-dim-value/filter-dim-value.service';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements AfterContentInit {
    @Input() filter: Filter;
    @Input() group: FilterGroup;
    @Input() disableFilterOnRadioChange: Function;

    public filterDimTypes: Object = FilterDimType;

    constructor(private readonly _filterDimValueService: FilterDimValueService) { }

    public ngAfterContentInit(): void {
        this.filter.dimensions.forEach(dim => {
            dim.isVisible = this._filterDimValueService.isDimensionVisible(dim);
            if (typeof dim.disableConstraint !== 'undefined') {
                const disableConstraintSelected: boolean = this._filterDimValueService.listHasSelectedValue(dim.disableConstraint);
                const dimHasSelectedValue: boolean = this._filterDimValueService.hasSelectedValue(dim.id);

                if ((disableConstraintSelected && dimHasSelectedValue)
                    || (!disableConstraintSelected && dim.id === dim.disableConstraint[0])) {
                    dim['_radioDisableModel'] = dim.id;
                    this.disableDimOnRadioChange(dim);
                }

                const visibleDimsRemaining: FilterDim[] = this.getRemainingVisibleDimsInConstraint(dim.disableConstraint);
                if (visibleDimsRemaining.length === 1) {
                    visibleDimsRemaining[0].constraintRadioVisible = false;
                }
            }
        });
    }

    public disableDimOnRadioChange(changedDim: FilterDim): void {
        this.filter.dimensions.forEach(dim => {
            if (changedDim.disableConstraint.indexOf(dim.id) !== -1) {
                dim['_disabled'] = (changedDim['_radioDisableModel'] !== dim.id);
            }
        });
    }

    private getRemainingVisibleDimsInConstraint(disableConstraints: number[]): FilterDim[] {
        return this.filter.dimensions.filter(dim => disableConstraints.indexOf(dim.id) !== -1 && dim.isVisible);
    }
}
