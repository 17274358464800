/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component } from '@angular/core';

import { AuthService } from '@services/auth/auth.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {

    constructor(public authService: AuthService) {}
}
