/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { Column, GridOption, AngularGridInstance, PaginationService, GridAutosizeColsMode } from 'angular-slickgrid';
import { Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { GenericGridService } from '@services/generic-grid/generic-grid.service';
import { GenericGridClickService } from '@services/generic-grid/generic-grid-click.service';
import { LanguageService } from '@services/language/language.service';
import { FilterConfigService } from '@services/filter-config/filter-config.service';
import { QueryService } from '@services/query/query.service';
import { ColumnDef, GridConf } from '@services/generic-grid/generic-grid-typings';
import { ExportService } from '@services/export/export.service';
import { InstanceIdService } from '@services/instance/instance.service';
import { FilterDimValueService } from '@app/services/filter-dim-value/filter-dim-value.service';
import { checkFiltersDef } from '@app/services/generic-grid/generic-grid-typings/generic-column-def.interface';
import { Filter, FilterDim, FilterGroup } from '../filters/filter-typings';

export interface DefaultSelectableElement {
    label: string;
    value: string | number;
    expanded?: boolean;
    disabled?: boolean;
}
export declare type SelectableElement = DefaultSelectableElement | any;

@Component({
    selector: 'app-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit, OnDestroy {
    @Input() paginated: boolean;
    @Input() idGrid: number;
    @Input() enableTypeSwitch = false;
    @Input() enablePrint = true;
    @Input() remittance = false;

    @Output() configLoaded: EventEmitter<void> = new EventEmitter();
    @Output() switchType: EventEmitter<void> = new EventEmitter();
    @Output() exportExcelStart: EventEmitter<void> = new EventEmitter();
    @Output() exportExcelDone: EventEmitter<void> = new EventEmitter();
    @Output() printDashboard: EventEmitter<void> = new EventEmitter();
    @Output() printContract: EventEmitter<void> = new EventEmitter();
    @Output() hideComponent: EventEmitter<void> = new EventEmitter();

    public instanceId: string;
    public dashboardContainerId: string;
    public columnDefinitions: Column[] = [];
    public columnDefinitionsBackup: Column[] = [];
    public gridData: Object[] = [];
    public refreshPagination: Subject<boolean> = new Subject();
    public paginationService: PaginationService;
    public pageSizes: Array<SelectableElement> = [];
    public selectedSize: SelectableElement = [];
    public applyTriggered = false;
    public gridOptions: GridOption = {};
    public gridOptionsExport: GridOption = {};
    public displayEmptyData = false;
    public printing = false;
    public filterColumns = false;
    public dynamicColumnLabel = false;
    public name = '';

    private _angularGrid: AngularGridInstance;
    private readonly _subscriptions: Subscription = new Subscription();

    constructor(
        public clickService: GenericGridClickService,
        public gridService: GenericGridService,
        private readonly _instanceIdService: InstanceIdService,
        private readonly _languageService: LanguageService,
        private readonly _translateService: TranslateService,
        private readonly _filterConfigService: FilterConfigService,
        private readonly _filterDimValueService: FilterDimValueService,
        private readonly _queryService: QueryService,
        private readonly _exportService: ExportService) {
        this.instanceId = 'grid-' + this._instanceIdService.getId(this);
        this.dashboardContainerId = 'dashboard-' + this.instanceId;
    }

    get isPaginated(): boolean {
        return typeof this.paginated !== 'undefined' && this.paginated !== false;
    }
    get angularGrid(): AngularGridInstance { return this._angularGrid; }

    ngOnInit(): void {
        this._createGrid();
        this._subscriptions.add(this._languageService.translationLoaded.subscribe(this._updateHeaderLabel.bind(this)));
    }

    ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
        if (this._angularGrid) {
            this._angularGrid.destroy();
        }
    }

    public angularGridReady(angularGrid: AngularGridInstance): void {
        this._angularGrid = angularGrid;
        if (this.isPaginated) {
            setTimeout(this._setPagination.bind(this));
        }
    }

    public changeItemPerPage(newPageSize: SelectableElement): void {
        this.selectedSize = newPageSize;
        if (!this.paginationService) {
            return;
        }
        this.paginationService.changeItemPerPage(this.selectedSize.value);
        this.refreshPagination.next(true);
    }

    public setData(data: Object[]): void {
        this.gridData = data;
        this.gridData.forEach((el, i) => el['id'] = i);
        this.applyTriggered = true;
        if (this._angularGrid
            && this._angularGrid.dataView) {
            this.refreshDataView();
            this.refreshPagination.next(true);
        }
        this.displayEmptyData = this.gridData.length === 0;
        if (this.filterColumns) {
            this.columnDefinitions = this._filterColumns(this.columnDefinitionsBackup);
            if (this.columnDefinitions.length === 0) {
                this.hideComponent.next();
            }
        }
        if (this.dynamicColumnLabel) {
            this._updateDynamicHeaderLabel();
        }
        this._setGridName();
        this._refreshMultiHeaderOptions();
    }

    public refreshDataView(): void {
        if (this.gridData.length > 0 && this._angularGrid && this._angularGrid.dataView) {
            this._angularGrid.dataView.refresh();
        }
    }

    public resizeGrid(): void {
        if (this.gridData.length > 0 && this._angularGrid && this._angularGrid.resizerService) {
            this._angularGrid.resizerService.resizeGrid();
        }
    }

    public exportExcel(): void {
        const exportDelay = 1000;
        this.exportExcelStart.next();
        let columns: Column[] = this.gridService.buildAllColumns(this.idGrid, false, true);
        
        if (columns.some((col: Column) => col.params && (col.params.filters || col.params.checkFilters)) === true) {
            columns = this._filterColumns(columns);
        }
        
        if (this.dynamicColumnLabel) {
            this._updateDynamicHeaderLabelByDefinition(columns);
        }
        const exportFilename: string = this.getExportFilename();
        const csvFileContent: string = this._exportService.generateCsvFileContent(columns, this.gridData);

        this._exportService.startDownloadFile(exportFilename, csvFileContent);
        setTimeout(() => {
            this.exportExcelDone.next();
        }, exportDelay);
    }

    public getExportFilename(): string {
        let filename = '';
        const gridName: string = this._translateService
            .instant(this.name)
            .replace(/\s/g, '_');
        filename += gridName + '_';

        const filters: Object = this._filterConfigService.buildValuesHashmap();
        if (filters['DT_FROM'] && filters['DT_TO']) {
            const dateStart: string = filters['DT_FROM'].replace(/-/g, '');
            const dateTo: string = filters['DT_TO'].replace(/-/g, '');
            filename += `${dateStart}_${dateTo}_`;
        } else {
            const filtersKeys: string[] = Object.keys(filters);
            const keyDate: string = filtersKeys.find(key => key.startsWith('DT_'));
            if (keyDate) {
                filename += `${filters[keyDate].replace(/-/g, '')}_`;
            } else if (filters['EVOL_TWELVE_MONTHS']) {
                filename += `${this._translateService.instant('_DATE_._MONTHLY_')}_`;
            } else if (filters['EVOL_OF_FOUR_QUARTERS']) {
                filename += `${this._translateService.instant('_DATE_._QUARTERLY_')}_`;
            }
        }
        const currentDate: Date = new Date();
        const month: string = this._formatExportedDate(currentDate.getMonth() + 1);
        const date: string = this._formatExportedDate(currentDate.getDate());
        const hours: string = this._formatExportedDate(currentDate.getHours());
        const minutes: string = this._formatExportedDate(currentDate.getMinutes());
        const seconds: string = this._formatExportedDate(currentDate.getSeconds());
        filename += `${currentDate.getFullYear()}${month}${date}_`;
        filename += `${hours}${minutes}${seconds}`;
        filename += '.csv';
        return filename.toLowerCase();
    }

    public setPrintOptions(): void {
        this._angularGrid?.slickGrid.setOptions({ autoHeight: true });
    }

    public resetPrintOptions(): void {
        this.printing = false;
        this._angularGrid?.slickGrid.setOptions({ autoHeight: false });
    }

    public print(): void {
        this.printing = true;
        // Wait filters collapse
        setTimeout(() => {
            this.printDashboard.next();
        });
    }

    public fetchGridRemittanceData(): void {
        const queryParams: Object = {
            CD_LANG: this._languageService.currentLang,
            NU_REM: this.clickService.selectedRemittance.NU_REM,
            NU_SEQUENCE: this.clickService.selectedRemittance.NU_SEQUENCE,
            NU_CONTRAT: this.clickService.selectedRemittance.NU_CONTRAT,
            DT_CONSTRUCTION: this.clickService.selectedRemittance.DT_CONSTRUCTION,
            CD_SIGN_REMITTANCE: this.clickService.selectedRemittance.CD_SIGN_REMITTANCE
        };
        const filters: Object = this._filterConfigService.buildValuesHashmap();
        Object.assign(queryParams, filters);
        this._queryService.getSqlResult(this.gridService.gridConf[this.idGrid].template, queryParams).subscribe(data => {
            this.setData(data);
        });
    }

    private _refreshMultiHeaderOptions(): void {
        if (this._isMultiHeader()) {
            this._setPreHeaderPanelOptions(true, true);
            if (this._angularGrid?.slickGrid) {
                this._angularGrid.slickGrid.setPreHeaderPanelVisibility(true, true);
            }
        } else {
            this._setPreHeaderPanelOptions(true, false);
            if (this._angularGrid?.slickGrid) {
                this._angularGrid.slickGrid.setPreHeaderPanelVisibility(false, true);
            }
        }
    }

    private _setPreHeaderPanelOptions(createPreHeaderPanel: boolean, showPreHeaderPanel: boolean): void {
        this.gridOptions.createPreHeaderPanel = createPreHeaderPanel;
        this.gridOptions.showPreHeaderPanel = showPreHeaderPanel;
    }

    private _getFlattenColumnFilters(colFilters: string[]): string[] {
        /**
         * colFilter may be conditional aka colFilter = 'colFilterValue_1||colFilterValue_2'
         */
        return colFilters.reduce((previous: string [], colFilter: string) => {
            const hasConditionalRegExp: boolean[] = [];
            
            ['||', '&&'].forEach((conditionalRegExp: string, index: number) => {
                hasConditionalRegExp.push(colFilter.includes(conditionalRegExp));

                if (!hasConditionalRegExp[index]) {
                    return;
                }

                colFilter
                    .split(conditionalRegExp)
                    .forEach(item => previous.push(item));
            });

            !hasConditionalRegExp.some(conditioned => (conditioned)) && previous.push(colFilter);

            return previous;
        }, [])
    }

    /**
     * If in the conf of the grid group is defined and the column is displayed, the grid has a multi-header.
     */
    private _isMultiHeader(): boolean {
        const filters: Object = this._filterConfigService.buildValuesHashmap();
        const columns: ColumnDef[] = this.gridService.gridConf[this.idGrid].columns;

        for (const column of columns) {
            if (typeof column.group === 'undefined' || column.group.trim() === '') {
                continue;
            }
            if (typeof column.filters === 'undefined') {
                return true;
            }

            const colFilters = this._getFlattenColumnFilters(column.filters);

            for (const filter in filters) {
                if (colFilters.indexOf(filter) !== -1) {
                    return true;
                }
            }
        }
        return false;
    }

    private _filterColumns(columns: Column[]): Column[] {
        const filterValues: string[] = Object.keys(this._filterConfigService.buildValuesHashmap());
        const filterGroup: FilterGroup[] = this._filterDimValueService.config;

        return columns.filter((col: Column) => {
            if (!col.params || (!col.params.filters && !col.params.checkFilters)) {
                return true;
            }

            let actifFilters: boolean[] = undefined;
            if (col.params.checkFilters) {
                actifFilters = col.params.checkFilters.map((checkFilter: checkFiltersDef) => {
                    let result: FilterDim = filterGroup.find((v :FilterGroup) => v.groupId === checkFilter.idGroup)
                        .filters.find((filter: Filter) => filter.filterName === checkFilter.filterName)
                        .dimensions.find((dim: FilterDim) => dim.name === checkFilter.dimName);
                    return typeof result !== 'undefined' && this._checkApplyValues(result, checkFilter.values);
                });
            }
            
            let resultFilters: boolean[] = undefined;
            if(col.params.filters) {
                const filters = col.params.filters.map((val: string) => val.split("||"));
                resultFilters = filters.map((values: string[]) => {
                return values.some((filterColumn: string) => filterValues.indexOf(filterColumn) !== -1)
            })
            }
            
            if (typeof actifFilters === 'undefined' && col.params.filters) {
                return resultFilters.every((val: boolean) => val === true);
            } else if (typeof resultFilters === 'undefined' && col.params.checkFilters) {
                return actifFilters.every((val: boolean) => val === true);
            } else {
                return resultFilters.every((val: boolean) => val === true) && actifFilters.every(val => val === true);
            }
        })
    }

    private _updateDynamicHeaderLabel(): void {
        this._updateDynamicHeaderLabelByDefinition(this.columnDefinitions);
    }

    private _checkApplyValues(obj1: any, obj2: any) {
        return Object
            .keys(obj2)
            .every(val => obj1.hasOwnProperty(val)
            && obj1[val] === obj2[val])
    }

    private _updateDynamicHeaderLabelByDefinition(columnDefinitions: Column[]): void {
        for (const col of columnDefinitions) {
            if (!col.params.dynamic_label) {
                continue;
            }
            const headerLabel: Object = this.gridData.find(data => data[col.params.dynamic_label]);
            if (headerLabel) {
                col.name = this._translateService.instant(headerLabel[col.params.dynamic_label]);
                col.nameKey = headerLabel[col.params.dynamic_label];
            }
        }
    }

    private _createGrid(): void {
        this._subscribeGridConfig();
    }

    private _subscribeGridConfig(): void {
        this._subscriptions.add(this.gridService.getColumnDefinitions(this.idGrid)
            .subscribe(defs => {
                this.columnDefinitions = defs;
                this.columnDefinitionsBackup = this.gridService.buildAllColumns(this.idGrid, true);
                this.filterColumns = this.columnDefinitions.some(col => col.params && (col.params.filters || col.params.checkFilters));
                this.dynamicColumnLabel = this.columnDefinitions.some(col => col.params && col.params.dynamic_label);
                this._setGridOptions();

                if (this.remittance) {
                    this.fetchGridRemittanceData();
                }

                this.configLoaded.next();
            }));
    }

    private _setPagination(): void {
        this.paginationService = this._angularGrid.paginationService;
        const pageSizes: number[] = this.gridOptions.pagination.pageSizes;
        if (this.paginationService && this.paginationService.paginationOptions) {
            this.paginationService.paginationOptions.pageSizes = pageSizes;
            this.pageSizes = pageSizes.map(size => ({ value: size, label: size.toString() }));
            this.selectedSize = this.pageSizes[0];
        }
    }

    private _updateHeaderLabel(): void {
        this.columnDefinitions = this.columnDefinitions.map((col: Column) => {
            col.name = this._translateService.instant(col.nameKey);
            if (col.params.group) {
                col.columnGroup = this._translateService.instant(col.params.group);
            }
            return col;
        });
        if (this._angularGrid && this._angularGrid.dataView) {
            this.refreshDataView();
            this.resizeGrid();
        }
    }

    private _setGridOptions(): void {
        const gridOptions: GridOption = {
            autoResize: {
                container: '#' + this.dashboardContainerId,
                calculateAvailableSizeBy: 'container',
                resizeDetection: 'container'
            },
            enableAutoResize: true,
            gridAutosizeColsMode: GridAutosizeColsMode.legacyForceFit,
            enableHeaderButton: true,
            enableColumnPicker: false,
            enableTextExport: true
        };
        if (this.isPaginated) {
            this.gridOptions = this.gridService.getPaginedGridOptions();
        }
        Object.assign(this.gridOptions, gridOptions);
        Object.assign(this.gridOptionsExport, gridOptions);
    }

    private _formatExportedDate(value: number): string {
        if (value < 10) {
            return `0${value}`;
        }
        return value.toString();
    }

    private _setGridName(): void {
        const gridConf: GridConf = this.gridService.gridConf[this.idGrid];
        if (!gridConf.title) {
            this.name = gridConf.name;
        } else {
            const filterKeys: string[] = Object.keys(this._filterConfigService.buildValuesHashmap());
            for (const filterKey of filterKeys) {
                if (gridConf.title[filterKey]) {
                    this.name = gridConf.title[filterKey];
                    return;
                }
            }
        }
    }
}
