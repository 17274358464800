/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Injectable, NgModule, Compiler, NgModuleFactory } from '@angular/core';
import { RouterModule, Router, Routes, Route } from '@angular/router';

import { AngularSlickgridModule } from 'angular-slickgrid';
import { TranslateModule } from '@ngx-translate/core';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

import { SharedModule } from '@app/shared.module';

import { TemplatesService } from '@services/templates/templates.service';
import { MenuService } from '@services/menu/menu.service';

import { TemplateModel } from '@services/templates/template.model';
import { TemplateType } from '@services/templates/template-typings';
import { MenuItem } from '@services/menu/menu-typings';
import { CommonModule, registerLocaleData } from '@angular/common';
import { first, switchMap, mergeAll } from 'rxjs/operators';
import { from, Observable, EMPTY } from 'rxjs';
import en from '@angular/common/locales/en';

/* tslint:disable-next-line:no-any */
type DynamicComponent = any;
/* tslint:disable-next-line:no-any */
type DynamicModule = any;

registerLocaleData(en);

@Injectable({
    providedIn: 'root'
})
export class ComponentGeneratorService {
    public menuItems: MenuItem[];

    constructor(private readonly _templatesService: TemplatesService,
        private _menuService: MenuService,
        private readonly _compiler: Compiler,
        private readonly _router: Router) {
        this.menuItems = this._menuService.menuItems;
    }

    public createComponent(url: string, templateType: TemplateType, htmlArgs?: Object, jsArgs?: Object): Promise<NgModuleFactory<void>> {
        const generator: TemplateModel = this._templatesService.templates[templateType];
        if (!generator) {
            return;
        }

        const newComponent: DynamicComponent = generator.create(url, htmlArgs, jsArgs);
        const newModule: DynamicModule = this._createModule(url, newComponent);
        return this._compiler.compileModuleAsync(newModule).then(this._assignRoute.bind(this));
    }

    public initMenu(): Observable<NgModuleFactory<void>> {
        if (this.menuItems.length > 0) {
            return EMPTY;
        }
        const promises: Promise<NgModuleFactory<void>>[] = [];
        return this._menuService.fetchMenu()
            .pipe(
                first(),
                switchMap((menuItems: Array<MenuItem>) => {
                    this._generateComponent(menuItems, promises);
                    return from(promises).pipe(mergeAll());
                }));
    }

    private _createModule(url: string, newComponent: DynamicComponent): DynamicModule {
        const newRoutes: Routes = [{ path: url, component: newComponent }];

        @NgModule({
            imports: [
                CommonModule,
                RouterModule.forChild(newRoutes),
                AngularSlickgridModule,
                NzDatePickerModule,
                SharedModule,
                TranslateModule.forChild()
            ],
            declarations: [newComponent]
        })
        class NewModule { }
        return NewModule;
    }

    private _assignRoute(module: DynamicModule): void {
        const appRoutes: Routes = [...this._router.config];
        const route: Route = {
            path: `reports`,
            loadChildren(): DynamicModule { return module; }
        };
        appRoutes.unshift(route);
        this._router.resetConfig(appRoutes);
        this._menuService.isLoading = false;
    }

    private _generateComponent(menuItems: Array<MenuItem>, promises: Promise<NgModuleFactory<void>>[]): void {
        const htmlArgs: Object = {};
        for (const menuItem of menuItems) {
            const jsArgs: Object = {
                menuCode: menuItem.code,
                idsGrid: menuItem.idsGrid,
                idsChart: menuItem.idsChart,
                typeSelectionAvailable: menuItem.typeSelectionAvailable,
                paginatedGrid: menuItem.templateType === TemplateType.PAGINATED_GRID
            };
            promises.push(this.createComponent(menuItem.url, menuItem.templateType, htmlArgs, jsArgs));
            if (menuItem.children) {
                this._generateComponent(menuItem.children, promises);
            }
        }
    }
}
