/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component } from '@angular/core';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
}
