/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Injectable, Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { TemplateModel, ComponentInstance } from '@services/templates/template.model';
import { LanguageService } from '@services/language/language.service';

@Injectable()
export class SwapLanguageModel extends TemplateModel {

    constructor() {
        super();
    }

    protected _getHtmlTemplate(htmlArgs: Object): string {
        return `
<app-layout>
    <div>
        <label>{{'_LANG_._LANGUAGE_' | translate}}</label>
    </div>
    <div (click)="swapLanguage()">Click to swap language</div>
</app-layout>
        `;
    }

    protected _getComponent(selectorTemplate: string, htmlTemplate: string, jsArgs: Object): ComponentInstance {
        @Component({
            selector: selectorTemplate,
            template: htmlTemplate
        })
        class NewComponent implements OnDestroy {
            private readonly _languages: string[] = [
                'fr',
                'en'
            ];
            private _currentLangIndex: number;
            private readonly _subscriptions: Subscription = new Subscription();

            constructor(private readonly _languageService: LanguageService) {
                this._currentLangIndex = this._languages.indexOf(this._languageService.currentLang);
            }

            ngOnDestroy(): void {
                this._subscriptions.unsubscribe();
            }

            public swapLanguage(): void {
                this._currentLangIndex = this._currentLangIndex ? 0 : 1;
                this._subscriptions.add(this._languageService.setLanguage(this._languages[this._currentLangIndex]).subscribe());
            }
        }
        return NewComponent;
    }
}
