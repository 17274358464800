/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Injectable } from '@angular/core';

import { SwapLanguageModel } from './models/swap-language.model';
import { GridModel } from './models/grid.model';
import { Templates, TemplateType } from './template-typings';

@Injectable({
    providedIn: 'root'
})
export class TemplatesService {

    private readonly _templates: Templates = {};

    constructor() {
        this._templates = {
            [TemplateType.GRID]: new GridModel(),
            [TemplateType.PAGINATED_GRID]: new GridModel(),
            [TemplateType.SWAP_LANGUAGE]: new SwapLanguageModel()
        };
    }

    public get templates(): Templates { return this._templates; }

    public get templatesName(): { label: string }[] {
        const keys: string[] = Object.keys(this._templates);
        const names: { label: string }[] = [];

        for (const key of keys) {
            names.push({ label: key });
        }
        return names;
    }
}
