/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { TemplateModel } from './template.model';

export interface Templates {
    [index: string]: TemplateModel;
}

export enum TemplateType {
    GRID = 'grid',
    PAGINATED_GRID = 'paginated-grid',
    SWAP_LANGUAGE = 'swap-language'
}
