/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { isDevMode, QueryList, Directive } from '@angular/core';
import { Subscription, Observable, combineLatest, from } from 'rxjs';
import { first } from 'rxjs/operators';

import { GenericGridService } from '@services/generic-grid/generic-grid.service';
import { GenericChartService } from '@services/generic-chart/generic-chart.service';
import { GenericGridClickService } from '@services/generic-grid/generic-grid-click.service';

import { ReportPlaceholderComponent } from '@components/report-placeholder/report-placeholder.component';
import { GridComponent } from '@components/grid/grid.component';
import { ChartComponent } from '@components/chart/chart.component';
import { FiltersComponent } from '@components/filters/filters.component';
import { GridSize } from '@slickgrid-universal/common';

export interface GridTemplateJSArgs {
    idsGrid: number[];
    idsChart: number[];
    typeSelectionAvailable: boolean;
    menuCode: string;
    paginatedGrid: boolean;
}

interface GridTemplateParams {
    menuCode: string;
    idsGrid: number[];
    idsChart: number[];
    typeSelectionAvailable: boolean;
    paginatedGrid: boolean;
    gridService: GenericGridService;
    chartService: GenericChartService;
    clickService: GenericGridClickService;
}

@Directive()
export class GridTemplateComponent {
    public filters: FiltersComponent;
    public placeholder: ReportPlaceholderComponent;
    public appGrid: QueryList<GridComponent>;
    public appChart: QueryList<ChartComponent>;
    public filtersRemittanceChild: FiltersComponent;
    public appGridRemittanceChild: GridComponent;

    public appGridList: GridComponent[] = [];
    public appChartList: ChartComponent[] = [];
    public menuCode: string;
    public idsGrid: number[];
    public idsChart: number[];
    // First id grid or id graph found in idsGrid or idsChart
    public idReportFilter: number;
    public centerPlaceholder: boolean;
    public multiReport: boolean;
    public typeSelectionAvailable: boolean;
    public paginatedGrid: boolean;
    public gridService: GenericGridService;
    public chartService: GenericChartService;
    public clickService: GenericGridClickService;
    public exportingExcel = false;
    public displayLoader = false;
    public hiddenGrid: Object = {};
    public defaultFilterType = '';
    public printingContract = false;

    protected subscriptions: Subscription = new Subscription();

    private readonly _beforePrintFunc: () => void = this._beforePrint.bind(this);
    private readonly _afterPrintFunc: () => void = this._afterPrint.bind(this);

    constructor(params: GridTemplateParams) {
        this.menuCode = params.menuCode;
        this.idsGrid = params.idsGrid;
        this.idsChart = params.idsChart;
        this.typeSelectionAvailable = params.typeSelectionAvailable;
        this.paginatedGrid = params.paginatedGrid;
        this.gridService = params.gridService;
        this.chartService = params.chartService;
        this.clickService = params.clickService;
        if (this.idsGrid.length === 0 && this.idsChart.length === 0) {
            if (isDevMode()) {
                console.error(`LIST_GRID AND LIST_GRAPH empty for ${this.menuCode}`);
            }
        } else {
            this.idReportFilter = this.idsGrid[0] || this.idsChart[0];
        }

        this.gridService.gridId = this.idsGrid;
        this.chartService.chartId = this.idsChart;
        this.multiReport = this.idsGrid.length > 1 || this.idsChart.length > 1;

        this.subscriptions.add(this.gridService.gridDataSub.subscribe(results => this._result(results, this.appGridList)));
        this.subscriptions.add(this.chartService.chartDataSub.subscribe(results => this._result(results, this.appChartList)));
        this.subscriptions.add(this.gridService.gridDataLimitSub.subscribe(this._setLimit.bind(this)));
        this.subscriptions.add(this.chartService.chartDataLimitSub.subscribe(this._setLimit.bind(this)));

        window.addEventListener('beforeprint', this._beforePrintFunc, true);
        window.addEventListener('afterprint', this._afterPrintFunc, true);
    }

    public resizeGrid(): void {
        for (const appGrid of this.appGridList) {
            appGrid.resizeGrid();
        }
    }

    public filtersCollapsed(collapsed: boolean): void {
        if (!collapsed && this.placeholder.rowsLimit) {
            this.placeholder.rowsLimit = undefined;
            this.placeholder.displayPlaceholder();
        }
        // Wait filters collapse
        setTimeout(() => {
            this.resizeGrid();
        });
    }

    public resizeRemittanceGrid(): void {
        this.appGridRemittanceChild.resizeGrid();
    }

    public expandFilters(): void {
        this.filters.collapseAllGroup(false);
        this.placeholder.displayPlaceholder();
        this.centerPlaceholder = false;
    }

    public forceResult(): void {
        this.centerPlaceholder = false;
        this.filters.collapseAllGroup(false);
        if (this.filters.selectedType === 'grid') {
            this.gridService.fetchGridData();
        } else {
            this.chartService.fetchChartData();
        }
    }

    public exportExcel(): void {
        this.exportingExcel = true;
        this.forceResult();
        this.placeholder.displayPlaceholder();
    }

    public exportExcelStart(): void {
        this.displayLoader = true;
    }

    public exportExcelDone(): void {
        this.displayLoader = false;
        if (this.exportingExcel) {
            this.exportingExcel = false;
            this.resetData();
        }
    }

    public switchType(type: string): void {
        this.filters.selectedType = type;
        if (type === 'grid') {
            this.gridService.fetchGridData();
        } else {
            this.chartService.fetchChartData();
        }
    }

    public resetData(): void {
        for (const appGrid of this.appGridList) {
            appGrid.setData([]);
            appGrid.displayEmptyData = false;
        }
        for (const appChart of this.appChartList) {
            appChart.setData([]);
            appChart.displayEmptyData = false;
        }
        this.placeholder.displayPlaceholder();
        if (this.clickService.selectedRemittance) {
            this.clickService.resetRemittance();
        }
    }

    public printDashboard(): void {
        this.displayLoader = true;
        if (this.clickService.selectedRemittance) {
            this.filtersRemittanceChild.collapseAllGroup(true);
            this.appGridRemittanceChild.setPrintOptions();
            this.appGridRemittanceChild.refreshDataView();
            from(this.appGridRemittanceChild.angularGrid.resizerService.resizeGrid()).pipe(first()).subscribe(d => {
                window.print();
            });

            return;
        }
        this.filters.collapseAllGroup(true);
        if (this.appGridList.length > 0 && this.filters.selectedType === 'grid') {
            const appGridReady: Observable<GridSize>[] = [];
            for (const appGrid of this.appGridList) {
                appGrid.setPrintOptions();
                appGridReady.push(from(appGrid.angularGrid.resizerService.resizeGrid()));
            }
            // Wait filters collapse
            setTimeout(() => {
                combineLatest(appGridReady).pipe(first()).subscribe(d => {
                    window.print();
                });
            });
        } else if (this.appChartList.length > 0 && this.filters.selectedType === 'chart') {
            // Wait filters collapse
            setTimeout(() => {
                window.print();
            });
        }
    }

    public printContract(): void {
        this.printingContract = true;
        setTimeout(() => {
            window.print();
        });
    }

    public destroy(): void {
        window.removeEventListener('beforeprint', this._beforePrintFunc, true);
        window.removeEventListener('afterprint', this._afterPrintFunc, true);
    }

    public hideGrid(idGrid: number): void {
        this.hiddenGrid[idGrid] = true;
    }

    protected setSelectedFilterType(): void {
        if (!this.typeSelectionAvailable) {
            if (this.idsGrid.length > 0 && this.idsChart.length === 0) {
                this.defaultFilterType = this.filters.selectedType = 'grid';
            } else if (this.idsChart.length > 0 && this.idsGrid.length === 0) {
                this.defaultFilterType = this.filters.selectedType = 'chart';
            }
        }
    }

    private _setLimit(rowsLimit: number): void {
        this.resetData();
        this.filters.collapseAllGroup(true);
        this.placeholder.setRowsLimit(rowsLimit);
        this.centerPlaceholder = true;
    }

    private _result(results: Object[], appList: GridComponent[] | ChartComponent[]): void {
        this.hiddenGrid = {};
        const dataLength: number = this._assignDataToContainers(results, appList);
        if (this.exportingExcel) {
            return;
        }
        this.placeholder.hidePlaceholder();
        this._resolveTypeSelectionVisibility(dataLength);
    }

    private _assignDataToContainers(results: Object[], appList: GridComponent[] | ChartComponent[]): number {
        const appObjectList: { [id: number]: GridComponent | ChartComponent } = {};
        let dataLength = 0;

        for (const app of appList) {
            if (app['idGrid']) {
                appObjectList[app['idGrid']] = app;
            } else {
                appObjectList[app['idChart']] = app;
            }
        }
        for (const result of results) {
            if (result['data'].length > 0) {
                dataLength = result['data'].length;
            }
            if (result['gridId']) {
                appObjectList[result['gridId']].setData(result['data']);
                if (this.exportingExcel) {
                    (appObjectList[result['gridId']] as GridComponent).exportExcel();
                }
            } else {
                appObjectList[result['chartId']].setData(result['data']);
            }
        }

        return dataLength;
    }

    private _resolveTypeSelectionVisibility(dataLength: number): void {
        if (dataLength > 0) {
            if (this.typeSelectionAvailable) {
                this.filters.displayTypeSelection = false;
            }
            if (this.multiReport) {
                this.filters.collapseAllGroup(true);
            }
        } else {
            if (this.typeSelectionAvailable) {
                this.filters.displayTypeSelection = true;
            }
        }
    }

    private _beforePrint(): void {
        if (this.clickService.selectedRemittance) {
            this.appGridRemittanceChild.refreshDataView();
            this.appGridRemittanceChild.resizeGrid();
            return;
        }
        for (const appGrid of this.appGridList) {
            appGrid.refreshDataView();
            appGrid.resizeGrid();
        }
    }

    private _afterPrint(): void {
        this.displayLoader = false;
        if (this.clickService.selectedRemittance) {
            this.appGridRemittanceChild.resetPrintOptions();
            return;
        }
        if (this.clickService.selectedContract) {
            this.printingContract = false;
            return;
        }
        for (const appGrid of this.appGridList) {
            appGrid.resetPrintOptions();
        }
    }
}
