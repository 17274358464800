/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Injectable, isDevMode } from '@angular/core';
import { Observable, Subject, of, forkJoin } from 'rxjs';

import { QueryService } from '@services/query/query.service';
import { FilterConfigService } from '@services/filter-config/filter-config.service';
import { switchMap, first } from 'rxjs/operators';
import { ConfigService } from '@services/config/config.service';
import { LanguageService } from '@services/language/language.service';
import { ChartConfsCollection } from './generic-chart-typings/generic-chart-conf-collection.interface';
import { ChartOptions } from './generic-chart-typings/chart-options.class';
import { ChartConf } from './generic-chart-typings/generic-chart-conf.interface';
import { ChartFromDB } from './generic-chart-typings/chart-from-db.interface';

@Injectable({
    providedIn: 'root'
})
export class GenericChartService {
    public chartDataSub: Subject<Object[]> = new Subject();
    public chartDataLimitSub: Subject<number> = new Subject();
    public chartConf: ChartConfsCollection = {};
    public chartId: number[];

    private readonly _queryTemplateChart: string;

    constructor(private readonly _queryService: QueryService,
                private readonly _filterConfigService: FilterConfigService,
                private readonly _configService: ConfigService,
                private readonly _languageService: LanguageService) {
        this._queryTemplateChart = this._configService.templateGetChartConf;
        this._onFilterApply();
    }

    public getChartConfig(chartId: number): Observable<ChartConf> {
        return new Observable<ChartConf>(observer => {
            this._fetchChartConf(chartId).subscribe(value => {
                if (value) {
                    observer.next(value);
                } else {
                    if (isDevMode()) {
                        console.error('No chart conf found for id : ' + chartId);
                    }
                    observer.error();
                }
            });
        });
    }

    public getChartData(): Observable<Object[]> {
        return this.chartDataSub;
    }

    public getChartDataLimit(): Observable<number> {
        return this.chartDataLimitSub;
    }

    public fetchChartData(): void {
        const limit = false;
        this._fetchChartData(this._filterConfigService.buildValuesHashmap(), limit)
            .subscribe({
                next: this._applyChartResult.bind(this),
                error: this._applyChartResult.bind(this, [])
            });
    }

    public getChartName(chartId: number): string {
        if (!this.chartConf[chartId]) {
            return '';
        }
        return this.chartConf[chartId].name;
    }

    private _onFilterApply(): void {
        this._filterConfigService.onApplyChart.pipe(
            switchMap(filterValues => this._fetchChartData(filterValues))
        ).subscribe(this._applyChartResult.bind(this));
    }

    private _applyChartResult(data: Object[][]): void {
        let cardinality: number;
        const results: Object[] = [];
        for (let i = 0; i < data.length; ++i) {
            if (data[i].length === 1 && typeof data[i][0] === 'number') {
                if (typeof cardinality === 'undefined' || cardinality < data[i][0]) {
                    cardinality = data[i][0] as number;
                }
            } else {
                results.push({
                    chartId: this.chartId[i],
                    data: data[i]
                });
            }
        }

        if (typeof cardinality !== 'undefined') {
            this.chartDataLimitSub.next(cardinality);
        } else {
            this.chartDataSub.next(results);
        }
    }

    private _fetchChartConf(chartId: number): Observable<ChartConf> {
        if (this.chartConf[chartId]) {
            return of(this.chartConf[chartId]);
        }
        const queryParams: {id_chart: number} = {id_chart: chartId};
        return this._queryService.getSqlResult(this._queryTemplateChart, queryParams).pipe(
            first(),
            switchMap((chart: ChartFromDB[]) => of(this._buildChartConf(chart[0])))
        );
    }

    private _buildChartConf(chart: ChartFromDB): ChartConf {
        if (!chart) {
            return;
        }
        const opt: ChartOptions = new ChartOptions(chart.CHART_OPT);
        const chartConf: ChartConf = {
            id: Number(chart.ID_CHART),
            name: chart.LB_CHART,
            filterGroups: [chart.ID_FLTR_GRP],
            config: JSON.parse(chart.CHART_CONFIG),
            template: opt.template,
            limit: opt.limit,
            filters: opt.filters
        } as ChartConf;
        this.chartConf[chartConf.id] = chartConf;
        return chartConf;
    }

    private _fetchChartData(filters?: Object, limit = true): Observable<Object[]> {
        let queryParams: Object = {};
        const cdLang: Object = { CD_LANG: this._languageService.currentLang };
        if (typeof filters !== 'undefined') {
            queryParams = filters;
        }
        Object.assign(queryParams, cdLang);
        const queries: Observable<Object[]>[] = [];
        for (const chartId of this.chartId) {
            let queryLimit: number;
            if (limit) {
                queryLimit = this.chartConf[chartId].limit || this._configService.rowsLimit;
            }
            queries.push(this._queryService.getSqlResult(this.chartConf[chartId].template, queryParams, queryLimit));
        }
        return forkJoin(queries);
    }
}
