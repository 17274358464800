/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, Output, EventEmitter } from '@angular/core';

export enum State {
    PLACEHOLDER,
    EMPTY_DATA,
    NONE
}

@Component({
    selector: 'app-report-placeholder',
    templateUrl: './report-placeholder.component.html',
    styleUrls: ['./report-placeholder.component.scss']
})
export class ReportPlaceholderComponent {
    @Output() forceResult: EventEmitter<void> = new EventEmitter<void>();
    @Output() closeRowsLimit: EventEmitter<void> = new EventEmitter<void>();
    @Output() exportExcel: EventEmitter<void> = new EventEmitter<void>();

    public rowsLimit: number;
    public stateEnum: typeof State = State;
    public currentState: State = State.PLACEHOLDER;

    public displayEmptyData(): void {
        this.currentState = State.EMPTY_DATA;
    }

    public displayPlaceholder(): void {
        this.currentState = State.PLACEHOLDER;
    }

    public hidePlaceholder(): void {
        this.currentState = State.NONE;
    }

    public setRowsLimit(value: number): void {
        this.rowsLimit = value;
        this.hidePlaceholder();
    }

    public forceResultTrigger(): void {
        this.rowsLimit = undefined;
        this.forceResult.next();
    }

    public closeMaxRowsComponent(): void {
        this.rowsLimit = undefined;
        this.closeRowsLimit.next();
    }

    public exportExcelTrigger(): void {
        this.rowsLimit = undefined;
        this.exportExcel.next();
    }
}
