/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { DateTime } from 'luxon';

import { AuthService, JurisdictionLevel } from '@services/auth/auth.service';

import {
    FilterGroup,
    FilterDimType,
    FilterDim,
    FilterDimValue,
    DimValueTypes
} from '@components/filters/filter-typings';
import { Router } from '@angular/router';

interface SelectedDimValue {
    filterDimId: number;
    displayedValue?: FilterDimValue;
    value: FilterDimValue | FilterDimValue[];
}

@Injectable()
export class FilterDimValueService {
    public dateFormat = 'yyyy-MM-dd';
    public applyAvailable = true;

    private _config: FilterGroup[];
    private _selectedDimValues: SelectedDimValue[] = [];
    private readonly _filterDimValueUpdated: Subject<void> = new Subject();
    private readonly _mainHierarchicLevels: number[] = [1, 2]; // for perim filter

    constructor(private readonly _authService: AuthService, private readonly _router: Router) {}

    get config(): FilterGroup[] { return this._config; }
    set config(conf: FilterGroup[]) {
        this._extractSelectedFilterDimValues();
        this._config = conf;
        this.resetValues();
    }
    get filterDimValueUpdated(): Observable<void> { return this._filterDimValueUpdated.asObservable(); }
    get mainHierarchicLevels(): number[] { return this._mainHierarchicLevels; }

    public buildValue(dim: FilterDim, value: DimValueTypes): FilterDimValue {
        const newValue: FilterDimValue = new FilterDimValue({
            label: '',
            value
        } as FilterDimValue);
        newValue.type = dim.valuetype;
        return newValue;
    }

    public resetValue(dim: FilterDim): void {
        const resetMethods: ((fltr: FilterDim) => void)[] = [];
        resetMethods[FilterDimType.SELECT] = this._resetSelectDim.bind(this);
        resetMethods[FilterDimType.MULTISELECT] = this._resetMultiSelectDim.bind(this);
        resetMethods[FilterDimType.DATE] = this._resetDateDim.bind(this);
        resetMethods[FilterDimType.VALUE] = this._resetValueDim.bind(this);
        resetMethods[FilterDimType.RADIO] = this._resetRadioDim.bind(this);
        resetMethods[FilterDimType.HIERARCHICALSELECT] = this._resetSelectHerarchicalDim.bind(this);
        resetMethods[dim.type](dim);
    }

    public resetValues(): void {
        this._config.forEach(group => {
            group.filters.forEach(filter => {
                filter.dimensions.forEach(dim => {
                    this.resetValue(dim);
                });
            });
        });
        this.filterDimValueUpdate();
    }

    public resetSelectedValues(): void {
        this._selectedDimValues = [];
    }

    public searchSelectedValue(dim: FilterDim): void {
        const selectedFDV: SelectedDimValue = this._loadSelectedFilterDimValue(dim.id);

        if (selectedFDV !== null) {
            if (dim.type === FilterDimType.SELECT) {
                const translationValue: FilterDimValue | FilterDimValue[] = this.getCurrentTranslationValues(dim, selectedFDV.value);
                if (translationValue instanceof FilterDimValue && typeof translationValue !== 'undefined'
                    || Array.isArray(translationValue) && translationValue.length === (selectedFDV.value as FilterDimValue[]).length) {
                    selectedFDV.value = translationValue;
                }
            }
            this._setSelectedValue(dim, selectedFDV);
        }
    }

    public hasSelectedValue(dimId: number): boolean {
        return this._loadSelectedFilterDimValue(dimId) !== null;
    }

    public listHasSelectedValue(dimIds: number[]): boolean {
        return dimIds.some(dimId => this.hasSelectedValue(dimId));
    }

    /**
     * filterDimValueUpdate
     *
     * Call this function everytime a filter dim value is updated.
     * The "Display" button is then enabled or not if all the mandatory
     * filters are set
     */
    public filterDimValueUpdate(): void {
        let perimeterSelected = false;
        this.applyAvailable = true;
        this._config.forEach(group => {
            group.filters.forEach(filter => {
                filter.dimensions.forEach(dim => {
                    if (dim.isMandatory && !dim.hasValue) {
                        this.applyAvailable = false;
                        return;
                    }
                    /**
                     * The following condition lets us choose which is the list of dim to look for
                     * in order to determine if a perimeter is selected or not
                     * This behaviour can not be properly and easily implemented via the is_mandatory
                     * flag stored in the database metadata
                     */
                    if (['CD_ENTITE_JURIDIQUE', 'CD_CODE_CHAINE'].indexOf(dim.name) !== -1 && dim.hasValue) {
                        perimeterSelected = true;
                    }
                });
            });
        });
        /**
         * Do not block the display button if the super user is on the support dashboard
         */
        if (this._authService.jurisdictionLevel === JurisdictionLevel.T && this._router.url !== '/reports/support') {
            this.applyAvailable = perimeterSelected;
        }
        this._filterDimValueUpdated.next();
    }

    /**
     * Return the given dimension values filtered of their duplicates
     * Duplication criteria is the value.value (do not check the parent)
     */
    public removeFilterDimValuesDuplicates(dim: FilterDim): FilterDimValue[] {
        const values: Object = {};
        return dim.values.filter(filterVal => {
            if (values[filterVal.value]) {
                return false;
            }
            values[filterVal.value] = true;
            return true;
        });
    }

    /**
     * 
     * Return the given dimension values related by hierachy level
     */
    public removeFilterDimValuesByRelated(dim: FilterDim, filterBy: string[], newValue: FilterDimValue): FilterDimValue[] {
        if (!filterBy.length) {
            return dim.values;
        }

        return dim.values.filter(value => {
            return (filterBy.length && filterBy[0] === value.parentValue) || 
                newValue?.value === value.parentValue || 
                value.value === 'all';
        })
    }

    public isDimensionVisible(dim: FilterDim): boolean {
        const hideEmpty: boolean = typeof dim.hideEmpty === 'undefined' ? false : dim.hideEmpty === 1;
        const hasSelectableValues: boolean = typeof dim.values !== 'undefined' && dim.values.length > 0;

        return !hideEmpty || (hideEmpty && hasSelectableValues);
    }

    private _resetSelectHerarchicalDim(dim: FilterDim): void {
        delete dim._value;
        delete dim._values;
    }

    private _resetSelectDim(dim: FilterDim): void {
        if (typeof dim['_filteredValues'] === 'undefined') {
            /**
             * Since the fact that a selector can contain multiple instances of an element
             * but with different parents, we have to remove the duplicates by value
             * when loading the filteredValues
             */
            dim['_filteredValues'] = this.removeFilterDimValuesDuplicates(dim);
        }
        let value: FilterDimValue = null;
        if (typeof dim.defaultValue !== 'undefined') {
            const defaultValue: FilterDimValue = dim.values.find((val: FilterDimValue) => val.value === dim.defaultValue);
            if (typeof defaultValue !== 'undefined') {
                value = defaultValue;
            }
        }
        dim.value = value;
    }

    private _resetMultiSelectDim(dim: FilterDim): void {
        if (typeof dim['_filteredValues'] === 'undefined') {
            dim['_filteredValues'] = this.removeFilterDimValuesDuplicates(dim);
        }
        const values: FilterDimValue[] = [];
        if (typeof dim.defaultValue !== 'undefined') {
            const defaultValue: FilterDimValue = dim.values.find((val: FilterDimValue) => val.value === dim.defaultValue);
            if (typeof defaultValue !== 'undefined') {
                values.push(defaultValue);
            }
        }
        dim.value = values;
    }

    private _resetValueDim(dim: FilterDim): void {
        let value: DimValueTypes;
        if (typeof dim.defaultValue !== 'undefined') {
            value = dim.defaultValue;
        }
        const newValue: FilterDimValue = this.buildValue(dim, value);
        dim.value = newValue;
        dim['_valueModel'] = dim._value.value;
    }

    private _resetDateDim(dim: FilterDim): void {
        dim['_dateModel'] = new Date();
        if (typeof dim.defaultValue !== 'undefined') {
            dim['_dateModel'] = new Date(dim.defaultValue);
        }
        const newDate: string = DateTime.fromJSDate(new Date(dim['_dateModel'])).toFormat(this.dateFormat);
        const newValue: FilterDimValue = this.buildValue(dim, newDate);
        dim.value = newValue;
    }

    private _resetRadioDim(dim: FilterDim): void {
        delete dim._value;
        if (dim.radioConstraint.indexOf(dim.id) !== -1) {
            dim['_selected'] = (dim['_radioModel'] === dim.radioConstraint[0]);
            if (dim['_radioModel'] === dim.radioConstraint[0]) {
                dim.value = dim.values[0];
            }
        }
    }

    private _extractSelectedFilterDimValues(): void {
        if (typeof this._config === 'undefined' || this._config?.length === 0) {
            return;
        }
        this._config.forEach(group => {
            group.filters.forEach(filter => {
                filter.dimensions.forEach(dim => this.upsertSelectedFilterDimValue(dim));
            });
        });
    }

    /**
     * Searches the current selected dim values to update or create a new entry
     *
     * Handles the case when a selected value is set but the user erased it
     */
    public upsertSelectedFilterDimValue(dim: FilterDim): void {
        let selectedDimIndex: number = this._selectedDimValues.findIndex(
            dimValue => dimValue.filterDimId === dim.id
        );
        if (dim.hasValue) {
            if (selectedDimIndex !== -1) {
                this._selectedDimValues[selectedDimIndex].value = dim.value;
            } else {
                selectedDimIndex = this._selectedDimValues.push({
                    filterDimId: dim.id,
                    value: dim.value
                }) - 1;
            }
            if (dim.type === FilterDimType.HIERARCHICALSELECT) {
                this._selectedDimValues[selectedDimIndex].displayedValue = dim._value;
            }
        } else if (selectedDimIndex !== -1) {
            this._selectedDimValues.splice(selectedDimIndex, 1);
        }
    }

    private _loadSelectedFilterDimValue(dimId: number): SelectedDimValue {
        const selectedDimIndex: number = this._selectedDimValues.findIndex(
            dimValue => dimValue.filterDimId === dimId
        );

        if (selectedDimIndex !== -1) {
            return this._selectedDimValues[selectedDimIndex];
        }
        return null;
    }

    /**
     * Sets the array of specific value setting for each FilterDimType
     * Then, executes the appropriate function for the current dim
     */
    private _setSelectedValue(dim: FilterDim, selectedValue: SelectedDimValue): void {
        const setValueMethods: ((fltr: FilterDim, selectedValue: SelectedDimValue) => void)[] = [];
        setValueMethods[FilterDimType.SELECT] = this._setSelectDim.bind(this);
        setValueMethods[FilterDimType.MULTISELECT] = this._setSelectDim.bind(this);
        setValueMethods[FilterDimType.DATE] = this._setDateDim.bind(this);
        setValueMethods[FilterDimType.VALUE] = this._setValueDim.bind(this);
        setValueMethods[FilterDimType.HIERARCHICALSELECT] = this._setSelectDim.bind(this);
        setValueMethods[dim.type](dim, selectedValue);
        this.filterDimValueUpdate();
    }

    private _setSelectDim(dim: FilterDim, selectedValue: SelectedDimValue): void {
        dim.value = selectedValue.value;
        if (selectedValue.displayedValue) {
            dim._value = selectedValue.displayedValue;
            dim._hierarchicalModel = selectedValue.displayedValue.value;
        }
    }

    private _setDateDim(dim: FilterDim, selectedValue: SelectedDimValue): void {
        dim['_dateModel'] = new Date((selectedValue.value as FilterDimValue).value);
        dim.value = selectedValue.value;
    }

    private _setValueDim(dim: FilterDim, selectedValue: SelectedDimValue): void {
        dim.value = selectedValue.value;
        dim['_valueModel'] = dim._value.value;
    }

    private getCurrentTranslationValues(
        dim: FilterDim,
        filterDimValues: FilterDimValue | FilterDimValue[]
    ): FilterDimValue | FilterDimValue[] {
        if (Array.isArray(filterDimValues)) {
            const translatedFilterDimValues: FilterDimValue[] = [];
            for (const filterDimValue of filterDimValues) {
                translatedFilterDimValues.push(dim.values.find(value => value.value === filterDimValue.value));
            }
            return translatedFilterDimValues;
        }

        return dim.values.find(value => value.value === filterDimValues.value);
    }
}
