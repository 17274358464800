/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

export class GridOptions {
    public template: string;
    public limit: number;
    public title: Object;

    constructor(_: string) {
        const options: GridOptions = JSON.parse(_);
        this.template = options.template;
        this.limit = options.limit;
        this.title = options.title;
    }
}
