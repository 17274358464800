/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { FilterGroupDirection, FilterDimType, FilterDimValueType } from './';
/* tslint:disable: variable-name check-format */
export class FlatFilter {
    group_id: number;
    group_label: string;
    display_direction: FilterGroupDirection;

    filter_id: number;
    filter_name: string;
    filter_label: string;
    filter_sublabel?: string;
    filter_disable_constraint?: number[];
    filter_restrict_display_grid?: number;
    filter_disable?: boolean;

    dim_id: number;
    dim_type: FilterDimType;
    dim_label: string;
    dim_name: string;
    dim_default_value: string;
    is_mandatory: number;
    hierarchy_level?: number;
    hierarchy_parent_dim_name?: string[];
    dim_disable_constraint?: number[];
    dim_radio_constraint?: number[];
    dim_value_type?: FilterDimValueType;
    dim_radio_link?: number[];
    help_label?: string;
    hide_empty?: number;

    private readonly _castToArray: string[] = [
        'dim_disable_constraint',
        'dim_radio_constraint',
        'hierarchy_parent_dim_name',
        'filter_disable_constraint'
    ];

    constructor(_: Object) {
        for (const key of Object.keys(_)) {
            const propName: string = key.toLowerCase();
            const value: string | number = _[key];
            if (value !== null) {
                this[propName] = value;
                if (this._castToArray.includes(propName)) {
                    this[propName] = JSON.parse(value as string);
                }
            }
        }
    }
}
