/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Injectable } from '@angular/core';

import { FormatterService } from './formatter.class';
import { CssFormatterOptions } from '../slickgrid-formatter/slickgrid-formatter.service';

enum FormatMethodName {
    TRUNCATE = 'truncate',
    RIB = 'rib',
    PREFIX = 'prefix'
}

export interface StringFormatterOptions extends CssFormatterOptions {
    truncate?: number;
    rib?: boolean;
    prefix?: string;
    matchValue?: string;
}

@Injectable({
  providedIn: 'root'
})
export class StringFormatterService  extends FormatterService {
    public callOrder: FormatMethodName[] = [
        FormatMethodName.TRUNCATE,
        FormatMethodName.RIB,
        FormatMethodName.PREFIX
    ];

    public value(value: string): StringFormatterService {
        FormatterService.prototype.value.apply(this, [value]);
        return this;
    }

    public truncate(length: number): StringFormatterService {
        this._addCallToStack(FormatMethodName.TRUNCATE, arguments);
        return this;
    }

    public rib(): StringFormatterService {
        this._addCallToStack(FormatMethodName.RIB, arguments);
        return this;
    }

    public prefix(prefix: string): StringFormatterService {
        this._addCallToStack(FormatMethodName.PREFIX, arguments);
        return this;
    }

    private _truncate(length: number): void {
        this._displayedValue = this._displayedValue.substring(0, length);
    }

    private _rib(): void {
        // The regex will add a space after the 5th character and then another space after the next 5 characters
        this._displayedValue = this._displayedValue.replace(/^(.{5})(.{5})(.*)$/, '$1 $2 $3');
    }

    private _prefix(prefix: string): void {
        this._displayedValue = prefix + this._displayedValue;
    }

}
