/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

export interface ChartOptionsFilter {
    label?: string;
    dynamicLabel?: { [filterCode: string]: string };
    column?: string;
    columns?: ChartOptionsFilter[];
    stacked?: boolean;
    format?: string;
    yAxisLabel?: string;
    yAxisFormat?: string;
}

export class ChartOptions {
    public template: string;
    public filters: ChartOptionsFilter;
    public limit: number;

    constructor(_: string) {
        const options: ChartOptions = JSON.parse(_);
        this.template = options.template;
        this.filters = options.filters;
        this.limit = options.limit;
    }
}
