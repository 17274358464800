/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigService } from '@app/services/config/config.service';
import { AlertService } from '@services/alert/alert.service';
import { AuthService } from '@services/auth/auth.service';
import { Subscription, Observable, timer } from 'rxjs';

@Component({
  selector: 'app-alert-password-expiration',
  templateUrl: './alert-password-expiration.component.html',
  styleUrls: ['./alert-password-expiration.component.scss']
})
export class AlertPasswordExpirationComponent implements OnInit, OnDestroy {
    public displayAlert = false;
    public expiratedAlert = false;
    public nameParams: Object;

    private _subscription: Subscription;
    private _timer: Observable<number>;

    constructor(
        public authService: AuthService,
        private _alertService: AlertService,
        private readonly _configService: ConfigService
    ) {
        this.nameParams = { nbDaysUntilReset: this.authService.nbDaysUntilReset, multipleDays: (this.authService.nbDaysUntilReset > 1 ? 's' : '') };
        this.displayAlert = !this._alertService.alertPasswordExpirationAlreadyDisplay
                            && this._configService.passwordExpirationWarnings.includes(this.authService.nbDaysUntilReset);
        this.expiratedAlert = this.authService.nbDaysUntilReset <= 0;
    }

    ngOnInit(): void {
        this.setTimer();
    }

    ngOnDestroy(): void {
        if (this._subscription && this._subscription instanceof Subscription) {
            this._subscription.unsubscribe();
        }
    }

    public setTimer(): void {
        const timerVal = 60000;
        if (this.displayAlert) {
            this._timer = timer(timerVal);
            this._subscription = this._timer.subscribe(() => {
                this._alertService.alertPasswordExpirationAlreadyDisplay = true;
                this.displayAlert = false;
            });
        }
    }

    public manuallyCloseAlert(): void {
        this._alertService.alertPasswordExpirationAlreadyDisplay = true;
        this.displayAlert = false;
    }
}
