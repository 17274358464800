/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Legend } from '@components/charts/chart-typings/legend.interface';

@Component({
    selector: 'app-chart-legend',
    templateUrl: './chart-legend.component.html',
    styleUrls: ['./chart-legend.component.scss']
})
export class ChartLegendComponent {
    @Input() legends: Legend[] = [];
    @Output() toggleLegend: EventEmitter<Object> = new EventEmitter();
}
