/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

export type FormatValue = string | number | Date;

export interface FormatMethodCall {
    func: FormatMethodName;
    args: IArguments;
}

type FormatMethodName = string;

export abstract class FormatterService {
    protected callOrder: string[];

    protected _value: FormatValue;
    protected _displayedValue: string;
    protected _callStack: FormatMethodCall[] = [];

    public value(value: FormatValue): FormatterService {
        this._value = value;
        this._displayedValue = value.toString();
        this._callStack = [];
        return this;
    }

    public apply(): string {
        this.callOrder.forEach(funcName => {
            this._callStack.forEach(funcCall => {
                if (funcName === funcCall.func) {
                    this['_' + funcName].apply(this, Array.from(funcCall.args));
                }
            });
        });
        this._callStack = [];
        return this._displayedValue;
    }

    public getValue(): FormatValue {
        return this._value;
    }

    protected _addCallToStack(methodName: FormatMethodName, args: IArguments): void {
        this._callStack.push({
            'func': methodName,
            args
        });
    }
}
