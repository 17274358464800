/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Injectable } from '@angular/core';

export interface Error {
    'title': string;
    'message': string;
}

@Injectable()
export class ErrorService {
    public hasError = false;
    private _error: Error;

    public updateMessage(title: string, message: string): void {
        this._error = {
          title,
          message
        };
        this.hasError = true;
    }

    public clearMessage(): void {
        this._error = undefined;
        this.hasError = false;
    }

    get error(): Error {
        return this._error;
    }
}
