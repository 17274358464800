/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, Input, SimpleChanges, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { PaginationService } from 'angular-slickgrid';
import { Subject, Subscription } from 'rxjs';
import { SelectableElement } from '@components/grid/grid.component';

@Component({
    selector: 'app-grid-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges, OnDestroy {
    @Input() paginationService: PaginationService;
    @Input() refreshPagination: Subject<boolean>;

    public pageNumber: number;
    public pageCount: number;

    private readonly _subscriptions: Subscription = new Subscription();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.paginationService && changes.paginationService.currentValue) {
            this._refreshInfos();
        }
    }

    ngOnInit(): void {
        this._subscriptions.add(this.refreshPagination.subscribe(data => {
            setTimeout(() => this._refreshInfos());
        }));
        setTimeout(() => this._refreshInfos());
    }

    ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public goFirstPage(): void {
        if (!this.paginationService) {
            return;
        }

        this.paginationService.goToFirstPage();
        this._refreshNavigation();
    }

    public goPrevPage(): void {
        if (!this.paginationService) {
            return;
        }

        this.paginationService.goToPreviousPage();
        this._refreshNavigation();
    }

    public goNextPage(): void {
        if (!this.paginationService) {
            return;
        }

        this.paginationService.goToNextPage();
        this._refreshNavigation();
    }

    public goLastPage(): void {
        if (!this.paginationService) {
            return;
        }

        this.paginationService.goToLastPage();
        this._refreshNavigation();
    }

    public changePageNumber($event: SelectableElement): void {
        this.pageNumber = $event;
        if (!this.paginationService) {
            return;
        }

        this.paginationService.goToPageNumber(this.pageNumber);
        this._refreshNavigation();
    }

    private _refreshInfos(): void {
        if (!this.paginationService) {
            return;
        }
        this._refreshNavigation();
    }

    private _refreshNavigation(): void {
        this.pageNumber = this.paginationService.pageNumber;
        this.pageCount = this.paginationService.pageCount;
    }
}
