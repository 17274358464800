/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
    @Input() svgIcon: string;

    constructor(
        private readonly _matIconRegistry: MatIconRegistry,
        private readonly _domSanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        this._matIconRegistry.addSvgIcon(
            this.svgIcon,
            this._domSanitizer.bypassSecurityTrustResourceUrl(`assets/svg/${this.svgIcon}.svg`)
        );
    }
}
