/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, OnDestroy, Input, OnChanges, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { QueryService } from '@services/query/query.service';
import { NumberFormatterService } from '@services/formatter/number-formatter.service';
import { LanguageService } from '@services/language/language.service';

interface QTreso {
    MT_CT_ANNONCE: number;
}

@Component({
    selector: 'app-cash-announcement',
    templateUrl: './cash-announcement.component.html',
    styleUrls: ['./cash-announcement.component.scss']
})
export class CashAnnouncementComponent implements OnInit, OnChanges, OnDestroy {
    @Input() filterValues: Object = {};

    public value: number;
    public displayedValue = '-';

    private readonly _subscriptions: Subscription = new Subscription();

    constructor(private readonly _queryService: QueryService,
                private readonly _numberFormatterService: NumberFormatterService,
                private readonly _languageService: LanguageService) {}

    ngOnInit(): void {
        this._subscriptions.add(this._languageService.translationLoaded.subscribe(this._setValue.bind(this)));
    }

    ngOnChanges(): void {
        this._subscriptions.add(this._queryService.getSqlResult('home/Q_TRESO.tpl', this.filterValues).subscribe((data: QTreso[]) => {
            if (data.length > 0 && typeof data[0].MT_CT_ANNONCE !== 'undefined') {
                this._setValue(data[0].MT_CT_ANNONCE);
            } else {
                this._setValue(null);
            }
        }));
    }

    ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    private _setValue(value?: number): void {
        if (typeof value !== 'undefined') {
            this.value = value;
        }
        this.displayedValue = this.value !== null
            ? this._numberFormatterService.value(this.value).format('0,0').apply() + ' €'
            : '-';
    }
}
