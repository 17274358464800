/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, ViewChild, ChangeDetectorRef, AfterViewInit, OnDestroy, Input, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataSets, ChartYAxe } from 'chart.js';
import { v4 as uuid } from 'uuid';
import { Subscription } from 'rxjs';

import { QueryService } from '@services/query/query.service';
import { ChartMixedComponent } from '@components/charts/chart-mixed/chart-mixed.component';
import { NumberFormatterService } from '@services/formatter/number-formatter.service';
import { LanguageService } from '@services/language/language.service';

interface QSales {
    DT_VENTE: string;
    NB_TRANSACTIONS: number;
    MT_VENTES_NETTES: number;
}

@Component({
    selector: 'app-sales',
    templateUrl: './sales.component.html',
    styleUrls: ['./sales.component.scss']
})
export class SalesComponent implements OnChanges, AfterViewInit, OnDestroy {
    @Input() filterValues: Object = {};
    @ViewChild('salesChart') salesChart: ChartMixedComponent;

    public salesChartId: string = uuid();
    public labels: string[] = [];
    public datasetsLines: ChartDataSets[] = [];
    public yAxis: ChartYAxe[] = [
        {
            id: 'y-axis-1',
            type: 'linear',
            position: 'left',
            gridLines: {
                drawBorder: true,
                drawTicks: false,
                drawOnChartArea: true
            },
            ticks: {
                callback: this._formatYAxisLabel.bind(this, '0,0')
            }
        },
        {
            id: 'y-axis-2',
            type: 'linear',
            position: 'right',
            gridLines: {
                drawBorder: true,
                drawTicks: false,
                drawOnChartArea: false
            },
            ticks: {
                callback: this._formatYAxisLabel.bind(this, '0,0.[00] a')
            }
        }
    ];

    private readonly _volumeColor = '#55CB72';
    private readonly _amountColor = '#1C89E8';
    private readonly _volumeGradient: string[] = ['rgba(80, 199, 125, 0.2)', 'rgba(80, 199, 125, 0)'];
    private readonly _amountGradient: string[] = ['rgba(28, 137, 232, 0.2)', 'rgba(28, 137, 232, 0)'];
    private readonly _yAxisPadding = 12;
    private readonly _subscriptions: Subscription = new Subscription();

    constructor(private readonly _queryService: QueryService,
                private readonly _changeDetector: ChangeDetectorRef,
                private readonly _numberFormatterService: NumberFormatterService,
                private readonly _translateService: TranslateService,
                private readonly _languageService: LanguageService) {
        this.yAxis[0].ticks.fontColor = this._volumeColor;
        this.yAxis[1].ticks.fontColor = this._amountColor;
        this.yAxis[0].ticks.padding  = this.yAxis[1].ticks.padding = this._yAxisPadding;
    }

    ngOnChanges(): void {
        const sqlTemplate = 'home/Q_VENTES_JOURS_GLISSANTS.tpl';
        this._subscriptions.add(this._queryService.getSqlResult(sqlTemplate, this.filterValues).subscribe(this._setChartConfig.bind(this)));
    }

    ngAfterViewInit(): void {
        this._subscriptions.add(this._languageService.translationLoaded.subscribe(() => {
            if (this.salesChart.customLegend.length > 0) {
                this.salesChart.customLegend[0].text = this._getVolumeLabel();
                this.salesChart.customLegend[1].text = this._getAmountLabel();
            }
            this.salesChart.updateChart();
        }));
    }

    ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    private _setChartConfig(data: QSales[]): void {
        const ctx: CanvasRenderingContext2D = (document.getElementById(this.salesChartId) as HTMLCanvasElement).getContext('2d');
        const y1 = 400;
        const gradientFillVolume: CanvasGradient = ctx.createLinearGradient(0, 0, 0, y1);
        gradientFillVolume.addColorStop(0, this._volumeGradient[0]);
        gradientFillVolume.addColorStop(1, this._volumeGradient[1]);
        const gradientFillAmount: CanvasGradient = ctx.createLinearGradient(0, 0, 0, y1);
        gradientFillAmount.addColorStop(0, this._amountGradient[0]);
        gradientFillAmount.addColorStop(1, this._amountGradient[1]);

        this.labels.length = 0;
        const dataVolume: number[] = [];
        const dataAmount: number[] = [];
        const currentDate: Date = new Date();
        data.forEach(item => {
            dataVolume.push(item.NB_TRANSACTIONS);
            dataAmount.push(item.MT_VENTES_NETTES);
            const date: Date = new Date(item.DT_VENTE);
            const dayInMilliseconds = 86400000;
            const diff = `J-${Math.floor(((currentDate.getTime() - date.getTime()) / dayInMilliseconds))}`;
            this.labels.push(diff);
        });
        this.datasetsLines = [{
            label: this._getVolumeLabel(),
            data: dataVolume,
            pointBackgroundColor: this._volumeColor,
            backgroundColor: gradientFillVolume,
            borderColor: this._volumeColor,
            lineTension: 0.2,
            fill: true,
            yAxisID: 'y-axis-1'
        }, {
            label: this._getAmountLabel(),
            data: dataAmount,
            pointBackgroundColor: this._amountColor,
            backgroundColor: gradientFillAmount,
            borderColor: this._amountColor,
            lineTension: 0.2,
            fill: true,
            yAxisID: 'y-axis-2'
        }];
        this._changeDetector.detectChanges();
    }

    private _formatYAxisLabel(format: string, value: number): string {
        return this._numberFormatterService
                .value(value)
                .format(format)
                .apply();
    }

    private _getVolumeLabel(): string {
        return `${this._translateService.instant('_HOME_._VOLUME_')} (Nb)`;
    }

    private _getAmountLabel(): string {
        return `${this._translateService.instant('_HOME_._AMOUNT_')} (€)`;
    }
}
