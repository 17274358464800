/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { ConfigService } from '@services/config/config.service';

enum Choice {
    STOP,
    EXPORT_EXCEL,
    CONTINUE
}

@Component({
    selector: 'app-max-rows-reached',
    templateUrl: './max-rows-reached.component.html',
    styleUrls: ['./max-rows-reached.component.scss']
})
export class MaxRowsReachedComponent implements OnInit {
    @Input() nbRows: number;
    @Output() close: EventEmitter<void> = new EventEmitter();
    @Output() forceResult: EventEmitter<void> = new EventEmitter();
    @Output() exportExcel: EventEmitter<void> = new EventEmitter();

    public choiceEnum: typeof Choice = Choice;
    public selectedChoice: Choice = Choice.STOP;
    public nbRowsLimit: number = this._configService.rowsLimit;
    public nbMaxRowsLimit: number = this._configService.maxRowsLimit;
    public allowChoice = true;
    public translateParams: Object;
    public choices: Object[] = [
        {
            value: Choice.STOP,
            label: '_MAX_ROWS_._STOP_'
        },
        {
            value: Choice.EXPORT_EXCEL,
            label: '_MAX_ROWS_._EXPORT_',
            warning: '_MAX_ROWS_._WARNING_'
        },
        {
            value: Choice.CONTINUE,
            label: '_MAX_ROWS_._CONTINUE_',
            warning: '_MAX_ROWS_._WARNING_'
        }
    ];

    constructor(private readonly _configService: ConfigService) {}

    ngOnInit(): void {
        this.allowChoice = this.nbRows < this.nbMaxRowsLimit;
        this.translateParams = {
            'nbRows': this.nbRows,
            'nbMaxRows': this.allowChoice ? this.nbRowsLimit : this.nbMaxRowsLimit
        };
    }

    public submit(): void {
        if (!this.allowChoice) {
            this.close.next();
            return;
        }
        if (this.selectedChoice === Choice.STOP) {
            this.close.next();
        } else if (this.selectedChoice === Choice.CONTINUE) {
            this.forceResult.next();
        } else if (this.selectedChoice === Choice.EXPORT_EXCEL) {
            this.exportExcel.next();
        }
    }
}
