/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, Input } from '@angular/core';

const i18nPrefix = '_CONTRACT_.';

export class ContractContainer {
    title: string;
    titleSuffix: string | number;
    boxes: Array<ContractBox>;

    constructor(title: string, boxes: Array<ContractBox>, titleSuffix?: string | number) {
        this.title = i18nPrefix + title;
        this.titleSuffix = titleSuffix;
        this.boxes = boxes;
    }
}

export class ContractBox {
    title: string;
    entries: Array<ContractEntry>;

    constructor(title: string, entries: Array<ContractEntry>) {
        this.title = i18nPrefix + title;
        this.entries = entries;
    }
}

export class ContractEntry {
    label: string;
    value: string | number;

    constructor(lb: string, val: string | number) {
        this.label = i18nPrefix + lb;
        this.value = val;
    }
}

@Component({
  selector: 'app-contract-container',
  templateUrl: './contract-container.component.html',
  styleUrls: ['./contract-container.component.scss']
})
export class ContractContainerComponent {

    @Input() container: ContractContainer;
}
