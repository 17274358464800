/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, OnDestroy, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { QueryService } from '@services/query/query.service';
import { NumberFormatterService } from '@services/formatter/number-formatter.service';
import { LanguageService } from '@services/language/language.service';
import { Legend } from '@components/charts/chart-typings/legend.interface';

interface QSalesPerScheme {
    NU_RESEAU_AGG: number;
    NB_TRANSACTIONS: number;
    MT_BRUT_CREDIT: number;
    label?: string;
    displayedNbTransactions?: string;
    displayedMtBrut?: string;
    img?: string;
    imgExtension?: string;
}

@Component({
    selector: 'app-network-sales',
    templateUrl: './network-sales.component.html',
    styleUrls: ['./network-sales.component.scss']
})
export class NetworkSalesComponent implements OnInit, OnChanges, OnDestroy {
    @Input() filterValues: Object = {};

    public data: QSalesPerScheme[] = [];
    public legends: Legend[] = [
        {
            color: '#55CB72',
            type: 'line'
        },
        {
            color: '#1C89E8',
            type: 'line'
        }
    ];

    private readonly _icons: Object = {
        '1': { 'name': 'cb', 'extension': 'png' },
        '2': { 'name': 'visa', 'extension': 'png' },
        '3': { 'name': 'mastercard', 'extension': 'svg' },
        '4': { 'name': 'mastercard', 'extension': 'svg' },
        '5': { 'name': 'jcb', 'extension': 'svg' },
        '7': { 'name': 'discover', 'extension': 'png' },
        '9': { 'name': 'upi', 'extension': 'svg' }
    };
    private readonly _subscriptions: Subscription = new Subscription();

    constructor(private readonly _queryService: QueryService,
                private readonly _numberFormatterService: NumberFormatterService,
                private readonly _languageService: LanguageService,
                private readonly _translateService: TranslateService) {}

    ngOnInit(): void {
        this._subscriptions.add(this._languageService.translationLoaded.subscribe(() => {
            this._setDisplayedValues();
            this._setLegendText();
        }));
        this._setLegendText();
    }

    ngOnChanges(): void {
        this._subscriptions.add(
            this._queryService.getSqlResult('home/Q_VENTES_PAR_RESEAU.tpl', this.filterValues).subscribe((data: QSalesPerScheme[]) => {
                this.data = data.filter(item => {
                    if (!this._icons[item.NU_RESEAU_AGG]) {
                        return null;
                    }
                    item.img = this._icons[item.NU_RESEAU_AGG].name;
                    item.imgExtension = this._icons[item.NU_RESEAU_AGG].extension;
                    item.label = this._icons[item.NU_RESEAU_AGG].name.toUpperCase();
                    return item;
                });
                this._setDisplayedValues();
            })
        );
    }

    ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    private _setDisplayedValues(): void {
        const format = '0,0';
        this.data.forEach(item => {
            item.displayedNbTransactions = item.NB_TRANSACTIONS !== null
                ? this._numberFormatterService.value(item.NB_TRANSACTIONS).format(format).apply()
                : '-';
            if (item.NB_TRANSACTIONS !== null && item.NB_TRANSACTIONS > 0) {
                item.displayedMtBrut = this._numberFormatterService.value(item.MT_BRUT_CREDIT).format(format).apply();
            }
        });
    }

    private _setLegendText(): void {
        this.legends[0].text = `${this._translateService.instant('_HOME_._VOLUME_')} (Nb)`;
        this.legends[1].text = `${this._translateService.instant('_HOME_._AMOUNT_')} (€)`;
    }
}
