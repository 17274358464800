/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { StatusCodes } from 'http-status-codes';

import { AuthService } from '@services/auth/auth.service';
import { mustMatch } from '@helpers/must-match.validator';

interface RequestErrors {
    credentials?: boolean;
    processAbortion?: boolean;
    newPasswordUnacceptable?: boolean;
    passwordResetTokenExpired?: boolean;
}

@Component({
    selector: 'app-reset-password-request',
    templateUrl: './reset-password-request.component.html',
    styleUrls: ['./reset-password-request.component.scss']
})
export class ResetPasswordRequestComponent implements OnInit, OnDestroy {
    public login = '';
    public loginRequiredError = false;
    public submitted = false;
    public displayRequestSent = false;
    public displayValidationRequestSent = false;
    public hasPasswordCode = false;
    public requestErrors: RequestErrors;
    public redirectTimeout = 3000;
    public isPasswordVisible: boolean[] = [false, false];

    public resetValidationForm: UntypedFormGroup;

    private readonly _subscriptions: Subscription = new Subscription();

    constructor(private readonly _authService: AuthService,
                private readonly _formBuilder: UntypedFormBuilder,
                private readonly _router: Router) { }

    ngOnInit(): void {
        const passwordRegexp = new RegExp('^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$');
        this.resetValidationForm = this._formBuilder.group({
            username: ['', [Validators.required]],
            resetCode: ['', [Validators.required]],
            password: ['', [Validators.required, Validators.pattern(passwordRegexp)]],
            confirmPassword: ['', Validators.required]
        }, {
            validator: mustMatch('password', 'confirmPassword')
        });
    }

    ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    get formControls(): { [key: string]: AbstractControl } { return this.resetValidationForm.controls; }

    public submitResetRequest(): void {
        if (this.login === '') {
            this.loginRequiredError = true;
            return;
        }
        this.loginRequiredError = false;
        this._subscriptions.add(this._authService.resetPasswordRequest(this.login).subscribe(() => {
            this.displayRequestSent = true;
        }, () => {
            this.displayRequestSent = true;
        }));
    }

    public submitResetValidationRequest(): void {
        this.submitted = true;
        this._resetRequestErrors();

        if (this.resetValidationForm.invalid) {
            return;
        }
        const {username, resetCode, password} = this.resetValidationForm.value;
        this._subscriptions.add(this._authService.resetPasswordValidationRequest(username, resetCode, password).subscribe(() => {
            this.displayValidationRequestSent = true;
            setTimeout(() => {
                this._authService.resetUserInfos();
                this._router.navigate(['/view/login']);
            }, this.redirectTimeout);
        }, error => {
            if (error.status === StatusCodes.FORBIDDEN) {
                this.requestErrors = { credentials: true };
            } else if (error.status === StatusCodes.LOCKED) {
                this.requestErrors = { processAbortion: true };
            } else if (error.status === StatusCodes.CONFLICT) {
                this.requestErrors = { newPasswordUnacceptable: true };
            } else if (error.status === StatusCodes.REQUEST_TIMEOUT) {
                this.requestErrors = { passwordResetTokenExpired: true };
            }
        }));
    }

    public toggleHasPasswordCodeFlag(): void {
        this.hasPasswordCode = !this.hasPasswordCode;
        this.login = '';
        this.loginRequiredError = false;
        this.submitted = false;
        this.displayRequestSent = false;
        this.displayValidationRequestSent = false;
        this.resetValidationForm.reset();
    }

    public togglePasswordVisibility(id: number): void {
        this.isPasswordVisible[id] = !this.isPasswordVisible[id];
    }

    private _resetRequestErrors(): void {
        this.requestErrors = null;
    }
}
