/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SelectableElement } from '@components/grid/grid.component';
import { GenericGridClickService } from '@services/generic-grid/generic-grid-click.service';
@Component({
    selector: 'app-report-header',
    templateUrl: './report-header.component.html',
    styleUrls: ['./report-header.component.scss']
})
export class ReportHeaderComponent {
    @Input() name: string;
    @Input() switchTypeName: string;
    @Input() dataLength: number;
    @Input() isPaginated: boolean;
    @Input() pageSizes: Array<SelectableElement>;
    @Input() selectedSize: SelectableElement;
    @Input() enableExcelExport = true;
    @Input() enableTypeSwitch = false;
    @Input() enablePrint = true;
    @Input() remittance = false;

    @Output() changeItemPerPage: EventEmitter<SelectableElement> = new EventEmitter<SelectableElement>();
    @Output() switchType: EventEmitter<void> = new EventEmitter<void>();
    @Output() exportExcel: EventEmitter<void> = new EventEmitter<void>();
    @Output() print: EventEmitter<void> = new EventEmitter<void>();

    public nameParams: Object = { nu_rem: this.clickService.selectedRemittance?.NU_REM };

    constructor(public clickService: GenericGridClickService) {}
}
