/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, Input, SimpleChanges, OnChanges, AfterContentInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { FilterDim, Filter, FilterGroup, FilterDimValue } from '@components/filters/filter-typings';
import { FilterDimValueService } from '@services/filter-dim-value/filter-dim-value.service';
import { FilterConfigService } from '@app/services/filter-config/filter-config.service';
import { AuthService } from '@app/services/auth/auth.service';

@Component({
    selector: 'app-filter-dimension-select',
    templateUrl: './filter-dimension-select.component.html',
    styleUrls: ['./filter-dimension-select.component.scss']
})
export class FilterDimensionSelectComponent implements OnChanges, AfterContentInit, OnDestroy {
    @Input() dim: FilterDim;
    @Input() filter: Filter;
    @Input() group: FilterGroup;
    @Input() disabled: boolean;
    @Input() multiselect = false;

    public valueField = 'value';
    public labelField = 'label';
    public display = true;

    private readonly _subscriptions: Subscription = new Subscription();

    constructor(private readonly _filterDimValueService: FilterDimValueService,
                private readonly _filterConfigService: FilterConfigService,
                private readonly _authService: AuthService) {}

    public ngAfterContentInit(): void {
        this._init();

        this._subscriptions.add(this._filterDimValueService.filterDimValueUpdated.subscribe(this._toggleContract.bind(this)));
        this._subscriptions.add(this._authService.authenticated.subscribe(this._toggleContract.bind(this)));
    }

    public ngOnChanges(change: SimpleChanges): void {
        if (change.disabled?.firstChange) {
            return;
        }

        if (typeof change.disabled?.currentValue !== 'undefined') {
            this._filterDimValueService.resetValue(this.dim);
            this._filterHierarchyValues(this.dim, this.dim.value ? this.dim.value as FilterDimValue : null);
            this._filterDimValueService.filterDimValueUpdate();
            this._toggleContract();
        }
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public onSelect(newValue: string): void {
        this.dim.value = this.dim.values.find(dimValue => dimValue.value === newValue);
        this._filterDimValueService.upsertSelectedFilterDimValue(this.dim);
        if (typeof this.dim.hierarchyLevel !== 'undefined') {
            this._filterHierarchyValues(this.dim, this.dim.value);
        }
        this._filterDimValueService.filterDimValueUpdate();
    }

    public compareFn(o1: any, o2: any): boolean {
        const o1Value = o1?.value ? o1.value : o1;
        const o2Value = o2?.value ? o2.value : o2;
        return o1Value === o2Value;
    }

    private _init(): void {
        this._filterDimValueService.resetValue(this.dim);
        this._filterDimValueService.searchSelectedValue(this.dim);
        this._filterHierarchyValues(this.dim, this.dim.value ? this.dim.value as FilterDimValue : null);
    }

    private _filterHierarchyValues(currentDim: FilterDim, newValue: FilterDimValue): void { 

        /*
        * reset value when new filter change 
        */
        for (const dimension of this.filter.dimensions) {
            if (typeof dimension.hierarchyParentDimName !== 'undefined'
                && dimension.hierarchyParentDimName.indexOf(currentDim.name) !== -1) {
                    if (typeof dimension['_value'] !== 'undefined' && dimension['_value'] !== null && this._filterDimValueService.mainHierarchicLevels.includes(currentDim.hierarchyLevel)) {
                        this._filterDimValueService.resetValue(dimension);
                    }
                }
        }

        /*
        * apply new filter with hierarchy condition
        */
        const valuesHashmap = this._filterConfigService.buildValuesHashmap();
        
        if (!valuesHashmap) {
            return;
        }
        for (const dimension of this.filter.dimensions) {
            if (typeof dimension.hierarchyParentDimName !== 'undefined'
                && dimension.hierarchyParentDimName.indexOf(currentDim.name) !== -1) {
                    /**
                     * When resetting the filteredValues via unselecting the selected hierarchical value
                     * or by selecting the 'all' value, always remove the duplicates
                    */
                    let filterBy: string[] = Object.keys(valuesHashmap)
                        .filter(value => dimension.hierarchyParentDimName.includes(value))
                        .reverse()
                        .map(value =>  valuesHashmap[value]);
                    
                    /**
                     * flat array in one dimension
                     */
                    filterBy = this._flatDeep(filterBy);

                    dimension['_filteredValues'] = this._filterDimValueService.removeFilterDimValuesByRelated(dimension, filterBy, newValue);
            }
        }
    }

    private _flatDeep(arr, d = 1) {
        return d > 0 ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? this._flatDeep(val, d - 1) : val), []) : arr.slice();
     };

    /**
     * For super user, display contract and rib dropdown only when company or retailer code are selected
     */
    private _toggleContract(): void {
        const filterValues: Object = this._filterConfigService.buildValuesHashmap();
        if (this._authService.isSuperUser && (this.dim.name === 'NU_CONTRAT' || this.dim.name === 'CMT_RIB')) {
            if (filterValues['CD_ENTITE_JURIDIQUE'] || filterValues['CD_CODE_CHAINE']) {
                this.display = true;
            } else {
                this.display = false;
            }
        }
        
        /*
        * specific rules for display, not display or gray merchant filter 
        */
        if (this._authService.isSuperUser && (this.dim.name === 'SOUS_MARCHAND')) {
            this.display = false;
            let parentValue = null;
            if (typeof filterValues['CD_CODE_CHAINE'] !== 'undefined') {
                parentValue = filterValues['CD_CODE_CHAINE']
            }else if (typeof filterValues['CD_ENTITE_JURIDIQUE'] !== 'undefined' && filterValues['CD_ENTITE_JURIDIQUE'].length > 0) {
                parentValue = filterValues['CD_ENTITE_JURIDIQUE'][0]
            }

            if (typeof this.dim.values.find(value => value.parentValue === parentValue) === 'undefined') {
                this.display = false;
                this.dim.actif = false;
            } else {
                this.display = true;
                const status: boolean = (typeof filterValues['NU_CONTRAT'] !== 'undefined' && typeof this.dim.values.find(value => value.parentValue == filterValues['NU_CONTRAT']) === 'undefined');
                this.disabled = status;
                this.dim.actif = !status;
            }
        }       
    }
}
