/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, Input } from '@angular/core';

import { ConfigService } from '@services/config/config.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    @Input() multiLines = true;

    public legalsLink: string = this._configService.personalDataLink;
    public appVersion: string = this._configService.version;
    public showContact = false;

    private cookiesPoliticFileUrl = './assets/docs/Politique_cookies_Light.pdf';

    constructor(private readonly _configService: ConfigService) {}

    public openCookiesPoliticFile(): void {
      window.open(this.cookiesPoliticFileUrl, '_blank', '');
    }
}
