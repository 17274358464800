/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { FilterDim, FlatFilter } from './';

export class Filter {
    filterId: number;
    filterName: string;
    filterLabel: string;
    filterSublabel?: string;
    dimensions: FilterDim[];
    disableConstraint?: number[];
    restrictDisplayGrid?: number;
    disable?: boolean;

    constructor(_: FlatFilter) {
        this.filterId = _.filter_id;
        this.filterName = _.filter_name;
        this.filterLabel = _.filter_label;
        this.dimensions = [];
        this.filterSublabel = _.filter_sublabel;
        this.disableConstraint = _.filter_disable_constraint;
        this.restrictDisplayGrid = _.filter_restrict_display_grid;
        this.disable = _.filter_disable;
        return this;
    }
}
