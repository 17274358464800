/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { QueryAuthToken } from '../query/query.service';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

enum URLItemType {
	STRING = 'string',
	NUMBER = 'number'
}

interface URLItem {
	key: string;
	type: URLItemType;
}

type UntypedQueryAuthToken = Omit<QueryAuthToken, 'tokenType'>;

export const SESSION_TOKEN_KEY = 'token';
export const COOKIE_URL_KEY = 'url';

const SESSION_AXEPTA_URL_PARAM = 'isAxeptaUser';
const QUERY_AUTH_TOKEN_ATTRIBUTES_COUNT = 4;
const MBE_COOKIE_AUTHORIZATION = 'MBE-TOKEN';
const MBE_REFRESH_TOKEN_COOKIE_AUTHORIZATION = 'MBE-REFRESH-TOKEN';
const MBE_EXPIRATION_TIME_COOKIE_AUTHORIZATION = 'MBE-EXPIRATION-TIME';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
	private _requestParams: string[];
	private _url: string;

	constructor(
		@Inject(DOCUMENT) private readonly _document: Document,
		private readonly _cookieService:CookieService
	) {
		this._url = this._document.URL;
	}

	set url(url: string) {
		this._url = url;
	}

	/**
	 * @description Find out in url specifics paramaters in order to set the session context (default | oauth2 authentication | axepta redirection)
	 * @returns void
	 */
	public init(): void {
		const splittedUrl = this._url.split('?');
		const requestParams: string = splittedUrl.length && splittedUrl[1];

		if (!requestParams) {
			return; // Default context
		}

		this._requestParams = requestParams.split(',');
		this._setSessionContext();
	}

	public setItem<T>(sessionStorageKey: string, value: T): void {
		window.sessionStorage[sessionStorageKey] = value;
	}

	public getItem<T>(sessionStorageKey: string): T | undefined {
		return window.sessionStorage[sessionStorageKey];
	}

	/**
	 * @description Try to extract isAxeptaUser from url in order to know if it is an axepta redirection context, if so, trigger OAuth2 authentication on app component
	 */
	private _setSessionContext(): void {
		const isAxeptaUser = this._requestParams.find(param => param.includes(SESSION_AXEPTA_URL_PARAM));
		const isPostOauth2LoginContext = this._cookieService.get(MBE_COOKIE_AUTHORIZATION);

		isAxeptaUser && this._cookieService.set(
			COOKIE_URL_KEY,
			this._url
				.split('#')
				.pop()				// Remove domain
				.split('?')
				.shift()			// Remove request params
		);
		isPostOauth2LoginContext && this._storeJwtToken();
	}

	private _storeJwtToken(): void  {
		this.setItem<string>(SESSION_TOKEN_KEY, JSON.stringify({
			jwt: this._cookieService.get(MBE_COOKIE_AUTHORIZATION),
			refreshToken: this._cookieService.get(MBE_REFRESH_TOKEN_COOKIE_AUTHORIZATION),
			expirationTime: this._cookieService.get(MBE_EXPIRATION_TIME_COOKIE_AUTHORIZATION),
			tokenType: 'bearer'
		}));
	}
}
