/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, Input, AfterViewInit, SimpleChanges, OnChanges, ElementRef } from '@angular/core';
import { Chart, ChartYAxe, ChartDataSets, ChartTooltipModel, ChartTooltipModelBody, InteractionMode, ChartTooltipItem } from 'chart.js';
import { v4 as uuid } from 'uuid';
import { CustomChart, CustomChartConfiguration } from '@components/charts/chart-typings/custom-chart.interface';
import { Legend } from '@components/charts/chart-typings/legend.interface';
import { NumberFormatterService } from '@services/formatter/number-formatter.service';

@Component({
    selector: 'app-chart-mixed',
    templateUrl: './chart-mixed.component.html',
    styleUrls: ['./chart-mixed.component.scss']
})
export class ChartMixedComponent implements AfterViewInit, OnChanges {
    @Input() title: string;
    @Input() labels: string[] = [];
    @Input() datasetsBarsVar: ChartDataSets[] = [];
    @Input() datasetsLinesVar: ChartDataSets[] = [];
    @Input() stepSizeBars = 0;
    @Input() stepSizeLines = 0;
    @Input() showLegend = true;
    @Input() canvasId: string = uuid();
    @Input() yAxis: ChartYAxe[];
    @Input() maintainAspectRatio = true;
    @Input() stacked = false;
    @Input() xAxisLabel = '';
    @Input() mode: InteractionMode = 'point';
    @Input() intersect = true;

    public customLegend: Legend[] = [];

    private _chart: CustomChart;
    private readonly _tooltipId: string = uuid();

    private readonly _barsColors: string[] = ['#4CB795', '#117445', '#24BF4D', '#69D535', '#7E50A8',
        '#D263A6', '#008F9E', '#20C5F2', '#1D95FF', '#0266BF',
        '#EF881D', '#EE5C42', '#D3C800', '#809AFE', '#6A59FF'];
    private readonly _linesColors: string[] = ['#00493C'];
    private readonly _barsTicksColor = '#1D95FF';
    private readonly _linesTicksColor = '#00493C';
    private readonly _defaultTooltipFormat = '0,0';
    private readonly _labelsfontSize = 12;

    constructor(private readonly _elementRef: ElementRef,
        private readonly _numberFormatterService: NumberFormatterService) { }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        const datasetsBarsFirstChange = simpleChanges.datasetsBars && !simpleChanges.datasetsBars.isFirstChange()
        const datasetsLinesFirstChange = simpleChanges.datasetsLines && !simpleChanges.datasetsLines.isFirstChange()
        const labelsFirstChange = simpleChanges.datasetsBars && !simpleChanges.datasetsBars.isFirstChange()
        if (datasetsBarsFirstChange || datasetsLinesFirstChange || labelsFirstChange) {
            this._initChart();
        }
    }

    ngAfterViewInit(): void {
        this._initChart();
    }

    public hideDataset(legendInfo: { legend: Legend, i: number }): void {
        legendInfo.legend.hidden = !legendInfo.legend.hidden;
        this._chart.config.data.datasets[legendInfo.i]['hidden'] = !this._chart.config.data.datasets[legendInfo.i]['hidden'];
        this._chart.update();
    }

    public updateChart(): void {
        if (this._chart) {
            this._chart.update();
        }
    }

    private _initChart(): void {
        if (this.labels.length > 0 && (this.datasetsBars.length > 0 || this.datasetsLines.length > 0)) {
            if (this._chart) {
                this._chart.destroy();
            }
            this._initConfig();
        }
    }

    /* tslint:disable-next-line:no-any */
    private _getTooltipLabel(tooltipItem: ChartTooltipItem, chart: any): string {
        let format: string;
        if (chart.datasets[tooltipItem.datasetIndex].tooltipFormat) {
            format = chart.datasets[tooltipItem.datasetIndex].tooltipFormat;
        } else {
            format = this._defaultTooltipFormat;
        }
        return this._numberFormatterService
            .value(tooltipItem.yLabel as number)
            .format(format)
            .apply();
    }

    private _initConfig(): void {
        if (document.getElementById(this.canvasId)) {
            const config: CustomChartConfiguration = {
                type: 'bar',
                data: {
                    labels: this.labels,
                    datasets: this._buildDatasets()
                },
                options: {
                    tooltips: {
                        enabled: false,
                        mode: this.mode,
                        intersect: this.intersect,
                        position: 'nearest',
                        callbacks: {
                            label: this._getTooltipLabel.bind(this),
                            labelTextColor: (tooltipItem, chart) => {
                                return chart.config.data.datasets[tooltipItem.datasetIndex].backgroundColor as string;
                            }
                        },
                        custom: this._customTooltip.bind(this)
                    },
                    responsive: true,
                    maintainAspectRatio: this.maintainAspectRatio,
                    datasets: {
                        bar: {
                            categoryPercentage: 0.5
                        },
                        line: {
                            fill: false,
                            borderWidth: 1,
                            lineTension: 0
                        }
                    },
                    legend: {
                        display: false
                    },
                    legendCallback: (chart: CustomChart): string => {
                        // We spliced instead of reassigning to not mess up we angular life cycle
                        this.customLegend.splice(0, this.customLegend.length);
                        chart.config.data.datasets.forEach((dataset: ChartDataSets) => {
                            this.customLegend.push({
                                type: dataset.type,
                                hidden: dataset.hidden,
                                color: dataset.borderColor || dataset.pointBackgroundColor || dataset.backgroundColor,
                                text: dataset.label
                            });
                        });
                        return '';
                    },
                    scales: {
                        xAxes: [{
                            stacked: this.stacked,
                            gridLines: {
                                display: false,
                                drawBorder: true
                            },
                            ticks: {
                                autoSkip: false,
                                fontSize: this._labelsfontSize,
                                fontColor: this._linesTicksColor
                            },
                            scaleLabel: {
                                labelString: this.xAxisLabel,
                                display: true
                            }
                        }],
                        yAxes: this._getYAxisConfig()
                    }
                }
            };
            this._chart = new Chart(this.canvasId, config);
        }
        this._chart.generateLegend();
    }

    private _customTooltip(tooltipModel: ChartTooltipModel): void {
        let tooltipEl: HTMLElement = document.getElementById(this._tooltipId);
        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = this._tooltipId;
            tooltipEl.classList.add('chartjs-tooltip');
            this._elementRef.nativeElement.appendChild(tooltipEl);
        } else {
            tooltipEl.innerHTML = '';
        }

        if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
        }

        if (tooltipModel.body) {
            const bodyLines: string[][] = tooltipModel.body.map((bodyItem: ChartTooltipModelBody) => bodyItem.lines);

            bodyLines.forEach((body: string[], i: number) => {
                const div: HTMLElement = document.createElement('div');
                div.style.color = tooltipModel.labelColors[i]['backgroundColor'];
                div.innerHTML = body.toString();
                tooltipEl.appendChild(div);
            });
        }

        const positionX: number = this._chart.canvas.offsetLeft;
        const positionY: number = this._chart.canvas.offsetTop;

        tooltipEl.style.opacity = '1';
        tooltipEl.style.left = `${positionX}${tooltipModel.caretX}px`;
        tooltipEl.style.top = `${positionY}${tooltipModel.caretY}px`;
        tooltipEl.style.padding = `${tooltipModel.yPadding}px ${tooltipModel.xPadding}px`;
    }

    private _buildDatasets(): ChartDataSets[] {
        this.datasetsBars?.forEach((dataset: ChartDataSets, i: number) => {
            if (!this.yAxis) {
                dataset['yAxisID'] = 'barsAxe';
            }
            dataset['type'] = 'bar';
            if (!dataset['backgroundColor']) {
                dataset['backgroundColor'] = this._barsColors[i % this._barsColors.length];
            }
        });
        this.datasetsLines?.forEach((dataset: ChartDataSets, i: number) => {
            if (!this.yAxis) {
                dataset['yAxisID'] = 'linesAxe';
            }
            dataset['type'] = 'line';
            if (!dataset['borderColor']) {
                dataset['borderColor'] = this._linesColors[i % this._linesColors.length];
            }
            if (!dataset['pointBackgroundColor']) {
                dataset['pointBackgroundColor'] = this._linesColors[i % this._linesColors.length];
            }
        });
        return [...this.datasetsLines, ...this.datasetsBars];
    }

    private _getYAxisConfig(): ChartYAxe[] {
        if (this.yAxis) {
            return this.yAxis;
        }
        const yAxisConfig: ChartYAxe[] = [];

        if (this.datasetsBars.length) {
            yAxisConfig.push({
                id: 'barsAxe',
                type: 'linear',
                position: 'left',
                stacked: this.stacked,
                gridLines: {
                    drawTicks: false
                },
                ticks: {
                    stepSize: this.stepSizeBars,
                    beginAtZero: true,
                    fontSize: this._labelsfontSize,
                    fontColor: this._barsTicksColor,
                    padding: 12
                }
            });
        }
        if (this.datasetsLines.length) {
            yAxisConfig.push({
                id: 'linesAxe',
                type: 'linear',
                position: this.datasetsBars.length ? 'right' : 'left',
                gridLines: {
                    drawBorder: true,
                    drawOnChartArea: !this.datasetsBars.length,
                    drawTicks: false
                },
                ticks: {
                    stepSize: this.stepSizeLines,
                    beginAtZero: true,
                    fontSize: this._labelsfontSize,
                    fontColor: this._linesTicksColor,
                    padding: 12
                }
            });
        }
        return yAxisConfig;
    }

    @Input()
    set datasetsBars(datasetsBars: ChartDataSets[]) {
        this.datasetsBarsVar = datasetsBars;
        if (this._chart) {
            this._chart.config.data.datasets = this._buildDatasets();
            this._chart.update();
            this._chart.generateLegend();
        }
    }
    get datasetsBars(): ChartDataSets[] {
        return this.datasetsBarsVar;
    }

    @Input()
    set datasetsLines(datasetsLines: ChartDataSets[]) {
        this.datasetsLinesVar = datasetsLines;
        if (this._chart) {
            this._chart.config.data.datasets = this._buildDatasets();
            this._chart.update();
            this._chart.generateLegend();
        }
    }
    get datasetsLines(): ChartDataSets[] {
        return this.datasetsLinesVar;
    }

}
