/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component } from '@angular/core';
import { ErrorService } from '@app/services/error';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
    constructor(public errorService: ErrorService) {}
}
