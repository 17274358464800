/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, Input, SimpleChanges, AfterContentInit, OnChanges } from '@angular/core';
import { TreeSelectItem } from '@app/components/tree-select/tree-select.component';
import { FilterDimValue, FilterDim, Filter, FilterGroup, DimValueTypes } from '@components/filters/filter-typings';
import { FilterDimValueService } from '@services/filter-dim-value/filter-dim-value.service';

@Component({
    selector: 'app-filter-dimension-hierarchical-select',
    templateUrl: './filter-dimension-hierarchical-select.component.html',
    styleUrls: ['./filter-dimension-hierarchical-select.component.scss']
})
export class FilterDimensionHierarchicalSelectComponent implements AfterContentInit, OnChanges {
    @Input() dim: FilterDim;
    @Input() filter: Filter;
    @Input() group: FilterGroup;
    @Input() disabled: boolean;

    public virtualHeightEnabled = false;
    public virtualHeightThreshold = 10;

    constructor(private readonly _filterDimValueService: FilterDimValueService) {}

    public ngAfterContentInit(): void {
        this._init();
    }

    public ngOnChanges(change: SimpleChanges): void {
        if (change['disabled']['currentValue']) {
            this._init();
        }
    }

    public onSelect(selection: TreeSelectItem): void {
        const selectedValue: FilterDimValue = this._findDimValue(selection, this.dim.values);
        this.dim._value = selectedValue;
        const children: FilterDimValue[] = [];
        if (selectedValue) {
            this._fillChildrenList(selectedValue, children);
        } else {
            this._filterDimValueService.resetValue(this.dim);
        }
        this.dim.value = children;
        if (typeof this.dim.hierarchyLevel !== 'undefined') {
            this._filterHierarchyValues(this.dim, selectedValue, children.map(child => child.value));
        }
        this._filterDimValueService.filterDimValueUpdate();
    }

    private _init(): void {
        this._filterDimValueService.resetValue(this.dim);
        this._filterDimValueService.searchSelectedValue(this.dim);
        if (this.dim.value && (this.dim.value as FilterDimValue[]).length > 0) {
            this._filterHierarchyValues(this.dim, this.dim._value, (this.dim.value as FilterDimValue[]).map(child => child.value));
        }
    }

    private _findDimValue(selection: TreeSelectItem, dimValues: FilterDimValue[]): FilterDimValue {
        const selectedValue: FilterDimValue = dimValues.find((dimVal: FilterDimValue) => {
            return selection && selection.value === dimVal.value;
        });
        if (selectedValue) {
            return selectedValue;
        }
        for (const dimVal of dimValues) {
            if (dimVal.children) {
                const selectedChild: FilterDimValue = this._findDimValue(selection, dimVal.children);
                if (selectedChild) {
                    return selectedChild;
                }
            }
        }
        return null;
    }

    private _fillChildrenList(dimVal: FilterDimValue, children: FilterDimValue[]): void {
        if (typeof dimVal.children === 'undefined') {
            children.push(dimVal);
        } else {
            dimVal.children.forEach(child => {
                this._fillChildrenList(child, children);
            });
        }
    }

    private _filterHierarchyValues(currentDim: FilterDim, newValue: FilterDimValue, values: DimValueTypes[]): void {
        for (const dimension of this.filter.dimensions) {
            if (typeof dimension.hierarchyParentDimName !== 'undefined'
                && dimension.hierarchyParentDimName.indexOf(currentDim.name) !== -1) {
                /**
                 * When resetting the filteredValues via unselecting the selected hierarchical value
                 * or by selecting the 'all' value, always remove the duplicates
                 */
                if (newValue?.value === 'all' || newValue === null || typeof newValue === 'undefined') {
                    dimension['_filteredValues'] = this._filterDimValueService.removeFilterDimValuesDuplicates(dimension);
                } else {
                    dimension['_filteredValues'] = dimension.values.filter(value => {
                        return values.indexOf(value.parentValue) !== -1 || value.value === 'all';
                    });
                }
                if (typeof dimension['_value'] !== 'undefined'
                    && dimension['_value'] !== null
                    && typeof dimension['_filteredValues'].find(val => val.value === dimension['_value'].value) === 'undefined') {
                    this._filterDimValueService.resetValue(dimension);
                }
            }

        }
    }
}
