/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, OnDestroy, Input, OnChanges } from '@angular/core';
import { forkJoin, Observable, Subscription } from 'rxjs';

import { QueryService } from '@services/query/query.service';
import { AvailableData } from '@views/home/home.component';

interface Litigation {
    class: string;
    label: string;
    value: number;
}

interface QDemand {
    NB_DDJ: number;
    MT_BRUT_DDJ: number;
}

interface QUnpaid {
    NB_IMP: number;
    MT_BRUT_IMP: number;
}

interface QTransactions {
    NB_FRAUDE: number;
    MT_BRUT_FRAUDE: number;
}

@Component({
    selector: 'app-litigation',
    templateUrl: './litigation.component.html',
    styleUrls: ['./litigation.component.scss']
})
export class LitigationComponent implements OnChanges, OnDestroy {
    @Input() filterValues: Object = {};
    @Input() availableData: AvailableData[] = [];

    public litigations: Litigation[] = [
        {
            'class': 'i-demand',
            'label': '_HOME_._GST_LITIGES_DMD_JUSTIFICATIF_',
            'value': null
        },
        {
            'class': 'i-unpaid',
            'label': '_HOME_._GST_LITIGES_IMPAYES_',
            'value': null
        },
        {
            'class': 'i-transactions',
            'label': '_HOME_._GST_LITIGES_TRANS_FRAUDULEUSES_',
            'value': null
        }
    ];

    private readonly _subscriptions: Subscription = new Subscription();

    constructor(private readonly _queryService: QueryService) {}

    ngOnChanges(): void {
        const fraudIndex = 2;
        this._subscriptions.add(forkJoin([
            this._queryService.getSqlResult('home/Q_DDJ.tpl', this.filterValues) as Observable<QDemand[]>,
            this._queryService.getSqlResult('home/Q_IMPAYE.tpl', this.filterValues) as Observable<QUnpaid[]>,
            this._queryService.getSqlResult('home/Q_FRAUDE.tpl', this.filterValues) as Observable<QTransactions[]>
        ])
        .subscribe(([demands, unpaids, transactions]) => {
            this.litigations[0].value = (demands[0] && typeof demands[0].NB_DDJ !== 'undefined') ? demands[0].NB_DDJ : null;
            this.litigations[1].value = (unpaids[0] && typeof unpaids[0].NB_IMP !== 'undefined') ? unpaids[0].NB_IMP : null;
            this.litigations[fraudIndex].value = (transactions[0] && typeof transactions[0].NB_FRAUDE !== 'undefined')
                ? transactions[0].NB_FRAUDE
                : null;
        }));
    }

    ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }
}
