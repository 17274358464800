/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Injectable, isDevMode } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, first, tap } from 'rxjs/operators';

import { LanguageService } from '@services/language/language.service';
import { QueryService } from '@services/query/query.service';
import { ConfigService } from '@services/config/config.service';

import {
    FilterGroup,
    Filter,
    FilterDim,
    FilterDimValue,
    FlatFilter,
    FilterDimType,
    FilterValueI18n
} from '@components/filters/filter-typings';

type FilterElem = FilterGroup | Filter | FilterDim | FilterDimValue;

interface FilterConfigTemplateParam { id_grid: number; }

interface GenerateFilterElemConfig {
    hashmap: Object;
    idField: string;
    idParentField?: string;
    instance: FilterElem;
    children: FilterElem[];
}

@Injectable()
export class FilterConfigBuilderService {
    private _config: FilterGroup[];
    private _groupHashmap: Object = {};
    private _filterHashmap: Object = {};
    private _dimHashmap: Object = {};
    private _currentReport: number;
    private _fetchFilterConfigTemplate: string;

    constructor(
        private readonly _language: LanguageService,
        private readonly _queryService: QueryService,
        private readonly _configService: ConfigService) {
        this._fetchFilterConfigTemplate = this._configService.templateGetFilter;
    }

    public init(idReport: number, forceReload = false): Observable<FilterGroup[]> {
        if (this._config && this._currentReport === idReport && !forceReload) {
            return of(this._config);
        }
        this._resetService(idReport);
        const queryParams: FilterConfigTemplateParam = {id_grid: this._currentReport};
        return this._queryService.getSqlResult(this._fetchFilterConfigTemplate, queryParams).pipe(
            first(),
            tap(d => d.forEach(row => this._generate(new FlatFilter(row)))),
            switchMap(d => of(this._config))
        );
    }

    private _resetService(idReport: number): void {
        this._config = [];
        this._currentReport = idReport;
        this._groupHashmap = {};
        this._filterHashmap = {};
        this._dimHashmap = {};
    }

    private _generate(dataRow: FlatFilter): void {
        const conf: { [key: string]: GenerateFilterElemConfig } = {
            group: { hashmap: this._groupHashmap, idField: 'group_id', instance: new FilterGroup(dataRow), children: this._config },
            filter: { hashmap: this._filterHashmap, idField: 'filter_id', instance: new Filter(dataRow), children: [] },
            dim: { hashmap: this._dimHashmap, idField: 'dim_id', instance: new FilterDim(dataRow), children: [] },
            val: { hashmap: {}, idField: 'cd_ref_cd', idParentField: 'cd_ref_cd_prnt', instance: null, children: [] }
        };
        const group: FilterGroup = this._generateElem(dataRow, conf.group) as FilterGroup;
        conf.filter.children = group.filters;
        const filter: Filter = this._generateElem(dataRow, conf.filter) as Filter;
        conf.dim.children = filter.dimensions;
        const dim: FilterDim = this._generateElem(dataRow, conf.dim) as FilterDim;
        const i18nValues: FilterValueI18n[] = this._language.getFilterValues(dim.name);
        i18nValues.forEach(this._generateValue.bind(this, dim, conf.val));
        if (dim.type === FilterDimType.HIERARCHICALSELECT) {
            i18nValues.forEach(this._buildValueHierarchy.bind(this, conf.val));
        }
    }

    private _generateElem(dataRow: FlatFilter|FilterValueI18n, conf: GenerateFilterElemConfig): FilterElem {
        const id: number = dataRow[conf.idField];

        if (!conf.hashmap[id]) {
            conf.hashmap[id] = conf.instance;
            conf.children.push(conf.hashmap[id]);
        } else if (conf.idParentField) {
            const parentId: string = dataRow[conf.idParentField];
            if (parentId !== conf.hashmap[id]['parentValue']) {
                conf.hashmap[`${parentId}|${id}`] = conf.instance;
                conf.children.push(conf.hashmap[`${parentId}|${id}`]);
            }
        }
        return conf.hashmap[id];
    }

    private _generateValue(dim: FilterDim, conf: GenerateFilterElemConfig, i18nVal: FilterValueI18n): void {
        conf.children = dim.values;
        if (dim.type === FilterDimType.HIERARCHICALSELECT && i18nVal.cd_ref_cd_prnt) {
            conf.children = [];
        }
        conf.instance = new FilterDimValue(i18nVal);
        conf.instance.type = dim.valuetype;
        this._generateElem(i18nVal, conf);
    }

    private _buildValueHierarchy(conf: GenerateFilterElemConfig, i18nVal: FilterValueI18n): void {
        if (i18nVal.cd_ref_cd_prnt) {
            let childVal: FilterDimValue = conf.hashmap[i18nVal.cd_ref_cd];
            let parentVal: FilterDimValue = conf.hashmap[i18nVal.cd_ref_cd_prnt];

            if (typeof conf.hashmap[`${i18nVal.cd_ref_cd_prnt}|${i18nVal.cd_ref_cd}`] !== 'undefined') {
                childVal = conf.hashmap[`${i18nVal.cd_ref_cd_prnt}|${i18nVal.cd_ref_cd}`];
            }
            if (typeof parentVal === 'undefined' && i18nVal.cd_ref_cd_prnt.indexOf('|') !== -1) {
                parentVal = conf.hashmap[i18nVal.cd_ref_cd_prnt.split('|')[1]];
            }

            if (typeof parentVal !== 'undefined') {
                parentVal.addChild(childVal);
            } else if (isDevMode()) {
                console.error(`${i18nVal.cd_ref_cd_prnt} is missing in REF_TYPOLOGIE for ${i18nVal.cd_dim_ref}.`);
            }
        }
    }
}
