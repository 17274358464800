/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Injectable } from '@angular/core';
import obseleteBrowser from '../../../assets/json/obselete-browser.json';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
    private _alertBrowserAlreadyDisplay = false;
    private _alertStructureAlreadyDisplay = false;
    private _alertPasswordExpirationAlreadyDisplay = false;

    get alertBrowserAlreadyDisplay(): boolean { return this._alertBrowserAlreadyDisplay; }
    set alertBrowserAlreadyDisplay(value: boolean) { this._alertBrowserAlreadyDisplay = value; }

    get alertStructureAlreadyDisplay(): boolean { return this._alertStructureAlreadyDisplay; }
    set alertStructureAlreadyDisplay(value: boolean) { this._alertStructureAlreadyDisplay = value; }

    get alertPasswordExpirationAlreadyDisplay(): boolean { return this._alertPasswordExpirationAlreadyDisplay; }
    set alertPasswordExpirationAlreadyDisplay(value: boolean) { this._alertPasswordExpirationAlreadyDisplay = value; }

    public getBrowser(userAgent: string): string {
        const agent: string = userAgent.toLowerCase();
        switch (true) {
          case agent.indexOf('edg') > -1:
          case agent.indexOf('edge') > -1:
            return 'Edge';
          case agent.indexOf('opera') > -1:
          case agent.indexOf('opr') > -1:
            return 'Opera';
          case agent.indexOf('chrome') > -1:
            return 'Chrome';
          case agent.indexOf('trident') > -1:
            return 'Internet Explorer';
          case agent.indexOf('firefox') > -1:
            return 'Firefox';
          case agent.indexOf('safari') > -1:
            return 'Safari';
          default:
            return '';
        }
    }

    public getBrowserVersion(userAgent: string): number {
        const matchIndex = 2;
        let matchTest: RegExpMatchArray = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i);
        let tem: RegExpMatchArray;
        if (/trident/i.test(matchTest[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(userAgent);
            return Number((tem[1] || ''));
        }
        if (matchTest[1] === 'Chrome') {
            tem = userAgent.match(/\b(OPR|Edge|Edg)\/(\d+)/);
            if (tem !== null) {
                return Number(tem.slice(1)[1]);
            }
        }
        matchTest = matchTest[matchIndex] ? [matchTest[1], matchTest[matchIndex]] : [navigator.appName, navigator.appVersion, '-?'];
        tem = userAgent.match(/version\/(\d+)/i);
        if (tem !== null) {
            matchTest.splice(1, 1, tem[1]);
        }
        return Number(matchTest[1]);
    }

    public isObsoleteBrowser(userAgent: string): boolean {
        if (obseleteBrowser[this.getBrowser(userAgent)] <=  this.getBrowserVersion(userAgent)) {
            return false;
        } else {
            return true;
        }
    }

    public lastSupportedBrowserVersion(userAgent: string): number {
        return obseleteBrowser[this.getBrowser(userAgent)];
    }
}
