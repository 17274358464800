/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, EventEmitter, Output } from '@angular/core';

import { ConfigService } from '@services/config/config.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
    @Output() close: EventEmitter<void> = new EventEmitter();

    public email: string = this._configService.email;
    public phoneNumber: string = this._configService.phoneNumber;
    public faxNumber: string = this._configService.faxNumber;

    constructor(private readonly _configService: ConfigService) {}
}
