/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

export enum FilterDimType {
    SELECT,
    MULTISELECT,
    DATE,
    VALUE,
    RADIO,
    HIERARCHICALSELECT
}
