/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { DateFormatterService, DateFormatterOptions } from '@services/formatter/date-formatter.service';
import { NumberFormatterService, NumberFormatterOptions } from '@services/formatter/number-formatter.service';
import { StringFormatterService, StringFormatterOptions } from '@services/formatter/string-formatter.service';
import { Formatter, Column } from 'angular-slickgrid';

import { ConfigService, ColumnFormatter } from '@services/config/config.service';
import { QueryService } from '@services/query/query.service';

enum Type {
    NUMBER = 1,
    STRING = 2,
    DATE = 3
}

interface SlickgridCellFormat {
    idFormatter: number;
    type: Type;
    options?: FormatterOptions;
}

type FormatterOptions = NumberFormatterOptions | StringFormatterOptions | DateFormatterOptions;
type FormatterServices = DateFormatterService | NumberFormatterService | StringFormatterService;
type FormatterValueType = Date | number | string;

export interface CssFormatterOptions {
    tooltip?: string;
    italic?: boolean;
    strong?: boolean;
    color?: string;
    textTransform?: string;
    textDecoration?: string;
    hover?: Hover;
    textAlign?: string;
    cursor?: string;
    disableHtml?: boolean;
    marginLeft?: number;
}

interface Hover {
    onMouseOver: string;
    onMouseOut: string;
}

@Injectable({
    providedIn: 'root'
})
export class SlickgridFormatterService {
    private _formatter: SlickgridCellFormat[];

    constructor(private readonly _dateFormatterService: DateFormatterService,
                private readonly _numberFormatterService: NumberFormatterService,
                private readonly _stringFormatterService: StringFormatterService,
                private readonly _config: ConfigService,
                private readonly _queryService: QueryService) { }

    public getFormatters(idFormatter: number, csvExport = false): Formatter {
        const formatter: SlickgridCellFormat = this._formatter.find( col => col.idFormatter === idFormatter );

        if (formatter) {
            formatter.options.disableHtml = csvExport;
            switch (formatter.type) {
                case Type.NUMBER:
                    return this._number.bind(this, Object.assign({}, formatter.options));
                case Type.STRING:
                    return this._string.bind(this, Object.assign({}, formatter.options));
                case Type.DATE:
                    return this._date.bind(this, Object.assign({}, formatter.options));
                default:
                    return this._defaultFormatter.bind(this);
            }
        } else {
            return this._defaultFormatter.bind(this);
        }
    }

    public getColumnFormatter(colField: string): Formatter {
        const foundColumnFormatter: ColumnFormatter = this._config.columnFormatters
            .find(columnFormatter => columnFormatter.label === colField);
        const idFormatter: number = foundColumnFormatter ? foundColumnFormatter.idFormatter : -1;
        return this.getFormatters(idFormatter);
    }

    public initFormatter(): Observable<Object[]> {
        return this._queryService.getSqlResult(this._config.templateGetFormatter).pipe(
            tap(
                this._applyFormatterResult.bind(this)
            )
        );
    }

    private _applyFormatterResult(formatterData: Object[]): void {
        this._formatter = [];
        formatterData.forEach(item => {
            this._formatter.push({
                idFormatter: item['ID_FORMATTER'],
                type: item['ID_TYPE'],
                options: JSON.parse(item['JOBJ_FORMATTER_CFG'])
            });
        });
    }

    /* tslint:disable-next-line: no-any */
    private _defaultFormatter(row: number, cell: number, value: any, columnDef: Column, dataContext: any): string {
        return this._buildHTMLResult(value, dataContext);
    }

    /* tslint:disable-next-line: no-any max-line-length */
    private _number(formatOptions: NumberFormatterOptions, row: number, cell: number, value: any, columnDef: Column, dataContext: any): string {
        let res = '';
        if (value !== null && value !== undefined) {
            res = this._callMethodFormatterService(value, formatOptions, this._numberFormatterService);
        }
        return this._buildHTMLResult(res, dataContext, formatOptions);
    }

    /* tslint:disable-next-line: no-any */
    private _date(formatOptions: DateFormatterOptions, row: number, cell: number, value: any, columnDef: Column, dataContext: any): string {
        let res = '';
        if (value !== null && value !== undefined) {
            res = this._callMethodFormatterService(value, formatOptions, this._dateFormatterService);
        }
        return this._buildHTMLResult(res, dataContext, formatOptions);
    }

    /* tslint:disable-next-line: no-any max-line-length */
    private _string(formatOptions: StringFormatterOptions, row: number, cell: number, value: any, columnDef: Column, dataContext: any): string {
        let res = '';
        if (value !== null && value !== undefined) {
            res = this._callMethodFormatterService(value, formatOptions, this._stringFormatterService);
        }
        return this._buildHTMLResult(res, dataContext, formatOptions);
    }

    /* tslint:disable-next-line: no-any */
    private _buildHTMLResult(res: string, dataContext: any, formatOptions?: FormatterOptions): string {
        if (formatOptions?.disableHtml) {
            return res;
        }
        let applyCss = true;
        if (typeof formatOptions !== 'undefined'
            && 'matchValue' in formatOptions
            && res.indexOf((formatOptions as StringFormatterOptions).matchValue) === -1) {
            applyCss = false;
        }

        const cssProperties: string = applyCss ? this._addCssProperties(formatOptions, dataContext) : '';
        return `<div ${cssProperties}>${res}</div>`;
    }

    private _getArgs(rawArgs: Object | string): string[] {
        let args: string[] = [];
        if  (typeof rawArgs === 'object') {
            args = [];
            for (const propName of Object.keys(rawArgs)) {
                args.push(rawArgs[propName]);
            }
        } else {
            args = [rawArgs];
        }
        return args;
    }

    private _callMethodFormatterService(value: FormatterValueType, formatOptions: FormatterOptions, formatter: FormatterServices): string {
        formatter.value(value as never);

        if ('matchValue' in formatOptions && (value as string).indexOf((formatOptions as StringFormatterOptions).matchValue) === -1) {
            return formatter.apply();
        }

        for (const key of Object.keys(formatOptions)) {
            if (formatter[key] !== undefined) {
                const args: string[] = this._getArgs(formatOptions[key]);
                formatter[key].apply(formatter, args);
            }
        }
        return formatter.apply();
    }

    /* tslint:disable-next-line: no-any */
    private _addCssProperties(formatOptions: FormatterOptions, dataContext: any): string {
        const cssProperties: string[] = [];
        if (formatOptions?.color) {
            cssProperties.push(`color: ${formatOptions.color}; `);
        }
        if (formatOptions?.strong) {
            cssProperties.push('font-weight: bold; ');
        }
        if (formatOptions?.italic) {
            cssProperties.push('font-style: italic; ');
        }
        if (formatOptions?.textTransform) {
            cssProperties.push(`text-transform: ${formatOptions.textTransform}; `);
        }
        if (formatOptions?.textDecoration) {
            cssProperties.push(`text-decoration: ${formatOptions.textDecoration}; `);
        }
        if (formatOptions?.cursor) {
            cssProperties.push(`cursor: ${formatOptions.cursor}; `);
        }
        if (formatOptions?.textAlign) {
            cssProperties.push(`text-align: ${formatOptions.textAlign}; `);
        }
        if (formatOptions?.marginLeft) {
            cssProperties.push(`margin-left: ${formatOptions.marginLeft}px; `);
        }

        let cssStyle = '';
        cssProperties.forEach(prop => cssStyle += prop);
        cssStyle = cssStyle !== '' ? `style="${cssStyle}"` : '';

        let hover = '';
        if (formatOptions?.hover) {
            hover = `onMouseOver="this.style.color='${formatOptions.hover.onMouseOver}'" onMouseOut="this.style.color='${formatOptions.hover.onMouseOut}'"`;
        }

        let title = '';
        if (formatOptions?.tooltip) {
            title = `title="${dataContext[formatOptions.tooltip]}"`;
        }

        return title + cssStyle + hover;
    }
}
