/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component } from '@angular/core';
import { LanguageService } from '@services/language/language.service';
import { ConfigService } from '@services/config/config.service';
import { saveAs } from 'file-saver';
import { MenuService } from '@app/services/menu/menu.service';

export interface PdfFile {
  label: string;
  name: string;
  path: string;
  language: string;
}

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent {
  public maBanqueEntrepriseLink: string = this._configService.maBanqueEntrepriseLink;
  public pdfFiles: PdfFile[] = [
    {
      label: '_HELP_MENU_._USER_GUIDE_PT_1_',
      name: 'BNPP_WCR_UserGuide_VEN.pdf',
      path: './assets/pdf/BNPP_WCR_UserGuide_VEN.pdf',
      language: 'en'
    },
    {
      label: '_HELP_MENU_._USER_GUIDE_PT_1_',
      name: 'BNPP_WCR_GuideUtilisateurs_VFR.pdf',
      path: './assets/pdf/BNPP_WCR_GuideUtilisateurs_VFR.pdf',
      language: 'fr'
    },
    {
      label: '_HELP_MENU_._USER_GUIDE_PT_2_',
      name: 'BNPP_WCR_ReleaseNote_VEN.pdf',
      path: './assets/pdf/BNPP_WCR_ReleaseNote_VEN.pdf',
      language: 'en'
    },
    {
      label: '_HELP_MENU_._USER_GUIDE_PT_2_',
      name: 'BNPP_WCR_Evolutions_VFR.pdf',
      path: './assets/pdf/BNPP_WCR_Evolutions_VFR.pdf',
      language: 'fr'
    }
  ];

  constructor(private readonly _languageService: LanguageService,
    private readonly _configService: ConfigService,
    private readonly _menuService: MenuService) { }

  public getPdfLocaleLanguage(): PdfFile[] {
    const pdfFilesList: PdfFile[] = [];
    for (const pdfFile of this.pdfFiles) {
      if (pdfFile.language === this._languageService.currentLang) {
        pdfFilesList.push(pdfFile);
      }
    }
    return pdfFilesList;
  }

  public downloadPdf(pdfUrl: string, pdfName: string): void {
    saveAs(pdfUrl, pdfName);
  }

  public openDoc(pdfUrl: string): void {
    window.open(pdfUrl, '_blank', '');
  }

}
