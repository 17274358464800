// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-dimension-radio-container .input-container, .filter-dimension-radio-container .dimension-label {
  min-width: initial !important;
}
.filter-dimension-radio-container .input-container.input-container, .filter-dimension-radio-container .dimension-label.input-container {
  flex: initial !important;
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/filters/filter-group/filter/filter-dimension-radio/filter-dimension-radio.component.scss"],"names":[],"mappings":"AACI;EACI,6BAAA;AAAR;AAEQ;EACI,wBAAA;EACA,kBAAA;AAAZ","sourcesContent":[".filter-dimension-radio-container {\n    .input-container, .dimension-label {\n        min-width: initial !important;\n\n        &.input-container {\n            flex: initial !important;\n            margin-right: 10px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
