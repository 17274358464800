/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Injectable, EventEmitter } from '@angular/core';
import { Observable, Observer } from 'rxjs';

import { FilterConfigBuilderService } from '@services/filter-config-builder/filter-config-builder.service';
import { FilterDimValueService } from '@services/filter-dim-value/filter-dim-value.service';

import {
    FilterGroup,
    FilterDim
} from '@components/filters/filter-typings';

@Injectable()
export class FilterConfigService {

    private readonly _onApplyGrid: EventEmitter<Object> = new EventEmitter();
    private readonly _onApplyChart: EventEmitter<Object> = new EventEmitter();
    private _config: FilterGroup[];
    private _currentReport: number;

    constructor(
        private readonly _configBuilder: FilterConfigBuilderService,
        private _filterDimValueService: FilterDimValueService) {}

    get onApplyGrid(): EventEmitter<Object> { return this._onApplyGrid; }
    get onApplyChart(): EventEmitter<Object> { return this._onApplyChart; }
    get config(): FilterGroup[] { return this._config; }

    public init(idReport: number, forceReload = false): Observable<FilterGroup[]> {
        return new Observable((observer: Observer<FilterGroup[]>) => {
            if (typeof this._config !== 'undefined'
                && idReport === this._currentReport
                && !forceReload) {
                this._filterDimValueService.config = this._config;
                observer.next(this._config);
                return;
            }
            this._currentReport = idReport;
            this.fetchConfig(idReport, observer, forceReload);
        });
    }

    public fetchConfig(idReport: number, observer: Observer<FilterGroup[]>, forceReload = false): void {
        this._configBuilder.init(idReport, forceReload).subscribe(config => {
            this._config = config;
            this._filterDimValueService.config = this._config;
            observer.next(this._config);
        });
    }

    public triggerApply(type: string): void {
        if (type === 'grid') {
            this._onApplyGrid.emit(this.buildValuesHashmap());
        } else {
            this._onApplyChart.emit(this.buildValuesHashmap());
        }
    }

    public buildValuesHashmap(): Object {
        const filterValues: Object = {};
        this._config.forEach(group => {
            group.filters.forEach(filter => {
                if (typeof filter['_disabled'] !== 'undefined' && filter['_disabled']) {
                    return;
                }
                filter.dimensions.forEach(dim => {
                    this._addValueToHashmap(filterValues, dim);
                });
            });
        });
        return filterValues;
    }

    private _addValueToHashmap(filterValues: Object, dim: FilterDim): void {
        if (dim.hasValue) {
            filterValues[dim.name] = dim.getValue();
        }
    }
}
