/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { FilterDimType } from './filter-dim-type.enum';
import { FilterDimValue, DimValueTypes } from './filter-dim-value.class';
import { FlatFilter } from './flat-filter.interface';
import { FilterDimValueType } from './filter-dim-value-type.enum';
import { DateFormatPipe } from '@services/formatter/date-format-pipe.class';
import { DateTime } from 'luxon'; 

interface TypeConditionArray {
    type: FilterDimType,
    condition: (obj: FilterDim) => boolean
}

export class FilterDim {
    id: number;
    type: FilterDimType;
    label: string;
    name: string;
    isMandatory?: number;
    defaultValue?: DimValueTypes;
    radioLink?: number[];
    disableConstraint?: number[];
    radioConstraint?: number[];
    values?: FilterDimValue[];
    helpLabel?: string;
    hierarchyLevel?: number;
    hierarchyParentDimName?: string[];
    valuetype?: FilterDimValueType;
    hideEmpty?: number;
    isVisible: boolean;
    constraintRadioVisible: boolean;
    actif?: boolean;

    _value?: FilterDimValue;
    _values?: FilterDimValue[];
    _hierarchicalModel?: DimValueTypes;
    _selected?: boolean;
    _disabled?: boolean;

    private readonly _arrayValueDimType: FilterDimType[] = [
        FilterDimType.HIERARCHICALSELECT,
        FilterDimType.MULTISELECT
    ];

    private readonly _typeConditionArray = [
        { type: FilterDimType.RADIO, condition: (dim: FilterDim) => !dim._selected },
        { type: FilterDimType.SELECT, condition: (dim: FilterDim) => dim._value?.value === 'all' || dim._value === null },
        { type: FilterDimType.VALUE, condition: (dim: FilterDim) => (typeof dim._value?.value === 'string'
            && dim._value.value.length === 0) || typeof dim._value?.value === 'undefined' },
        { type: FilterDimType.MULTISELECT, condition: (dim: FilterDim) => dim._values?.length === 0 },
        { type: FilterDimType.DATE, condition: (dim: FilterDim) => typeof dim._value === 'undefined' },
        { type: FilterDimType.HIERARCHICALSELECT, condition: (dim: FilterDim) => typeof dim._values === 'undefined'
            || dim._values?.length === 0 }
    ];

    constructor(_: FlatFilter) {
        this.id = _.dim_id;
        this.type = _.dim_type;
        this.label = _.dim_label;
        this.name = _.dim_name;
        this.values = [];
        this.defaultValue = _.dim_default_value;
        this.isMandatory = _.is_mandatory;
        this.hierarchyLevel = _.hierarchy_level;
        this.hierarchyParentDimName = _.hierarchy_parent_dim_name;
        this.helpLabel = _.help_label;
        this.disableConstraint = _.dim_disable_constraint;
        this.radioConstraint = _.dim_radio_constraint;
        this.valuetype = _.dim_value_type;
        this.radioLink = _.dim_radio_link;
        this.hideEmpty = _.hide_empty;
        this.isVisible = true;
        this.actif = true;
        this.constraintRadioVisible = true;
        return this;
    }

    set value(val: FilterDimValue | FilterDimValue[]) {
        if (this._arrayValueDimType.includes(this.type)) {
            this._values = val as FilterDimValue[];
        } else {
            this._value = val as FilterDimValue;
        }
    }

    get value(): FilterDimValue | FilterDimValue[] {
        if (this._arrayValueDimType.includes(this.type)) {
            return this._values;
        } else {
            return this._value;
        }
    }

    get hasValue(): boolean {
        if ((this.type !== FilterDimType.RADIO && typeof this._value === 'undefined' && typeof this._values === 'undefined')) {
            return false;
        }

        for (const typeCondition of this._typeConditionArray) {
            if (typeCondition.type === this.type && typeCondition.condition(this)) {
                return false;
            }
        }
        return true;
    }

    get isMultiValue(): boolean { return this._arrayValueDimType.includes(this.type); }

    get displayValue(): DimValueTypes {
        if (!this.hasValue || !this._value) {
            return;
        }
        if (this.isMultiValue) {
            return this._values.length === 1 ? this._values[0].label : this._values.length.toString();
        } else if (this._value.label) {
            return this._value.label;
        } else if (this._value.value && this.type === 2) {
            const dateFormat: DateFormatPipe = new DateFormatPipe();
            return DateTime.fromJSDate(this._value.value).toFormat(dateFormat.localDateFormat());
        } else if (this._value.value) {
            return this._value.value;
        }
    }

    public getValue(): DimValueTypes | DimValueTypes[] {
        if (this.type === FilterDimType.RADIO) {
            return this._selected.toString();
        }
        if (this.isMultiValue) {
            return this._values?.map((val: FilterDimValue) => val.value);
        } else {
            return this._value?.value;
        }
    }
}
