/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { QueryAuthToken } from '../query/query.service';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

enum URLItemType {
	STRING = 'string',
	NUMBER = 'number'
}

interface URLItem {
	key: string;
	type: URLItemType;
}

type UntypedQueryAuthToken = Omit<QueryAuthToken, 'tokenType'>;

export const SESSION_TOKEN_KEY = 'token';
export const COOKIE_URL_KEY = 'url';

const SESSION_AXEPTA_URL_PARAM = 'isAxeptaUser';
const QUERY_AUTH_TOKEN_ATTRIBUTES_COUNT = 4;

@Injectable({
  providedIn: 'root'
})
export class SessionService {
	public triggerOAuth2Authentication$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	private _params: string[];
	private _url: string;

	constructor(
		@Inject(DOCUMENT) private readonly _document: Document,
		private readonly _cookieService:CookieService
	) {
		this._url = this._document.URL;
	}

	set url(url: string) {
		this._url = url;
	}

	/**
	 * @description Find out in url specifics paramaters in order to set the session context (default | oauth2 authentication | axepta redirection)
	 * @returns void
	 */
	public init(): void {
		const splittedUrl = this._url.split('?');
		const params: string = splittedUrl.length && splittedUrl[1];

		if (!params) {
			return; // Default context
		}

		this._params = params.split(',');
		this._setSessionContext();
	}

	public setItem<T>(sessionStorageKey: string, value: T): void {
		window.sessionStorage[sessionStorageKey] = value;
	}

	public getItem<T>(sessionStorageKey: string): T | undefined {
		return window.sessionStorage[sessionStorageKey];
	}

	/**
	 * @description Try to extract isAxeptaUser from url in order to know if it is an axepta redirection context, if so, trigger OAuth2 authentication on app component
	 */
	private _setSessionContext(): void {
		const isAxeptaUser = this._params.find(param => param.includes(SESSION_AXEPTA_URL_PARAM));

		!isAxeptaUser
			? this._storeJwtToken()
			: this._cookieService.set(
				COOKIE_URL_KEY,
				this._url
					.split('#')
					.pop()				// Remove domain
					.split('?')
					.shift()			// Remove params
			);

		this.triggerOAuth2Authentication$.next(
			!!isAxeptaUser
		);
	}

	/**
	 * @description Extract jwt from url and store it in session storage after oauth2 authentication
	 * @returns void
	 */
	private _storeJwtToken(): void  {
		const tokenJson: QueryAuthToken = {
			...this._generateAuthTokenJSON([
				{ key: 'jwt', type: URLItemType.STRING },
				{ key: 'refreshToken', type: URLItemType.STRING},
				{ key: 'expirationTime', type: URLItemType.NUMBER }
			]),
			tokenType: 'bearer'
		};

		if (Object.keys(tokenJson).length < QUERY_AUTH_TOKEN_ATTRIBUTES_COUNT) {
			return;
		}
		this.setItem<string>(SESSION_TOKEN_KEY, JSON.stringify(tokenJson));
	}

	private _generateAuthTokenJSON(urlItems: URLItem[]): UntypedQueryAuthToken {
		const json: UntypedQueryAuthToken | {} = {};

		urlItems.forEach(urlItem => {
			const value = this._extractFromUrl(urlItem.key, urlItem.type);
			value && (json[urlItem.key] = value);
		});

		return json as UntypedQueryAuthToken;
	}

	private _extractFromUrl(key: string, type: URLItemType): string | number | undefined {
		const value = this._params.find(param => param.includes(key));

		if (!value) {
			return;
		}

		return type === URLItemType.STRING 
			? value.split('=').pop()
			: Number(value.split('=').pop());
	}
}
