/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertService } from '@services/alert/alert.service';
import { AuthService } from '@services/auth/auth.service';
import { Subscription, Observable, timer } from 'rxjs';

@Component({
  selector: 'app-alert-structure',
  templateUrl: './alert-structure.component.html',
  styleUrls: ['./alert-structure.component.scss']
})
export class AlertStructureComponent implements OnInit, OnDestroy {
    public displayAlert = false;

    private _subscription: Subscription;
    private _timer: Observable<number>;

    constructor(public authService: AuthService, private _alertService: AlertService) {
        this.displayAlert = !this._alertService.alertStructureAlreadyDisplay
                            && this.authService.hasKOStructure;
    }

    ngOnInit(): void {
        this.setTimer();
    }

    ngOnDestroy(): void {
        if (this._subscription && this._subscription instanceof Subscription) {
            this._subscription.unsubscribe();
        }
    }

    public setTimer(): void {
        const timerVal = 60000;
        if (!this._alertService.alertStructureAlreadyDisplay && this.authService.hasKOStructure) {
            this._timer = timer(timerVal);
            this._subscription = this._timer.subscribe(() => {
                this._alertService.alertStructureAlreadyDisplay = true;
                this.displayAlert = false;
            });
        }
    }

    public manuallyCloseAlert(): void {
        this._alertService.alertStructureAlreadyDisplay = true;
        this.displayAlert = false;
    }
}
