/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { MenuItemType } from './menu-item-type.enum';
import { MenuItemFromDb } from './menu-item-from-db.interface';
import { TemplateType } from '@services/templates/template-typings';

export class MenuItem {
    id: number;
    idPrnt?: number;
    code: string;
    url: string;
    type: MenuItemType;
    idStyle: number;
    idsGrid?: number[] = [];
    idsChart?: number[] = [];
    order?: number;
    templateType?: TemplateType;
    typeSelectionAvailable?: boolean;

    private _drill?: boolean;
    private _forceDrill?: boolean;
    private _highlight?: boolean;
    private _children?: MenuItem[];

    constructor(_: MenuItemFromDb) {
        this.id = _.ID_MENU;
        this.idPrnt = _.ID_MENU_PARENT;
        this.code = _.CODE_MENU;
        this.url = _.URL;
        this.type = MenuItemType[_.TYPE];
        this.idStyle = _.CODE_STYLE;
        this.order = _.NB_ORDER;
        if (_.LIST_GRID) {
            this.idsGrid = _.LIST_GRID.split(',').map(d => Number(d));
        }
        if (_.LIST_CHART) {
            this.idsChart = _.LIST_CHART.split(',').map(d => Number(d));
        }
        if (this.idsGrid.length > 0 && this.idsChart.length > 0) {
            this.typeSelectionAvailable = true;
        }
        this.templateType = TemplateType.GRID;
        if (_.CD_TEMPLATE_TYPE) {
            this.templateType = _.CD_TEMPLATE_TYPE as TemplateType;
        }
    }

    get isRoot(): boolean { return typeof this.idPrnt === 'undefined' || this.idPrnt === null; }

    get drill(): boolean { return this._drill; }
    set drill(drill: boolean) { this._drill = drill; }

    get forceDrill(): boolean { return this._forceDrill; }
    set forceDrill(forceDrill: boolean) { this._forceDrill = forceDrill; }

    get highlight(): boolean { return this._highlight; }
    set highlight(highlight: boolean) { this._highlight = highlight; }

    get children(): MenuItem[] { return this._children; }


    public addChild(child: MenuItem): void {
        if (typeof this._children === 'undefined') {
            this._children = [];
        }
        this._children.push(child);
    }
}
