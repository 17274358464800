/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ViewComponent } from './view/view.component';
import { LoginComponent } from './view/login/login.component';
import { HelpComponent } from './view/help/help.component';
import { HomeComponent } from './view/home/home.component';
import { ResetPasswordRequestComponent } from './view/reset-password-request/reset-password-request.component';
import { homeRouterUrl } from './services/menu/menu.service';

const appRoutes: Routes = [
    {
        path: 'view',
        component: ViewComponent,
        children: [
            {
                path: 'resetPasswordRequest',
                component: ResetPasswordRequestComponent
            },
            {
                path: 'user-profile',
                component: HomeComponent // TODO
            },
            {
                path: 'login',
                component: LoginComponent
            },
            {
                path: 'home',
                component: HomeComponent
            },
            {
                path: 'help',
                component: HelpComponent
            }
        ]
    },
    {
        path: '**',
        redirectTo: homeRouterUrl
    }
];

declare module '@angular/core' {
    interface ModuleWithProviders<T = any> {
        ngModule: Type<T>;
        providers?: (Provider | EnvironmentProviders)[];
    }
}

export const RoutingModule: ModuleWithProviders = RouterModule.forRoot(appRoutes, { useHash: true, initialNavigation: 'disabled' });
