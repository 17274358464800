/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';

import { MenuService } from '@services/menu/menu.service';
import { MenuItem, MenuItemType } from '@services/menu/menu-typings';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
    @Input() menuItems: MenuItem[];

    public isRoot = false;
    public isCollapse = false;

    constructor(public menuService: MenuService) { }

    ngOnInit(): void {
        if (typeof this.menuItems === 'undefined') {
            this.isRoot = true;
            this.menuItems = this.menuService.menuItems;
        }
    }

    public interact(event: Event, menuItem: MenuItem): void {
        if (menuItem.type === MenuItemType.DASH || menuItem.url === this.menuService.homeUrl || menuItem.url === this.menuService.helpUrl) {
            this.resetAllDrill();
            this.menuService.interractItemDashboard(menuItem);
            this.menuService.selectItemMenu(menuItem);
        }
        event.stopPropagation();
    }

    public resetAllDrill(): void {
        this.menuService.menuItems.forEach(menuItem => {
            menuItem.forceDrill = false;
            menuItem.drill = false;
            this._resetChildrenDrill(menuItem);
        });
    }

    public collapseMenu(): void {
        this.isCollapse = !this.isCollapse;
        this.menuService.isCollapse = this.isCollapse;
    }

    private _resetChildrenDrill(menuItem: MenuItem): void {
        if (menuItem.children) {
            for (const child of menuItem.children) {
                child.drill = false;
                child.forceDrill = false;
                this._resetChildrenDrill(child);
            }
        }
    }
}
