/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, Input, AfterContentInit } from '@angular/core';
import { FilterDim, Filter, FilterGroup } from '@components/filters/filter-typings';

@Component({
    selector: 'app-filter-dimension-radio',
    templateUrl: './filter-dimension-radio.component.html',
    styleUrls: ['./filter-dimension-radio.component.scss']
})
export class FilterDimensionRadioComponent implements AfterContentInit {
    @Input() dim: FilterDim;
    @Input() filter: Filter;
    @Input() group: FilterGroup;
    @Input() disabled: boolean;

    public ngAfterContentInit(): void {
        if (typeof this.dim.radioConstraint !== 'undefined' && this.dim.id === this.dim.radioConstraint[0]) {
            this.dim['_radioModel'] = this.dim.id;
            this.onRadioChange();
        }
    }

    public onRadioChange(): void {
        this.filter.dimensions.forEach(dim => {
            if (this.dim.radioConstraint.indexOf(dim.id) !== -1) {
                dim['_selected'] = (this.dim['_radioModel'] === dim.id);
                this.dim.value = this.dim.values[0];
            }
        });
    }
}
