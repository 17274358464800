/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Injectable } from '@angular/core';

import { FormatterService } from './formatter.class';
import { CssFormatterOptions } from '../slickgrid-formatter/slickgrid-formatter.service';
import { DateTime } from 'luxon';

enum FormatMethodName {
    FORMAT = 'format'
}

export interface DateFormatterOptions extends CssFormatterOptions {
    format?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DateFormatterService extends FormatterService {
    public callOrder: FormatMethodName[] = [
        FormatMethodName.FORMAT
    ];

    private _defaultFormat = 'dd/MM/yyyy';

    set defaultFormat(format: string) { this._defaultFormat = format; }
    get defaultFormat(): string { return this._defaultFormat; }

    public value(value: Date): DateFormatterService {
        FormatterService.prototype.value.apply(this, [value]);
        return this;
    }

    public format(format: string = this._defaultFormat): DateFormatterService {
        this._addCallToStack(FormatMethodName.FORMAT, arguments);
        return this;
    }

    private _format(format: string = this._defaultFormat): void {
        this._displayedValue = DateTime.fromJSDate(this._value).toFormat(format);
    }
}
