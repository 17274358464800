/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

export type ComponentInstance = Object;

export abstract class TemplateModel {

    public create(name: string, htmlArgs?: Object, jsArgs?: Object): ComponentInstance {
        const selectorTemplate = `dyn-${name}`;
        const htmlTemplate: string = this._getHtmlTemplate(htmlArgs);
        return this._getComponent(selectorTemplate, htmlTemplate, jsArgs);
    }

    /* tslint:disable-next-line:no-any */
    protected _getHtmlTemplate(...args: any[]): string { throw new Error('You must implement _getHtmlTemplate'); }

    /* tslint:disable-next-line:no-any */
    protected _getComponent(...args: any[]): ComponentInstance { throw new Error('You must implement _getComponent'); }
}
