/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FilterGroup, Filter } from '@components/filters/filter-typings';

export type FadeState = 'visible' | 'hidden';

@Component({
    selector: 'app-filter-group',
    templateUrl: './filter-group.component.html',
    styleUrls: ['./filter-group.component.scss']
})
export class FilterGroupComponent implements AfterViewInit {
    @Input() group: FilterGroup;
    @Input() iconClass: string;
    @Input() displayHeaderValuesList = true;
    @Output() collapse: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() filterRadioChange: EventEmitter<void> = new EventEmitter<void>();

    public filterList: string[] = [];
    public disableFilterOnRadioChangeCallback: (changedFilter: Filter) => void = this.disableFilterOnRadioChange.bind(this);

    ngAfterViewInit(): void {
        this.group.filters.forEach(filter => {
            if (typeof filter.disableConstraint !== 'undefined' && filter.filterId === filter.disableConstraint[0]) {
                filter['_radioDisableModel'] = filter.filterId;
                this.disableFilterOnRadioChange(filter);
            }
        });
    }

    public disableFilterOnRadioChange(changedFilter: Filter): void {
        this.group.filters.forEach(filter => {
            if (changedFilter.disableConstraint.indexOf(filter.filterId) !== -1) {
                filter['_disabled'] = (changedFilter['_radioDisableModel'] !== filter.filterId);
            }
        });
        this.filterRadioChange.next();
    }

    public toggleCollapse(): void {
        this.group.isCollapsed = !this.group.isCollapsed;
        this.collapse.next(this.group.isCollapsed);
    }
}
