/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { GenericGridClickService } from '@services/generic-grid/generic-grid-click.service';
import { ContractContainer, ContractBox, ContractEntry } from './contract-container/contract-container.component';
import { DateFormatterService } from '@services/formatter/date-formatter.service';
import { StringFormatterService } from '@services/formatter/string-formatter.service';

export interface Contract {
    id: number;
    CD_APE: string;
    CD_BANQUE_RIB: string;
    CD_CODE_CHAINE: string;
    CD_COMMUNE: string;
    CD_DEPARTEMENT: string;
    CD_MCC: string;
    CD_PERDC_DT_COMSS: string;
    CD_POSTAL: string;
    CD_RIB_DT_COMSS: string;
    CD_SIEGE_RIB: string;
    DT_CLOTURE: string;
    DT_MODIFICATION: string;
    DT_OUVERTURE: string;
    LB_CODE_CHAINE: string;
    LB_CONTRAT: string;
    LB_ENSEIGNE_COMMERCIALE: string;
    LB_GRAND_COMMERCE: string;
    LB_LIEU_DIT: string;
    LB_RAISON_SOCIALE: string;
    LB_TYPE_PAIMT: string;
    LB_CREDIT_BRUT: string;
    LB_REGROUP_CREDIT: string;
    LB_PERIDC_RELEVE: string;
    LB_RELEVE: string;
    LB_JOUR_DT_VALEUR: string;
    NU_COMPTE_RIB: string;
    NU_CONTRAT: number;
    NU_RUE: string;
    NU_SIREN: string;
    NU_SIRET: string;
}

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss']
})
export class ContractComponent implements OnInit, OnChanges {
    @Input() contract: Contract;
    @Input() contracts: Array<Contract>;
    @Output() print: EventEmitter<void> = new EventEmitter();

    public configTop: ContractContainer;
    public configBottom: Array<ContractContainer>;

    constructor(
        public clickService: GenericGridClickService,
        private readonly _string: StringFormatterService,
        private readonly _date: DateFormatterService) {}

    ngOnInit(): void { this._buildConfig(); }
    ngOnChanges(): void { this._buildConfig(); }

    public next(): void {
        const i: number = this.contracts.findIndex(ctr => ctr.NU_CONTRAT === this.contract.NU_CONTRAT);
        const next: Contract = i < this.contracts.length - 1 ? this.contracts[i + 1] : this.contracts[0];
        this.clickService.selectedContract = next;
    }

    public prev(): void {
        const i: number = this.contracts.findIndex(ctr => ctr.NU_CONTRAT === this.contract.NU_CONTRAT);
        const prev: Contract = i > 0 ? this.contracts[i - 1] : this.contracts[this.contracts.length - 1];
        this.clickService.selectedContract = prev;
    }

    private _buildConfig(): void {
        let endDate: string = this.contract.DT_CLOTURE;
        let modificationDate: string = this.contract.DT_MODIFICATION;
        let creationDate: string = this.contract.DT_OUVERTURE;
        let cdRIB: string = this.contract.CD_RIB_DT_COMSS;
        if (modificationDate) {
            modificationDate = this._date.value(new Date(modificationDate)).format().apply();
        }
        if (endDate) {
            endDate = this._date.value(new Date(endDate)).format().apply();
        }
        if (creationDate) {
            creationDate = this._date.value(new Date(creationDate)).format().apply();
        }
        if (cdRIB) {
            cdRIB = this._string.value(this.contract.CD_RIB_DT_COMSS).rib().apply();
        }
        this.configTop = new ContractContainer('_MERCHANT_CONTRACT_', [
            new ContractBox('_IDENTIFICATION_', [
                new ContractEntry('_CONTRACT_LABEL_', this.contract.LB_CONTRAT),
                new ContractEntry('_SIRET_', this.contract.NU_SIRET)
            ]),
            new ContractBox('_ADDRESS_', [
                new ContractEntry('_STREET_NUMBER_', this.contract.NU_RUE),
                new ContractEntry('_LOCALITY_', this.contract.LB_LIEU_DIT),
                new ContractEntry('_ZIPCODE_', this.contract.CD_POSTAL),
                new ContractEntry('_CITY_', this.contract.CD_COMMUNE)
            ]),
            new ContractBox('_CONTRACT_DATES_', [
                new ContractEntry('_CREATION_DATE_', creationDate),
                new ContractEntry('_MODIFICATION_DATE_', modificationDate),
                new ContractEntry('_END_DATE_', endDate)
            ])
        ], this.contract.NU_CONTRAT);
        this.configBottom = [
            new ContractContainer('_DOMICILIATION_', [
                new ContractBox('_DIRECT_DEBIT_ACCOUNT_', [
                    new ContractEntry('_BANK_CODE_RIB_', this.contract.CD_BANQUE_RIB),
                    new ContractEntry('_HEADQUARTERS_CODE_RIB_', this.contract.CD_SIEGE_RIB),
                    new ContractEntry('_BANK_ACCOUNT_NUMBER_', this.contract.NU_COMPTE_RIB)
                ]),
                new ContractBox('_POS_NAME_', [
                    new ContractEntry('_COMMERCIAL_', this.contract.LB_ENSEIGNE_COMMERCIALE),
                    new ContractEntry('_DPTM_AND_CITY_', `${this.contract.CD_DEPARTEMENT} ${this.contract.CD_COMMUNE}`),
                    new ContractEntry('_POS_REF_', this.contract.LB_GRAND_COMMERCE)
                ]),
                new ContractBox('_ACTIVITY_', [
                    new ContractEntry('_CD_APE_', this.contract.CD_APE),
                    new ContractEntry('_CD_MMC_', this.contract.CD_MCC),
                    new ContractEntry('_PAYMENT_TYPE_', this.contract.LB_TYPE_PAIMT)
                ])
            ]),
            new ContractContainer('_CHARAC_', [
                new ContractBox('_ACCOUNTING_SERVICE_', [
                    new ContractEntry('_CRED_OP_', this.contract.LB_CREDIT_BRUT),
                    new ContractEntry('_GATHERING_REMITTANCE', this.contract.LB_REGROUP_CREDIT),
                    new ContractEntry('_INVOICES_', this.contract.LB_RELEVE),
                    new ContractEntry('_PERIOD_INVOICES_', this.contract.LB_PERIDC_RELEVE)
                ]),
                new ContractBox('_DBT_MERCH_SERV_CHARGES_', [
                    new ContractEntry('_BANK_ACC_FOR_CHARGES_', cdRIB),
                    new ContractEntry('_MTH_DBT_FOR_CHARGES_', this.contract.CD_PERDC_DT_COMSS),
                    new ContractEntry('_VAL_DATE_', this.contract.LB_JOUR_DT_VALEUR)
                ])
            ])
        ];
    }
}
