/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Injectable, isDevMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, concat } from 'rxjs';

import packageInfo from 'package.json';

export enum AuthenticationModes {
    BASIC,
    WEBSSO
}

export interface Config {
    backendUrl: string;
    authenticationUrl: string;
    authenticationMode: AuthenticationModes;
    rowsLimit: number;
    maxRowsLimit: number;
    personalDataLink: string;
    email: string;
    phoneNumber: string;
    faxNumber: string;
    maBanqueEntrepriseLink: string;
    passwordExpirationWarnings: number[];
    merchantPortalUrl: string;
}

export interface ColumnFormatter {
    label: string;
    idFormatter: number;
}

@Injectable()
export class ConfigService {
    private _config: Config;
    private _columnFormatters: ColumnFormatter[];
    private _appConfigUrl = 'config.app.json';
    private _envConfigUrl = 'config.env.json';
    private readonly _columnFormattersUrl = 'column-formatters.json';
    private readonly _dashboardRootUrl = '/reports/';
    private readonly _templateGetI18n = 'get_i18n.tpl';
    private readonly _templateGetMenu = 'get_menu.tpl';
    private readonly _templateGetFilter = 'get_filter_config.tpl';
    private readonly _templateGetGridConf = 'get_grid_config.tpl';
    private readonly _templateFormatter = 'get_formatter.tpl';
    private readonly _templateGetChartConf = 'get_chart_config.tpl';
    private readonly _version: string = packageInfo.version;
    private readonly _hourInMilliseconds = 3600000;

    constructor (private readonly http: HttpClient) {
    }

    public init(): Promise<Config> {
        return new Promise((resolve, reject) => {
            const time: number = Math.round(new Date().getTime() / this._hourInMilliseconds) * this._hourInMilliseconds;
            const appConfigObs: Observable<Object> = this.http.get(this._appConfigUrl, {
                params: {
                    'v': time.toString()
                }
            });
            const envConfigObs: Observable<Object> = this.http.get(this._envConfigUrl, {
                params: {
                    'v': time.toString()
                }
            });
            const concatResponse: Object = {};

            concat(envConfigObs, appConfigObs).subscribe({
                next: (response: Object[]) => {
                    Object.keys(response).forEach(key => concatResponse[key] = response[key]);
                },
                error: error => {
                    if (error.url.indexOf('config.env.json') > -1) {
                        if (isDevMode()) {
                            console.error('Please create your `config.env.json` file in src/config/ from the example and rebuild or restart your application.');
                        }
                        reject(error);
                    } else if (Object.keys(concatResponse).length) {
                        this._config = concatResponse as Config;
                        resolve(null);
                    }
                },
                complete: () => {
                    this._config = concatResponse as Config;
                    resolve(null);
                }
            });
        });
    }

    public initColumnFormatters(): Promise<void> {
        return new Promise((resolve, reject) => {
            const time: number = Math.round(new Date().getTime() / this._hourInMilliseconds) * this._hourInMilliseconds;

            this.http.get(this._columnFormattersUrl, {
                params: {
                    'v': time.toString()
                }
            }).subscribe({
                next: (response: Object) => {
                    this._columnFormatters = response['columns'];
                },
                error: error => reject(error),
                complete: () => resolve()
            });
        });
    }

    get config(): Config { return this._config; }
    set config(newConfig: Config) { this._config = newConfig; }

    get appConfigUrl(): string { return this._appConfigUrl; }
    set appConfigUrl(newConfigUrl: string) { this._appConfigUrl = newConfigUrl; }

    get envConfigUrl(): string { return this._envConfigUrl; }
    set envConfigUrl(newConfigUrl: string) { this._envConfigUrl = newConfigUrl; }

    get columnFormatters(): ColumnFormatter[] { return this._columnFormatters; }
    get columnFormattersUrl(): string { return this._columnFormattersUrl; }
    get dashboardRootUrl(): string { return this._dashboardRootUrl; }
    get templateGetMenu(): string { return this._templateGetMenu; }
    get templateGetFilter(): string { return this._templateGetFilter; }
    get templateGetGridConf(): string { return this._templateGetGridConf; }
    get templateGetChartConf(): string { return this._templateGetChartConf; }
    get templateGetI18n(): string { return this._templateGetI18n; }
    get templateGetFormatter(): string { return this._templateFormatter; }
    get version(): string { return this._version; }

    get authenticationUrl(): string { return this._config.authenticationUrl; }
    get authenticationMode(): AuthenticationModes { return this._config.authenticationMode; }
    get backendUrl(): string { return this._config.backendUrl; }
    get rowsLimit(): number { return this._config.rowsLimit; }
    get maxRowsLimit(): number { return this._config.maxRowsLimit; }
    get personalDataLink(): string { return this._config.personalDataLink; }
    get email(): string { return this._config.email; }
    get phoneNumber(): string { return this._config.phoneNumber; }
    get faxNumber(): string { return this._config.faxNumber; }
    get maBanqueEntrepriseLink(): string { return this._config.maBanqueEntrepriseLink; }
    get passwordExpirationWarnings(): number[] { return this._config.passwordExpirationWarnings; }
    get isWebSSOAuthentication(): boolean { return this._config.authenticationMode === AuthenticationModes.WEBSSO; }
    get merchantPortalUrl(): string { return this._config.merchantPortalUrl; }
}
