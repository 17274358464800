/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { MenuService } from '@services/menu/menu.service';
import { LanguageService } from '@services/language/language.service';
import { AuthService } from '@services/auth/auth.service';
import { ConfigService } from '@app/services/config/config.service';

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, OnDestroy {
    private readonly _subscriptions: Subscription = new Subscription();

    constructor(
        public authService: AuthService,
        public language: LanguageService,
        public menu: MenuService,
        public configService: ConfigService) {}

    ngOnInit(): void {
        this._subscriptions.add(this.authService.getUserInfos().subscribe());
    }

    ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public logout(): void {
        this.authService.logout();
        this.menu.selected = null;
    }

    public redirectToHome(): void {
        if (this.menu.homeItem) {
            this.menu.selectItemMenu(this.menu.homeItem);
        }
    }

    public setLanguage(lang: string): void {
        this._subscriptions.add(this.language.setLanguage(lang).subscribe());
    }

    public redirectToAxepta(): void {
        window.open(this.configService.merchantPortalUrl);
    }
}
