/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { FilterValueI18n } from './filter-i18n.class';
import { FilterDimValueType } from './filter-dim-value-type.enum';

export type DimValueTypes = number | string;

export class FilterDimValue {
    label: string;
    title: string;
    value: DimValueTypes;
    key: DimValueTypes;
    parentValue?: DimValueTypes;
    hierarchyHidden?: boolean;
    hierarchyLevel?: string;
    valueType: FilterDimValueType;
    children?: FilterDimValue[];
    emittable?: boolean;
    isLeaf?: boolean;

    constructor(_: FilterDimValue|FilterValueI18n) {
        return (_ instanceof FilterValueI18n ? this._fromI18nValue(_) : this._byCopy(_));
    }

    get type(): FilterDimValueType { return this.valueType; }
    set type(type: FilterDimValueType) {
        this.valueType = type;
        this._castValue();
    }

    public addChild(child: FilterDimValue): FilterDimValue {
        if (typeof this.children === 'undefined') {
            this.children = [];
        }
        child.emittable = true;
        if (this.emittable) {
            delete this.emittable;
        }
        this.children.push(child);
        return this;
    }

    private _byCopy(_: FilterDimValue): FilterDimValue {
        this.label = _.label;
        this.title = _.title;
        this.value = _.value;
        this.key = _.value;
        this.valueType = _.valueType;
        this.hierarchyHidden = _.hierarchyHidden;
        this.parentValue = _.parentValue;
        return this;
    }

    private _fromI18nValue(_: FilterValueI18n): FilterDimValue {
        this.label = _.cd_ref_val;
        this.title = _.cd_ref_val;
        this.value = _.cd_ref_cd;
        this.key = _.cd_ref_cd;
        this.hierarchyHidden = _.hierarchy_hidden;
        this.hierarchyLevel = _.cd_lvl;
        if (typeof _.cd_ref_cd_prnt !== 'undefined' && _.cd_ref_cd_prnt !== null) {
            this.parentValue = _.cd_ref_cd_prnt;
        }
        return this;
    }

    private _castValue(): void {
        if (typeof this.valueType === 'undefined') {
            return;
        }
        const castFuncs: (() => DimValueTypes)[] = [];
        castFuncs[FilterDimValueType.STRING] = this._valueToString.bind(this);
        castFuncs[FilterDimValueType.NUMBER] = this._valueToNumber.bind(this);
        this.value = castFuncs[this.valueType]();
        this.key = this.value;
    }

    private _valueToNumber(): DimValueTypes {
        const toNum: number = parseFloat(this.value as string);
        if (!isNaN(toNum)) {
            return toNum;
        }
        return this.value;
    }

    private _valueToString(): DimValueTypes {
        return typeof  this.value !== 'undefined' ? this.value.toString() : '';
    }
}
