/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-empty-data',
    templateUrl: './empty-data.component.html',
    styleUrls: ['./empty-data.component.scss']
})
export class EmptyDataComponent {
    @Input() label = '_LABEL_._EMPTY_GRID_TXT_';
}
