/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, Input, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { FilterConfigService } from '@services/filter-config/filter-config.service';
import { FilterDimValueService } from '@services/filter-dim-value/filter-dim-value.service';
import { FilterGroup } from './filter-typings';
import { LanguageService } from '@services/language/language.service';

@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, OnDestroy {
    @Input() idReport: number;
    @Input() applyFunc: (type: string) => void;
    @Input() defaultCollapse = false;
    @Input() displayHeaderValuesList = true;
    @Input() typeSelectionAvailable = false;
    @Input() selectedType = '';
    @Output() collapse: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() reset: EventEmitter<boolean> = new EventEmitter<boolean>();

    public filterGroups: FilterGroup[];
    public displayApplyHelp = false;
    public displayTypeSelection = false;
    public disableTypeSelection = false;

    private readonly _subscriptions: Subscription = new Subscription();

    constructor(public filterConfigService: FilterConfigService,
        public filterDimValueService: FilterDimValueService,
        private readonly _languageService: LanguageService) {
        this.applyFunc = this.filterConfigService.triggerApply.bind(this.filterConfigService);
    }

    ngOnInit(): void {
        this.loadFilterConfig();
        this._subscriptions.add(this._languageService.translationLoaded.subscribe(this.loadFilterConfig.bind(this, true)));
    }

    ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public loadFilterConfig(forceReload = false): void {
        this._subscriptions.add(this.filterConfigService.init(this.idReport, forceReload).subscribe(() => {
            this.filterGroups = this.filterConfigService.config;
            if (this.typeSelectionAvailable) {
                this.displayTypeSelection = true;
                this.enableOrDisableTypeSelection();
            }
            if (this.defaultCollapse) {
                this.collapseAllGroup(true, true);
            }
        }));
    }

    public hideButtons(): boolean {
        let hide = false;
        this.filterGroups.forEach((group: FilterGroup) => {
            if (!group.isCollapsed) {
                hide = true;
            }
        });
        return hide;
    }

    public applyAvailable(): boolean {
        return (this.filterDimValueService.applyAvailable
            && (!this.displayTypeSelection || (this.displayTypeSelection && this.selectedType !== '')));
    }

    public collapseAllGroup(val: boolean, init = false): void {
        this.filterGroups.forEach((group: FilterGroup) => {
            group.isCollapsed = val;
        });
        if (!init) {
            this.collapse.next(val);
        }
    }

    public resetFilter(): void {
        this.filterDimValueService.resetValues();
        this.filterDimValueService.resetSelectedValues();
        if (this.typeSelectionAvailable) {
            this.displayTypeSelection = true;
            this.selectedType = 'grid';
            this.enableOrDisableTypeSelection();
        }
        this.reset.next();
    }

    public enableOrDisableTypeSelection(): void {
        this.disableTypeSelection = false;
        if (!this.filterGroups) {
            return;
        }
        for (const group of this.filterGroups) {
            for (const filter of group.filters) {
                if (!filter['_disabled'] && filter.filterId === filter.restrictDisplayGrid) {
                    this.selectedType = 'grid';
                    this.disableTypeSelection = true;
                    return;
                }
            }
        }
    }
}
