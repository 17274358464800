/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, Input, AfterContentInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { FilterDimValueService } from '@services/filter-dim-value/filter-dim-value.service';
import { FilterDim, Filter, FilterGroup, FilterDimValue } from '@components/filters/filter-typings';
import { LanguageService } from '@services/language/language.service';
import { DateFormatPipe } from '@services/formatter/date-format-pipe.class';
import { differenceInCalendarDays } from 'date-fns';

@Component({
    selector: 'app-filter-dimension-date',
    templateUrl: './filter-dimension-date.component.html',
    styleUrls: ['./filter-dimension-date.component.scss']
})
export class FilterDimensionDateComponent implements AfterContentInit, OnDestroy {
    @Input() dim: FilterDim;
    @Input() filter: Filter;
    @Input() group: FilterGroup;
    @Input() disabled: boolean;

    public format: string = new DateFormatPipe().localDateFormat();
    public startDate: Date;
    public endDate: Date;
    public boundDisabledDate: boolean = this.disabledDate.bind(this);
    public currentLanguageFormat: string = this.languageService.getLanguageFormat(this.languageService.currentLang);

    private readonly _subscriptions: Subscription = new Subscription();
    private readonly _homeDashboardDepth = 2;

    constructor(public filterDimValueService: FilterDimValueService,
                public languageService: LanguageService) {}

    public ngAfterContentInit(): void {
        this.filterDimValueService.resetValue(this.dim);
        this.filterDimValueService.searchSelectedValue(this.dim);

        this._subscriptions.add(this.filterDimValueService.filterDimValueUpdated.subscribe(this._updateDateRange.bind(this)));
        this._subscriptions.add(this.languageService.translationLoaded.subscribe(this._updateDateFormat.bind(this)));
        this._updateDateRange();
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public setDate(newDate: Date): void {
        const formattedDate: string = this._getFormattedDate(newDate);
        const newValue: FilterDimValue = this.filterDimValueService.buildValue(this.dim, formattedDate);
        this.dim.value = newValue;
        this.filterDimValueService.filterDimValueUpdate();
    }

    public disabledDate(current: Date): boolean {
        return differenceInCalendarDays(current, this.startDate) < 0 || differenceInCalendarDays(current, this.endDate) > 0;
    }

    private _updateDateRange(): void {
        if (this.dim.name === 'DT_TO') {
            const filterDim: FilterDim = this.filter.dimensions.find(dim => dim.name === 'DT_FROM');
            if (filterDim) {
                const filterDimValue: FilterDimValue = filterDim.value as FilterDimValue;
                this.startDate = new Date(filterDimValue.value as string);
            }
        } else if (this.dim.name === 'DT_FROM') {
            const filterDim: FilterDim = this.filter.dimensions.find(dim => dim.name === 'DT_TO');
            if (filterDim) {
                const filterDimValue: FilterDimValue = filterDim.value as FilterDimValue;
                this.endDate = new Date(filterDimValue.value as string);
            }
        } else if (this.dim.name === 'DT_REF') {
            this.startDate = new Date();
            this.startDate.setDate(this.startDate.getDate() - this._homeDashboardDepth);
            this.endDate = new Date();
        }
    }

    private _updateDateFormat(): void {
        this.currentLanguageFormat = this.languageService.getLanguageFormat(this.languageService.currentLang);
    }

    private _getFormattedDate(date: Date): string {
        const year: number = date.getFullYear();
        const month: number = date.getMonth() + 1;
        const day: number = date.getDate();
        const zeroPrefixThreshold = 10;

        const monthStr: string = month >= zeroPrefixThreshold ? `${month}` : `0${month}`;
        const dayStr: string = day >= zeroPrefixThreshold ? `${day}` : `0${day}`;

        return `${year}-${monthStr}-${dayStr}`;
    }
}
