/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Settings } from 'luxon';
export class DateFormatPipe {
    emitFormat: { [lang: string]: string } = {
        'fr': 'dd/MM/yyyy',
        'en': 'yyyy/MM/dd'
    };

    public localDateFormat(): string {
        const localeLanguage: string = Settings.defaultLocale;
        return this.emitFormat[localeLanguage];
    }
}
