/*
 * Copyright © BNP PARIBAS - All rights reserved.
 */

import { Component, Input, AfterContentInit, OnDestroy } from '@angular/core';
import { FilterDim, FilterGroup, FilterDimValue, Filter, FilterDimValueType } from '@components/filters/filter-typings';
import { FilterDimValueService } from '@services/filter-dim-value/filter-dim-value.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-filter-dimension-value',
    templateUrl: './filter-dimension-value.component.html',
    styleUrls: ['./filter-dimension-value.component.scss']
})
export class FilterDimensionValueComponent implements AfterContentInit, OnDestroy {
    @Input() dim: FilterDim;
    @Input() filter: Filter;
    @Input() group: FilterGroup;
    @Input() disabled: boolean;

    public failValidation: boolean;
    public validateNumber: boolean;
    public valueTypes: typeof FilterDimValueType = FilterDimValueType;
    public min: number;
    public max: number;

    private readonly _subscriptions: Subscription = new Subscription();

    constructor(private readonly _filterDimValueService: FilterDimValueService) {}

    public ngAfterContentInit(): void {
        this._init();

        this._subscriptions.add(this._filterDimValueService.filterDimValueUpdated.subscribe(this._validateNumber.bind(this)));
        this._validateNumber();
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public onChange(): void {
        const newValue: FilterDimValue = this._filterDimValueService.buildValue(this.dim, this.dim['_valueModel']);
        this.dim.value = newValue;
        this._filterDimValueService.filterDimValueUpdate();
    }

    public setFailFlag(flag: boolean): void {
        setTimeout(() => this.failValidation = flag);
    }

    private _init(): void {
        this._filterDimValueService.resetValue(this.dim);
        this._filterDimValueService.searchSelectedValue(this.dim);
    }

    private _validateNumber(): void {
        this._resolveMinMaxInput();
        // currentValue, this.min or this.max can be an empty string after ngModel update.
        const currentValue: number = (this.dim.value as FilterDimValue)?.value as number;
        const dimIsString: boolean = (this.dim['valuetype'] === this.valueTypes.STRING);
        const dimIsNumber: boolean = (this.dim['valuetype'] === this.valueTypes.NUMBER);
        const dimIsMin: boolean = this.dim.name === 'MT_BRUT_MIN';
        const dimIsMax: boolean = this.dim.name === 'MT_BRUT_MAX';
        const currentValueIsEmpty: boolean = typeof currentValue === 'undefined' || currentValue?.toString() === '';
        const minIsEmpty: boolean = typeof this.min === 'undefined' || this.min?.toString() === '';
        const maxIsEmpty: boolean = typeof this.max === 'undefined' || this.max?.toString() === '';
        const dimIsClassicNumber: boolean = !dimIsMin && !dimIsMax && dimIsNumber;

        // I needed to split the condition to avoid sonar issues...
        if (dimIsString || currentValueIsEmpty) {
            this.validateNumber = true;
            return;
        }
        if ((dimIsMin && maxIsEmpty) || (dimIsMax && minIsEmpty)) {
            this.validateNumber = true;
            return;
        }
        if ((dimIsClassicNumber && !isNaN(Number(currentValue)))) {
            this.validateNumber = true;
        } else {
            this.validateNumber = (currentValue <= this.max || currentValue >= this.min);
        }
    }

    private _resolveMinMaxInput(): void {
        if (this.dim.name === 'MT_BRUT_MIN') {
            const filterDim: FilterDim = this.filter.dimensions.find(dim => dim.name === 'MT_BRUT_MAX');
            if (filterDim && filterDim.value) {
                const filterDimValue: FilterDimValue = filterDim.value as FilterDimValue;
                this.max = filterDimValue.value as number;
            }
        } else if (this.dim.name === 'MT_BRUT_MAX') {
            const filterDim: FilterDim = this.filter.dimensions.find(dim => dim.name === 'MT_BRUT_MIN');
            if (filterDim && filterDim.value) {
                const filterDimValue: FilterDimValue = filterDim.value as FilterDimValue;
                this.min = filterDimValue.value as number;
            }
        }
    }
}
